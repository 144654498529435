import {
  CREATE_SUPPORT_TICKET,
  CREATE_SUPPORT_TICKET_SUCCESS,
  CREATE_SUPPORT_TICKET_FAILURE,
  GET_SUPPORT_TICKETS_SUCCESS,
  GET_SUPPORT_TICKETS_FAILURE,
  GET_SUPPORT_TICKETS,
  EDIT_SUPPORT_TICKET,
  EDIT_SUPPORT_TICKET_SUCCESS,
  EDIT_SUPPORT_TICKET_FAILURE,
  GET_SINGLE_SUPPORT_TICKET,
  GET_SINGLE_SUPPORT_TICKET_SUCCESS,
  GET_SINGLE_SUPPORT_TICKET_FAILURE,
  DELETE_SUPPORT_TICKET,
  DELETE_SUPPORT_TICKET_SUCCESS,
  DELETE_SUPPORT_TICKET_FAILURE,
  GET_TICKET_HISTORY,
  GET_TICKET_HISTORY_SUCCESS,
  GET_TICKET_HISTORY_FAILURE,
  ADD_TICKET_HISTORY
} from "../actions";

export const createSupportTicket = data => {
  return {
    type: CREATE_SUPPORT_TICKET,
    payload: data
  };
};

export const createSupportTicketSuccess = msg => {
  return {
    type: CREATE_SUPPORT_TICKET_SUCCESS,
    payload: msg
  };
};

export const createSupportTicketFailure = error => {
  return {
    type: CREATE_SUPPORT_TICKET_FAILURE,
    payload: error
  };
};

export const getSupportTickets = (data) => {
  return {
    type: GET_SUPPORT_TICKETS,
    payload: data
  };
};

export const supportTicketSuccess = data => {
  return {
    type: GET_SUPPORT_TICKETS_SUCCESS,
    payload: data
  };
};

export const supportTicketFailure = error => {
  return {
    type: GET_SUPPORT_TICKETS_FAILURE,
    payload: error
  };
};

export const editSupportTicket = data => {
  return {
    type: EDIT_SUPPORT_TICKET,
    payload: data
  };
};

export const editSupportTicketSuccess = (data) => {
  return {
    type: EDIT_SUPPORT_TICKET_SUCCESS,
    payload: data
  };
};

export const editSupportTicketFailure = error => {
  return {
    type: EDIT_SUPPORT_TICKET_FAILURE,
    payload: error
  };
};

export const getSingleSupportTicket = id => {
  return {
    type: GET_SINGLE_SUPPORT_TICKET,
    payload: id
  };
};

export const fetchTicketHistory = id => {
  return {
    type: GET_TICKET_HISTORY,
    payload: id
  };
};

export const getTicketHistorySuccess = data => {
  return {
    type: GET_TICKET_HISTORY_SUCCESS,
    payload: data
  };
};

export const getTicketHistoryFailure = error => {
  return {
    type: GET_TICKET_HISTORY_FAILURE,
    payload: error
  };
};

export const getSingleSupportTicketSuccess = data => {
  return {
    type: GET_SINGLE_SUPPORT_TICKET_SUCCESS,
    payload: data
  };
};

export const getSingleSupportTicketFailure = error => {
  return {
    type: GET_SINGLE_SUPPORT_TICKET_FAILURE,
    payload: error
  };
};

export const deleteSupportTicket = id => {
  return {
    type: DELETE_SUPPORT_TICKET,
    payload: id
  }
}
export const deleteSupportTicketSuccess = () => {
  return {
    type: DELETE_SUPPORT_TICKET_SUCCESS
  }
}
export const deleteSupportTicketFailure = error => {
  return {
    type: DELETE_SUPPORT_TICKET_FAILURE,
    payload: error
  }
}

export const addTicketHistory = data => {
  return {
    type: ADD_TICKET_HISTORY,
    payload: data
  };
};

// export const addTicketHistorySuccess = (data) => {
//   return {
//     type: EDIT_SUPPORT_TICKET_SUCCESS,
// 	payload:data
//   };
// };

// export const addTicketHistoryFailure = error => {
//   return {
//     type: EDIT_SUPPORT_TICKET_FAILURE,
//     payload: error
//   };
// };