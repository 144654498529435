import {
	GET_USER_CLIENTS,
	GET_USER_CLIENTS_SUCCESS,
	GET_USER_CLIENTS_FAILURE,
	DELETE_USER_CLIENTS,
	DELETE_USER_CLIENTS_SUCCESS,
	DELETE_USER_CLIENTS_FAILURE,
	EDIT_USER_CLIENTS,
	EDIT_USER_CLIENTS_SUCCESS,
	EDIT_USER_CLIENTS_FAILURE,
	GET_SINGLE_USER_CLIENTS_SUCCESS,
	GET_SINGLE_USER_CLIENTS_FAILURE,
} from '../actions';
import { updateItemInArray, removeItemFromArray } from '../utils/mutation';

const INITIAL_STATE = {
	userClients: [],
	success: false,
	error: null,
	delete: {
		success: false,
		error: null
	},
	edit: {
		data: {},
		success: false,
		error: null
	}
}

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_USER_CLIENTS_SUCCESS:
			return { ...INITIAL_STATE, userClients: action.payload }
		case GET_USER_CLIENTS_FAILURE:
			return { ...INITIAL_STATE, error: action.payload }

		// Get Single Provider
		case GET_SINGLE_USER_CLIENTS_SUCCESS:
			return { ...INITIAL_STATE, edit: { data: action.payload, error: null } };
		case GET_SINGLE_USER_CLIENTS_FAILURE:
			return { ...INITIAL_STATE, edit: { ...state.edit, error: action.payload } };

		// Delete User-Client
		case DELETE_USER_CLIENTS_SUCCESS:
			return {
				...state,
				delete: { ...INITIAL_STATE.delete, success: true },
				userClients: removeItemFromArray(state.userClients, state.userClients.findIndex(x => x.id == action.payload.id))

			};
		case DELETE_USER_CLIENTS_FAILURE:
			return { ...state, delete: { ...INITIAL_STATE.delete, error: action.payload } };

		// Update USER_CLIENT
		case EDIT_USER_CLIENTS:
			return { ...state, edit: { ...INITIAL_STATE.edit } };
		case EDIT_USER_CLIENTS_SUCCESS:
			return {
				...state,
				edit: { ...INITIAL_STATE.edit, success: true },
				userClients: updateItemInArray(
					state.userClients,
					state.userClients.findIndex(b => b.id === action.payload.id),
					action.payload,
					true,
				)
			};
		case EDIT_USER_CLIENTS_FAILURE:
			return { ...state, edit: { ...state.edit, error: action.payload } };

		default:
			return { ...state };
	}
};