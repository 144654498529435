import {
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_FAILURE,
  UPLOAD_IMAGE_RESET,
  UPLOAD_PROGRESS,
  GET_MEDIAS,
  GET_MEDIA_SUCCESS,
  GET_MEDIA_FAILURE

} from "../actions";


export const getMedias = (data) => {
  return {
    type: GET_MEDIAS,
    payload: data
  };
};


export const mediaSuccess = data => {
  return {
    type: GET_MEDIA_SUCCESS,
    payload: data
  };
};

export const mediaFailure = error => {
  return {
    type: GET_MEDIA_FAILURE,
    payload: error
  };
};


export const uploadNewImage = data => {
  return {
    type: UPLOAD_IMAGE,
    payload: data
  };
};

export const uploadNewImageSuccess = (data) => {
  return {
    type: UPLOAD_IMAGE_SUCCESS,
    payload: data
  };
};

export const uploadNewImageFailure = error => {
  return {
    type: UPLOAD_IMAGE_FAILURE,
    payload: error
  };
};

export const uploadImageReset = () => {
  return {
    type: UPLOAD_IMAGE_RESET
  }
}

export const uploadProgress = (percentage) => {
  return {
    type: UPLOAD_PROGRESS,
    payload: percentage
  }
}
