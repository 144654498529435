import { put, call, takeLatest } from 'redux-saga/effects';
import {
  create,
  get,
  getOne,
  update,
  remove,
  createHistory,
  getHistory,
} from './api';
import {
  supportTicketSuccess,
  supportTicketFailure,
  createSupportTicketSuccess,
  createSupportTicketFailure,
  getSingleSupportTicketSuccess,
  getSingleSupportTicketFailure,
  editSupportTicketSuccess,
  editSupportTicketFailure,
  deleteSupportTicketSuccess,
  deleteSupportTicketFailure,
  getTicketHistorySuccess,
  getTicketHistoryFailure,
} from './actions';
import { removeItemFromObject } from '../utils/mutation';

function* createSupportTicket(action) {
  const response = yield call(create, action.payload);
  if (response.hasOwnProperty('id'))
    yield put(createSupportTicketSuccess(response));
  else yield put(createSupportTicketFailure(response.errors[0].message));
}

function* getSupportTickets(action) {
  const response = yield call(get, action.payload);
  if (response !== undefined && Array.isArray(response || response.data))
    yield put(supportTicketSuccess(response));
  else yield put(supportTicketFailure(response));
}

function* getSingleSupportTicket(action) {
  const response = yield call(getOne, action.payload);
  if (response.hasOwnProperty('id'))
    yield put(getSingleSupportTicketSuccess(response));
  else yield put(getSingleSupportTicketFailure(response));
}

function* getTicketHistory(action) {
  const response = yield call(getHistory, action.payload);
  if (response !== undefined) yield put(getTicketHistorySuccess(response));
  else yield put(getTicketHistoryFailure(response));
}

function* updateSupportTicket(action) {
  if (action.payload.data.comment || action.payload.data.attachment) {
    const historyResponse = yield call(createHistory, action.payload);
    if (!historyResponse.hasOwnProperty('id')) {
      yield put(editSupportTicketFailure(historyResponse));
    }
  }
  const response = yield call(
    update,
    action.payload.id,
    removeItemFromObject(action.payload.data, 'attachment')
  );
  response.isChat = Boolean(
    action.payload.data.comment || action.payload.data.attachment
  );
  if (response.hasOwnProperty('id')) {
    yield put(editSupportTicketSuccess(response));
  } else {
    yield put(editSupportTicketFailure(response));
  }
}

function* deleteSupportTicket(action) {
  const response = yield call(remove, action.payload);
  if (response.hasOwnProperty('id'))
    yield put(deleteSupportTicketSuccess(response));
  else yield put(deleteSupportTicketFailure(response));
}

export default function* rootSaga() {
  yield takeLatest('CREATE_SUPPORT_TICKET', createSupportTicket);
  yield takeLatest('GET_SUPPORT_TICKETS', getSupportTickets);
  yield takeLatest('GET_SINGLE_SUPPORT_TICKET', getSingleSupportTicket);
  yield takeLatest('EDIT_SUPPORT_TICKET', updateSupportTicket);
  yield takeLatest('DELETE_SUPPORT_TICKET', deleteSupportTicket);
  yield takeLatest('GET_TICKET_HISTORY', getTicketHistory);
}
