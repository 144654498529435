import { put, call, takeLatest } from "redux-saga/effects";
import { filterProvidersCompany } from "./api";

import {
    getProvidersCompany,
    providersCompanySuccess,
    providersCompanyFailure,
} from "./actions";

import { GET_AUTOSUGGEST_COMPANY, AUTOSUGGEST_COMPANY_SUCCESS, AUTOSUGGEST_COMPANY_FAILURE } from './../actions'



function* providersCompanyResponse(action) {
    const response = yield call(filterProvidersCompany, action.payload)
    if (response !== undefined)
        yield put(providersCompanySuccess(response))
    else
        yield put(providersCompanyFailure(response))
}

export default function* rootSaga() {
    yield takeLatest(GET_AUTOSUGGEST_COMPANY, providersCompanyResponse);
}