import { put, call, takeLatest } from "redux-saga/effects";
import { getOne, update, getAll, create,remove } from "./api";
import {
  getSingleAdminSuccess,
  getSingleAdminFailure,
  editAdminSuccess,
  editAdminFailure,
  getAdminsSuccess,
  getAdminsFailure,
  createAdminSuccess,
  createAdminFailure,
  deleteAdminSuccess,
  deleteAdminFailure
} from "./actions";
import {
  GET_ALL_ADMINS,
  GET_SINGLE_ADMIN,
  EDIT_ADMIN,
  CREATE_ADMIN,
  DELETE_ADMIN
} from "../actions";

function * getAllAdmins (action) {
  const response = yield call(getAll,action.payload)
  if (response && Array.isArray(response.data)) yield put(getAdminsSuccess(response))
  else yield put(getAdminsFailure(response))
}
function* getSingleAdmin(action) {
  const response = yield call(getOne, action.payload);
  if (response.hasOwnProperty("id"))
    yield put(getSingleAdminSuccess(response));
  else yield put(getSingleAdminFailure(response));
}

function* updateAdmin(action) {
  const response = yield call(update, action.payload.id, action.payload.data);
  if (response.hasOwnProperty("id")) yield put(editAdminSuccess(response));
  else yield put(editAdminFailure(response.errors));
}

function* createAdmin(action){
  const response = yield call(create, action.payload)
  if (response.hasOwnProperty('id')) {
    yield put(createAdminSuccess(response))
  } else {
    yield put(createAdminFailure(response.errors))
  }
}
function * deleteAdmin (action) {
  const response = yield call(remove, action.payload.id)
  if (response.hasOwnProperty('id')) yield put(deleteAdminSuccess(response))
  else yield put(deleteAdminFailure(response))
}
export default function* rootSaga() {
  yield takeLatest(GET_ALL_ADMINS, getAllAdmins);
  yield takeLatest(GET_SINGLE_ADMIN, getSingleAdmin);
  yield takeLatest(EDIT_ADMIN, updateAdmin);
  yield takeLatest(CREATE_ADMIN,createAdmin);
  yield takeLatest(DELETE_ADMIN, deleteAdmin)
}
