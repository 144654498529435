import { put, call, takeLatest } from 'redux-saga/effects'
import { getAll, remove, getOne, update } from './api'
import {
  getBcgsSuccess,
  getBcgsFailure,
  deleteBcgSuccess,
  deleteBcgFailure,
  getSingleBcgSuccess,
  getSingleBcgFailure,
  editBcgSuccess,
  editBcgFailure,
} from './actions'
import {
  GET_ALL_BCGS,
  DELETE_BCG,
  GET_SINGLE_BCG,
  EDIT_BCG
} from '../actions'

function * getSingleBcg (action) {
  const response = yield call(getOne, action.payload)
  if (response.hasOwnProperty('id'))
    yield put(getSingleBcgSuccess(response))
  else yield put(getSingleBcgFailure(response))
}

function * getAllBcgs (action) {
  const response = yield call(getAll, action.payload)
  if (response !== undefined) yield put(getBcgsSuccess(response))
  else yield put(getBcgsFailure(response))
}

function * deleteBcgByID (action) {
  const response = yield call(remove, action.payload)
  if (response.hasOwnProperty('id')) yield put(deleteBcgSuccess(response))
  else yield put(deleteBcgFailure(response))
}

function * updateBcg (action) {
  const response = yield call(update, action.payload.id, action.payload)
  if (response.hasOwnProperty('id')) yield put(editBcgSuccess(response))
  else yield put(editBcgFailure(response.errors))
}


export default function * rootSaga () {
  yield takeLatest(GET_ALL_BCGS, getAllBcgs)
  yield takeLatest(DELETE_BCG, deleteBcgByID)
  yield takeLatest(GET_SINGLE_BCG, getSingleBcg)
  yield takeLatest(EDIT_BCG, updateBcg)
}
