import { put, call, takeLatest } from "redux-saga/effects";
import { create } from "./api";
import {loginUser} from './../auth/actions'


function* loginAsProvider(action) {
    const response = yield call(create, action.id);

    if(response){
        yield put(loginUser(action.payload,null,'loginProvider'))
    }
}

export default function* rootSaga() {
    yield takeLatest("LOGIN_AS_PROVIDER", loginAsProvider);
}
