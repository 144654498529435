import {
  EDIT_APP_CONFIGURATION,
  EDIT_APP_CONFIGURATION_SUCCESS,
  EDIT_APP_CONFIGURATION_FAILURE,
  GET_APP_CONFIGURATIONS,
  GET_APP_CONFIGURATIONS_SUCCESS,
  GET_APP_CONFIGURATIONS_FAILURE,
} from '../actions';
import { updateItemInArray } from '../utils/mutation';

const INITIAL_STATE = {
  success: false,
  loading: false,
  error: {},
  data: [],
  edit: {
    success: false,
    error: null,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_APP_CONFIGURATIONS:
      return { ...state, loading: true };
    case GET_APP_CONFIGURATIONS_SUCCESS:
      return { ...state, data: action.payload, loading: false };
    case GET_APP_CONFIGURATIONS_FAILURE:
      return { ...state, error: action, loading: false };

    case EDIT_APP_CONFIGURATION:
      return { ...state, edit: { ...state.edit, success: false, data: null } };
    case EDIT_APP_CONFIGURATION_SUCCESS:
      return {
        ...state,
        edit: { ...state.edit, success: true },
        data: updateItemInArray(
          state.data,
          state.data.findIndex((b) => b.id === action.payload.id),
          action.payload,
          true
        ),
      };
    case EDIT_APP_CONFIGURATION_FAILURE:
      return { ...state, edit: { ...state.edit, error: action.payload } };

    default:
      return { ...state };
  }
};
