import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  MARK_NOTIFICATION_READ,
  MARK_NOTIFICATION_READ_SUCCESS,
  MARK_NOTIFICATION_READ_FAILURE,
  MARK_NOTIFICATION_READ_BY_PROVIDER
} from "../actions";

export const getNotifications = data => {
  return {
    type: GET_NOTIFICATIONS,
    payload: data
  };
};

export const getNotificationsSuccess = data => {
  return {
    type: GET_NOTIFICATIONS_SUCCESS,
    payload: data
  };
};
export const getNotificationsFailure = error => {
  return {
    type: GET_NOTIFICATIONS_FAILURE,
    payload: error
  };
};

export const markNotificationRead = id => {
  return {
    type: MARK_NOTIFICATION_READ,
    payload: id
  };
};

export const markNotificationReadSuccess = data => {
  return {
    type: MARK_NOTIFICATION_READ_SUCCESS,
    payload: data
  };
};
export const markNotificationReadFailure = error => {
  return {
    type: MARK_NOTIFICATION_READ_FAILURE,
    payload: error
  };
};
export const markNotificationReadByProvider = error => {
  return {
    type: MARK_NOTIFICATION_READ_BY_PROVIDER,
    payload: error
  };
};
