import client from "../../feathers";

const bcgsService = client.service("become-cg-request");


export const getAll = async data => {
  try {
    let bcgReveived = {
      allBcgs: await bcgsService.find({ query: {...(data||{}), $sort:{createdAt:-1}} }),
      submittedBcgs: await bcgsService.find({ query: {...(data||{}), status: 'submitted', $sort:{createdAt:-1}} }),
    }
    return bcgReveived
  } catch (error) {
    return error;
  }
};

export const getOne = async id => {
  try {
    return await bcgsService.get(id);
  } catch (error) {
    return error;
  }
};

export const remove = async id => {
  try {
    return await bcgsService.remove(id)
  } catch (error) {
    return error
  }
}

export const update = async (id, data) => {
  try {
    return await bcgsService.patch(id, data)
  } catch (error) {
    return error;
  }
}
