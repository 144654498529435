import {
    AUTOSUGGEST_COMPANY_SUCCESS,
    AUTOSUGGEST_COMPANY_FAILURE,
} from "../actions";

const INITIAL_STATE = {
    data: [],
    success: false,
    error: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AUTOSUGGEST_COMPANY_SUCCESS:
            return {
                ...INITIAL_STATE,
                success: true,
                data:  action.payload
            };
        case AUTOSUGGEST_COMPANY_FAILURE:
            return {
                ...INITIAL_STATE,
                error: action.payload
            };
        default:
            return { ...state };
	}
};
