import {
  CREATE_CERTIFICATE,
  CREATE_CERTIFICATE_SUCCESS,
  CREATE_CERTIFICATE_FAILURE,
  GET_CERTIFICATES_SUCCESS,
  GET_CERTIFICATES_FAILURE,
  GET_All_CERTIFICATES_SUCCESS,
  GET_All_CERTIFICATES_FAILURE,
  EDIT_CERTIFICATE_SUCCESS,
  EDIT_CERTIFICATE_FAILURE,
  GET_SINGLE_CERTIFICATE_SUCCESS,
  GET_SINGLE_CERTIFICATE_FAILURE,
  DELETE_CERTIFICATE_SUCCESS,
  DELETE_CERTIFICATE_FAILURE,
} from '../actions';
import { updateItemInArray, removeItemFromArray } from '../utils/mutation';

const INITIAL_STATE = {
  success: false,
  error: {},
  data: [],
  allData: [],
  loaded: false,
  create: {
    success: false,
    error: null,
  },
  edit: {
    success: false,
    error: null,
  },
  delete: {
    success: false,
    error: null,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATE_CERTIFICATE:
      return { ...state, create: { ...state.create, success: false } };
    case CREATE_CERTIFICATE_SUCCESS:
      return {
        ...state,
        create: { ...state.create, success: true },
        data: [...state.data.data, action.payload],
      };
    case CREATE_CERTIFICATE_FAILURE:
      return { ...state, create: { ...state.create, error: action.payload } };
    case GET_CERTIFICATES_SUCCESS:
      return { ...state, loaded: true, data: action.payload, error: {} };
    case GET_CERTIFICATES_FAILURE:
      return { ...state, error: action };
    case GET_All_CERTIFICATES_SUCCESS:
      return { ...state, loaded: true, allData: action.payload, error: {} };
    case GET_All_CERTIFICATES_FAILURE:
      return { ...state, error: action };
    case EDIT_CERTIFICATE_SUCCESS:
      return {
        ...state,
        edit: { ...state.edit, success: true },
        data: updateItemInArray(
          state.data,
          state.data.findIndex((b) => b.id === action.payload.id),
          action.payload,
          true
        ),
      };
    case EDIT_CERTIFICATE_FAILURE:
      return { ...state, edit: { ...state.edit, error: action.payload } };
    case GET_SINGLE_CERTIFICATE_SUCCESS:
      return { ...state, edit: { ...state.edit, success: true } };
    case GET_SINGLE_CERTIFICATE_FAILURE:
      return { ...state, edit: { ...state.edit, error: action.payload } };
    case DELETE_CERTIFICATE_SUCCESS:
      let removedCertificateIndex = state.data.data.findIndex(
        (x) => x.id == action.payload.id
      );
      let newArray = removeItemFromArray(
        state.data.data,
        removedCertificateIndex
      );
      return {
        ...state,
        data: { ...state.data, data: newArray, total: state.data.total - 1 },
        allData: removeItemFromArray(
          state.allData,
          state.allData.findIndex((x) => x.id == action.payload.id)
        ),
      };
    case DELETE_CERTIFICATE_FAILURE:
      return { ...state, delete: { ...state.edit, error: action.payload } };
    default:
      return { ...state };
  }
};
