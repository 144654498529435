import { put, call, fork, take, takeLatest } from "redux-saga/effects";
import { eventChannel, END } from 'redux-saga';
import { create, get } from "./api";
import { uploadNewImageSuccess, uploadNewImageFailure, uploadProgress, mediaSuccess, mediaFailure } from "./actions";

// function uploadFile(data) {
//   const config = { headers: { "content-type": "multipart/form-data" } };
//   console.log(process.env.REACT_APP_API_URL)
//   axios
//     .post(process.env.REACT_APP_API_URL + "/media", data, config)
//     .then(response => {
//       console.log(response);
//     })
//     .catch(error => {
//       console.log("*****  " + error);
//     });
// }

function createUploader(file) {
  let emit;
  const chan = eventChannel((emitter) => {
    emit = emitter;
    return () => { };
  });
  const uploadProgressCb = ({ total, loaded }) => {
    const percentage = Math.round((loaded * 100) / total);
    emit(percentage);
    if (percentage === 100) emit(END);
  };
  const uploadPromise = create(file, uploadProgressCb);
  return [uploadPromise, chan];
}

function* uploadProgressWatcher(chan) {
  while (true) { // eslint-disable-line no-constant-condition
    const progress = yield take(chan);
    yield put(uploadProgress(progress));
  }
}

function* uploadNewImage(action) {
  // const response = yield call(create, action.payload.file);
  // if (response.hasOwnProperty("url")) yield put(uploadNewImageSuccess({url: response.url, name: action.payload.name}));
  // else yield put(uploadNewImageFailure(response.errors));
  try {
    const [uploadPromise, chan] = yield call(createUploader, action.payload.file);
    yield fork(uploadProgressWatcher, chan);
    const response = yield call(() => uploadPromise);
    if (response.status === 201 && response.statusText === 'Created') yield put(uploadNewImageSuccess({ id: response.data.id, url: response.data.url, name: action.payload.name }));
    else yield put(uploadNewImageFailure(response.errors));
  } catch (e) {
    yield put(uploadNewImageFailure(e));
  }
}

// function* uploadWatcher() {
//   yield takeEvery('UPLOAD_IMAGE', uploadNewImage)
// }

function* getAllMedias(action) {
  const response = yield call(get, action.payload);
  if (response !== undefined) yield put(mediaSuccess(response));
  else yield put(mediaFailure(response));
}

export default function* rootSaga() {
  // yield all([ fork(uploadWatcher) ]);
  yield takeLatest('UPLOAD_IMAGE', uploadNewImage);
  yield takeLatest('GET_MEDIAS', getAllMedias)
}
