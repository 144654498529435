import client from "../../feathers";

const tagService = client.service("tags");

export const create = async data => {
  try {
    return await tagService.create({
      name: data.name,
      status: false
    });
  } catch (error) {
    return error;
  }
};

export const get = async (data) => {
  try {
    return await tagService.find({ query: data });
  } catch (error) {
    return error;
  }
};

export const getOne = async id => {
  try {
    return await tagService.get(id);
  } catch (error) {
    return error;
  }
};

export const update = async (id, data) => {
  try {
    return await tagService.patch(id, data)
  } catch (error) {
    return error;
  }
}

export const remove = async id => {
  try {
    return await tagService.remove(id)
  } catch (error) {
    return error
  }
}

export const approve = async (id) => {
  try {
    return await tagService.patch(id, { status: true })
  } catch (error) {
    return error;
  }
}
// export const update = async () => {
//   try {
//     return await tagService.update
//   }
// }
