import { put, call, takeLatest } from 'redux-saga/effects';
import {
  create,
  get,
  getOne,
  update,
  remove,
  getAll,
  // , getCertificate
} from './api';
import {
  certificateSuccess,
  getAllCertificateSuccess,
  getAllCertificateFailure,
  certificateFailure,
  createCertificateSuccess,
  createCertificateFailure,
  getSingleCertificateSuccess,
  getSingleCertificateFailure,
  editCertificateSuccess,
  editCertificateFailure,
  deleteCertificateSuccess,
  deleteCertificateFailure,
} from './actions';

function* createCertificate(action) {
  const response = yield call(create, action.payload);
  if (response.hasOwnProperty('id'))
    yield put(createCertificateSuccess(response));
  else yield put(createCertificateFailure(response.errors[0].message));
}

function* getCertificates(action) {
  const response = yield call(get, action.payload);
  if (response !== undefined) yield put(certificateSuccess(response));
  else yield put(certificateFailure(response));
}

function* getAllCertificates(action) {
  const response = yield call(getAll, action.payload);
  if (response && (response.data || Array.isArray(response)))
    yield put(getAllCertificateSuccess(response));
  else yield put(getAllCertificateFailure(response));
}

function* getSingleCertificate(action) {
  const response = yield call(getOne, action.payload);
  if (response.hasOwnProperty('id'))
    yield put(getSingleCertificateSuccess(response));
  else yield put(getSingleCertificateFailure(response));
}

function* updateCertificate(action) {
  const response = yield call(update, action.payload.id, action.payload.data);
  if (response.hasOwnProperty('id'))
    yield put(editCertificateSuccess(response));
  else yield put(editCertificateFailure(response));
}

function* deleteCertificate(action) {
  const response = yield call(remove, action.payload);
  if (response.hasOwnProperty('id'))
    yield put(deleteCertificateSuccess(response));
  else yield put(deleteCertificateFailure(response));
}

export default function* rootSaga() {
  yield takeLatest('CREATE_CERTIFICATE', createCertificate);
  yield takeLatest('GET_CERTIFICATES', getCertificates);
  yield takeLatest('GET_All_CERTIFICATES', getAllCertificates);
  yield takeLatest('GET_SINGLE_CERTIFICATE', getSingleCertificate);
  yield takeLatest('EDIT_CERTIFICATE', updateCertificate);
  yield takeLatest('DELETE_CERTIFICATE', deleteCertificate);
}
