import {
  CREATE_NEW_CLIENT,
  CREATE_NEW_CLIENT_SUCCESS,
  CREATE_NEW_CLIENT_FAILURE,
  GET_ALL_CLIENTS,
  GET_ALL_CLIENTS_SUCCESS,
  GET_ALL_CLIENTS_FAILURE,
  GET_ALL_EMPLOYEES_SUCCESS,
  GET_ALL_EMPLOYEES_FAILURE,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAILURE,
  GET_SINGLE_CLIENT_SUCCESS,
  GET_SINGLE_CLIENT_FAILURE,
  EDIT_CLIENT,
  EDIT_CLIENT_SUCCESS,
  EDIT_CLIENT_FAILURE,
  EDIT_CLIENT_BY_ADMIN,
  EDIT_CLIENT_BY_ADMIN_SUCCESS,
  EDIT_CLIENT_BY_ADMIN_FAILURE,
} from '../actions';
import { updateItemInArray, removeItemFromArray } from '../utils/mutation';

const INITIAL_STATE = {
  data: [],
  allEmployees: [],
  success: false,
  error: null,
  loading: false,
  create: {
    success: false,
    error: null,
  },
  edit: {
    data: {},
    success: false,
    error: null,
  },
  delete: {
    success: false,
    error: null,
  },
  adminEdit: {
    success: false,
    error: null,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Create CLIENT
    case CREATE_NEW_CLIENT:
      return {
        ...state,
        create: { ...INITIAL_STATE.create },
      };
    case CREATE_NEW_CLIENT_SUCCESS:
      return {
        ...state,
        create: { ...state.create, success: true },
        data: Array.isArray(state.data)
          ? { ...state.data }
          : { ...state.data, data: [...state.data.data, action.payload] },
      };
    case CREATE_NEW_CLIENT_FAILURE:
      return {
        ...state,
        create: { ...state.create, error: action.payload },
      };

    // Get All CLIENTs
    case GET_ALL_CLIENTS:
      return { ...state, loading: true };
    case GET_ALL_CLIENTS_SUCCESS:
      return { ...INITIAL_STATE, loading: false, data: action.payload };
    case GET_ALL_CLIENTS_FAILURE:
      return { ...INITIAL_STATE, loading: false, error: action.payload };

    // All Employees without paginate
    case GET_ALL_EMPLOYEES_SUCCESS:
      return { ...INITIAL_STATE, allEmployees: action.payload };
    case GET_ALL_EMPLOYEES_FAILURE:
      return { ...INITIAL_STATE, error: action.payload };

    // Get Single CLIENT
    case GET_SINGLE_CLIENT_SUCCESS:
      return {
        ...INITIAL_STATE,
        edit: { data: action.payload, error: null },
      };
    case GET_SINGLE_CLIENT_FAILURE:
      return { ...INITIAL_STATE, edit: { error: action.payload } };

    // Delete CLIENT
    case DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        delete: { ...INITIAL_STATE.delete, success: true },
        data: Array.isArray(state.data)
          ? { ...state.data }
          : {
              ...state.data,
              data: removeItemFromArray(
                state.data.data,
                state.data.data.findIndex((x) => x.id == action.payload.id)
              ),
            },
      };
    case DELETE_CLIENT_FAILURE:
      return {
        ...state,
        delete: { ...INITIAL_STATE.delete, error: action.payload },
      };

    // Update CLIENT
    case EDIT_CLIENT:
      return {
        ...state,
        edit: { ...INITIAL_STATE.edit, success: false },
      };
    case EDIT_CLIENT_SUCCESS:
      return {
        ...state,
        edit: { ...INITIAL_STATE.edit, success: true },
        data: Array.isArray(state.data)
          ? { ...state.data }
          : {
              ...state.data,
              data: updateItemInArray(
                state.data.data,
                state.data.data
                  ? state.data.data.findIndex((b) => b.id === action.payload.id)
                  : state.data.findIndex((b) => b.id === action.payload.id),
                action.payload,
                true
              ),
            },
      };
    case EDIT_CLIENT_FAILURE:
      return { ...state, edit: { ...state.edit, error: action.payload } };

    // Update CLIENT BY ADMIN
    case EDIT_CLIENT_BY_ADMIN:
      return {
        ...state,
        adminEdit: { ...INITIAL_STATE.adminEdit, success: false },
      };
    case EDIT_CLIENT_BY_ADMIN_SUCCESS:
      return {
        ...state,
        adminEdit: { ...INITIAL_STATE.adminEdit, success: true },
        data: updateItemInArray(
          state.data,
          state.data.findIndex((b) => b.id === action.payload.id),
          action.payload,
          true
        ),
      };
    case EDIT_CLIENT_BY_ADMIN_FAILURE:
      return {
        ...state,
        adminEdit: { ...state.adminEdit, error: action.payload },
      };

    default:
      return { ...state };
  }
};
