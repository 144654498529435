import {
  GET_ALL_BCGS,
  GET_ALL_BCGS_SUCCESS,
  GET_ALL_BCGS_FAILURE,
  DELETE_BCG,
  DELETE_BCG_SUCCESS,
  DELETE_BCG_FAILURE,
  EDIT_BCG,
  EDIT_BCG_SUCCESS,
  EDIT_BCG_FAILURE,
  GET_SINGLE_BCG,
  GET_SINGLE_BCG_SUCCESS,
  GET_SINGLE_BCG_FAILURE,
} from '../actions';
import { updateItemInArray, removeItemFromArray } from '../utils/mutation';

const INITIAL_STATE = {
  loading: false,
  data: [],
  success: false,
  error: null,
  create: {
    success: false,
    error: null,
  },
  edit: {
    data: {},
    success: false,
    error: null,
  },
  delete: {
    success: false,
    error: null,
  },
  allCount: {
    total: 0,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_BCGS:
      return { ...INITIAL_STATE, loading: true };
    case GET_ALL_BCGS_SUCCESS:
      return { ...INITIAL_STATE, data: action?.payload, loading: false };
    case GET_ALL_BCGS_FAILURE:
      return { ...INITIAL_STATE, error: action.payload, loading: false };

    case GET_SINGLE_BCG_SUCCESS:
      return { ...INITIAL_STATE, edit: { data: action.payload, error: null } };
    case GET_SINGLE_BCG_FAILURE:
      return {
        ...INITIAL_STATE,
        edit: { ...state.edit, error: action.payload },
      };

    case DELETE_BCG_SUCCESS:
      return {
        ...state,
        delete: { ...INITIAL_STATE.delete, success: true },
        data: removeItemFromArray(
          state.data,
          state.data.findIndex((x) => x.id == action.payload.id)
        ),
      };

    case DELETE_BCG_FAILURE:
      return {
        ...state,
        delete: { ...INITIAL_STATE.delete, error: action.payload },
      };

    case EDIT_BCG:
      return { ...state, edit: { ...INITIAL_STATE.edit } };
    case EDIT_BCG_SUCCESS:
      return {
        ...state,
        edit: { ...INITIAL_STATE.edit, success: true },
        data: updateItemInArray(
          state.data,
          state?.data?.findIndex((b) => b.id === action.payload.id),
          action.payload,
          true
        ),
      };
    case EDIT_BCG_FAILURE:
      return { ...state, edit: { ...state.edit, error: action.payload } };

    default:
      return { ...state };
  }
};
