import { put, call, takeLatest } from "redux-saga/effects";
import { create, getAll, remove, update } from "./api";
import {
    createUserRoleSuccess,
    createUserRoleFailure,
    getUserRoleSuccess,
    getUserRoleFailure,
    deleteUserRoleSuccess,
    deleteUserRoleFailure,
    editUserRoleSuccess,
    editUserRoleFailure
} from "./actions";
import { CREATE_USER_ROLES, GET_USER_ROLES, DELETE_USER_ROLE, EDIT_USER_ROLE } from './../actions'

function* createUserRole(action) {
    const response = yield call(create, action.payload)
    if (response.hasOwnProperty('id')) yield put(createUserRoleSuccess(response))
    else yield put(createUserRoleFailure(response.error))
}

function* getUserRoles() {
    const response = yield call(getAll)
    if (response !== undefined)
        yield put(getUserRoleSuccess(response))
    else
        yield put(getUserRoleFailure(response))
}

function* deleteUserRole(action) {
    const response = yield call(remove, action.payload)
    if (response.hasOwnProperty('id')) yield put(deleteUserRoleSuccess(response));
    else yield put(deleteUserRoleFailure(response));
}

function* updateUserRole(action) {
    const response = yield call(update, action.payload.id, action.payload.data);
    if (response.hasOwnProperty("id")) yield put(editUserRoleSuccess(response));
    else yield put(editUserRoleFailure(response.errors));
}

export default function* rootSaga() {
    yield takeLatest(CREATE_USER_ROLES, createUserRole);
    yield takeLatest(GET_USER_ROLES, getUserRoles);
    yield takeLatest(DELETE_USER_ROLE, deleteUserRole);
    yield takeLatest(EDIT_USER_ROLE, updateUserRole);
}