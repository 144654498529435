import { put, call, takeLatest } from "redux-saga/effects";
import { create } from "./api";
import {
  createMediaMetaSuccess,
  createMediaMetaFailure
} from "./actions";

function* createMedia(action) {
  const response = yield call(create, action.payload);
  if (response.length > 0) yield put(createMediaMetaSuccess());
  else yield put(createMediaMetaFailure(response.errors[0].message));
}

export default function* rootSaga() {
  yield takeLatest("CREATE_MEDIA_META", createMedia);

}
