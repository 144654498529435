import {
  GET_REVIEWS,
  GET_REVIEWS_FAILURE,
  GET_REVIEWS_SUCCESS,
  EDIT_REVIEW_SUCCESS,
  EDIT_REVIEW_FAILURE,
} from '../actions';
import { updateItemInArray } from '../utils/mutation';

const INITIAL_STATE = {
  success: false,
  error: {},
  data: [],
  dataMap: {},
  edit: {
    success: false,
    error: null,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_REVIEWS:
      return { ...state, data: [] };
    case GET_REVIEWS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        dataMap: {
          ...state.dataMap,
          [action.payload.length
            ? action.payload[0].parentProviderId || action.payload[0].providerId
            : 0]: action.payload,
        },
      };
    case GET_REVIEWS_FAILURE:
      return { ...state, error: action };
    case EDIT_REVIEW_SUCCESS:
      return {
        ...state,
        edit: { ...state.edit, success: true },
        data: updateItemInArray(
          state.data,
          state.data.findIndex((b) => b.id === action.payload.id),
          action.payload,
          true
        ),
      };
    case EDIT_REVIEW_FAILURE:
      return { ...state, edit: { ...state.edit, error: action.payload } };
    default:
      return { ...state };
  }
};
