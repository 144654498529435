import client from "../../feathers";

const categoryService = client.service("categories");

export const create = async data => {
  try {
    return await categoryService.create({
      name: data.name
    });
  } catch (error) {
    return error;
  }
};

export const get = async (params) => {
  try {
    if(params)
    {
      if(!params.name || params.length == 0){
        delete params.name
      }
      return await categoryService.find({query:{name: params.name, $limit:params.limit, $skip:params.skip, $sort:{createdAt:-1}}});
    }
    else{
      return await categoryService.find({query:{pagination:false}});
    }
   
  } catch (error) {
    return error;
  }
};

export const getOne = async id => {
  try {
    return await categoryService.get(id);
  } catch (error) {
    return error;
  }
};

export const update = async (id, data) => {
  try {
    return await categoryService.patch(id, data)
  } catch (error) {
    return error;
  }
}

export const remove = async id => {
  try {
    return await categoryService.remove(id)
  } catch (error) {
    return error
  }
}

// export const update = async () => {
//   try {
//     return await categoryService.update
//   }
// }
