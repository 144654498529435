import client from '../../feathers';

const appConfigurationService = client.service('app-configuration');

export const get = async () => {
  try {
    return await appConfigurationService.find();
  } catch (error) {
    return error;
  }
};

export const getOne = async (id) => {
  try {
    return await appConfigurationService.get(id);
  } catch (error) {
    return error;
  }
};

export const update = async (id, data) => {
  try {
    return await appConfigurationService.patch(id, data);
  } catch (error) {
    return error;
  }
};
