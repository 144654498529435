import { put, call, takeLatest } from 'redux-saga/effects';
import { create, getAll, remove, getOne, update, getAllUser } from './api';
import {
  createProviderSuccess,
  createProviderFailure,
  getProvidersSuccess,
  getProvidersFailure,
  deleteProviderSuccess,
  deleteProviderFailure,
  getSingleProviderSuccess,
  getSingleProviderFailure,
  editProviderSuccess,
  editProviderFailure,
  getAllUsersSuccess,
  getAllUsersFailure,
} from './actions';
import {
  CREATE_NEW_PROVIDER,
  GET_ALL_PROVIDERS,
  DELETE_PROVIDER,
  GET_SINGLE_PROVIDER,
  EDIT_PROVIDER,
  GET_ALL_USERS,
} from '../actions';

import { getAll as getRoles } from '../user-roles/api';

function* getSingleProvider(action) {
  const response = yield call(getOne, action.payload);
  if (response.hasOwnProperty('id'))
    yield put(getSingleProviderSuccess(response));
  else yield put(getSingleProviderFailure(response));
}

function* getAllProviders(action) {
  const response = yield call(getAll, action.payload);
  if (response !== undefined) yield put(getProvidersSuccess(response));
  else yield put(getProvidersFailure(response));
}

function* createNewProvider(action) {
  const response = yield call(create, action.payload);
  if (response.hasOwnProperty('id')) {
    yield put(createProviderSuccess(response));
  } else {
    yield put(
      createProviderFailure(response.message || response.errors || response)
    );
  }
}

function* deleteProviderByID(action) {
  const response = yield call(remove, action.payload.id, action.payload.userRoleId);
  if (response.hasOwnProperty('id')) yield put(deleteProviderSuccess(response));
  else yield put(deleteProviderFailure(response));
}

function* updateProvider(action) {
  const response = yield call(update, action.payload.id, action.payload.data);
  if (response.hasOwnProperty('id')) yield put(editProviderSuccess(response));
  else
    yield put(
      editProviderFailure(response.message || response.errors || response)
    );
}

function* getAllUsers(action) {
  const response = yield call(getAllUser, action.payload);
  if (response !== undefined) {
    yield put(getAllUsersSuccess(response));
  } else yield put(getAllUsersFailure(response));
}

export default function* rootSaga() {
  yield takeLatest(CREATE_NEW_PROVIDER, createNewProvider);
  yield takeLatest(GET_ALL_PROVIDERS, getAllProviders);
  yield takeLatest(DELETE_PROVIDER, deleteProviderByID);
  yield takeLatest(GET_SINGLE_PROVIDER, getSingleProvider);
  yield takeLatest(EDIT_PROVIDER, updateProvider);
  yield takeLatest(GET_ALL_USERS, getAllUsers);
}
