import {
  GET_REVIEWS_SUCCESS,
  GET_REVIEWS_FAILURE,
  GET_REVIEWS,
  EDIT_REVIEW,
  EDIT_REVIEW_SUCCESS,
  EDIT_REVIEW_FAILURE
} from "../actions";

export const getReviews = (data) => {
  return {
    type: GET_REVIEWS,
    payload: data
  };
};

export const getReviewsSuccess = data => {
  return {
    type: GET_REVIEWS_SUCCESS,
    payload: data
  };
};

export const getReviewsFailure = error => {
  return {
    type: GET_REVIEWS_FAILURE,
    payload: error
  };
};

export const editReview = data => {
  return {
    type: EDIT_REVIEW,
    payload: data
  };
};

export const editReviewSuccess = (data) => {
  return {
    type: EDIT_REVIEW_SUCCESS,
	payload:data
  };
};

export const editReviewFailure = error => {
  return {
    type: EDIT_REVIEW_FAILURE,
    payload: error
  };
};
