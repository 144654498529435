import {
    GET_PERSONAL_REGTD_YEAR,
    GET_PERSONAL_REGTD_YEAR_SUCCESS,
    GET_PERSONAL_REGTD_YEAR_ERROR,
} from "../actions";

export const getPersonalRegtdYearSuccess = data => {
    return {
        type: GET_PERSONAL_REGTD_YEAR_SUCCESS,
        payload: data
    };
};

export const getPersonalRegtdYearError = data => {
    return {
        type: GET_PERSONAL_REGTD_YEAR_ERROR,
        payload: data
    };
};

export const getPersonalRegtdYear = (data) => {
    return {
        type: GET_PERSONAL_REGTD_YEAR,
        payload: data
    };
};