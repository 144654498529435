import {
  CREATE_SUPPORT_TICKET_SUCCESS,
  CREATE_SUPPORT_TICKET_FAILURE,
  GET_SUPPORT_TICKETS,
  GET_SUPPORT_TICKETS_SUCCESS,
  GET_SUPPORT_TICKETS_FAILURE,
  EDIT_SUPPORT_TICKET,
  EDIT_SUPPORT_TICKET_SUCCESS,
  EDIT_SUPPORT_TICKET_FAILURE,
  GET_SINGLE_SUPPORT_TICKET,
  GET_SINGLE_SUPPORT_TICKET_SUCCESS,
  GET_SINGLE_SUPPORT_TICKET_FAILURE,
  DELETE_SUPPORT_TICKET_SUCCESS,
  DELETE_SUPPORT_TICKET_FAILURE,
  GET_TICKET_HISTORY_SUCCESS,
  GET_TICKET_HISTORY_FAILURE,
} from '../actions';
import { updateItemInArray, removeItemFromArray } from '../utils/mutation';

const INITIAL_STATE = {
  loading: false,
  success: false,
  error: {},
  data: [],
  selectedTicket: null,
  create: {
    success: false,
    error: null,
  },
  edit: {
    success: false,
    error: null,
  },
  delete: {
    success: false,
    error: null,
  },
  history: {
    data: [],
    error: null,
  },
  ticketReplyResponse: false,
  isTicketEdition: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATE_SUPPORT_TICKET_SUCCESS:
      return {
        ...state,
        create: { ...state.create, success: true },
      }; /*data: [...state.data, action.payload] provider tickets should not be added to itself*/
    case CREATE_SUPPORT_TICKET_FAILURE:
      return { ...state, create: { ...state.create, error: action.payload } };
    case GET_SUPPORT_TICKETS:
      return { ...INITIAL_STATE, loading: true };
    case GET_SUPPORT_TICKETS_SUCCESS:
      return { ...INITIAL_STATE, data: action.payload, loading: false };
    case GET_SUPPORT_TICKETS_FAILURE:
      return { ...INITIAL_STATE, error: action, loading: false };
    case EDIT_SUPPORT_TICKET:
      return {
        ...state,
        ticketReplyResponse: false,
        isTicketEdition: false,
      };
    case EDIT_SUPPORT_TICKET_SUCCESS:
      return {
        ...state,
        edit: { ...state.edit, success: true },
        ticketReplyResponse: action.payload.isChat,
        isTicketEdition: !action.payload.isChat,
        selectedTicket: action.payload,
        // selectedTicket: appendItemToArray(
        // 	state.selectedTicket.history,
        // 	action.payload
        // ),
        data: updateItemInArray(
          state.data,
          state.data.findIndex((b) => b.id === action.payload.id),
          action.payload,
          true
        ),
      };
    case EDIT_SUPPORT_TICKET_FAILURE:
      return {
        ...state,
        edit: { ...state.edit, error: action.payload },
        ticketReplyResponse: false,
        isTicketEdition: false,
      };
    case GET_SINGLE_SUPPORT_TICKET:
      return {
        ...state,
        ticketReplyResponse: false,
        isTicketEdition: false,
        selectedTicket: undefined,
      };
    case GET_SINGLE_SUPPORT_TICKET_SUCCESS:
      return {
        ...state,
        selectedTicket: action.payload,
      };
    case GET_SINGLE_SUPPORT_TICKET_FAILURE:
      return { ...state, edit: { ...state.edit, error: action.payload } };
    case DELETE_SUPPORT_TICKET_SUCCESS:
      return { ...state, delete: { ...state.delete, success: true } };
    case DELETE_SUPPORT_TICKET_FAILURE:
      return {
        ...state,
        delete: { ...state.edit, error: action.payload },
        data: removeItemFromArray(
          state.data,
          state.data.findIndex((x) => x.id == action.payload.id)
        ),
      };
    case GET_TICKET_HISTORY_SUCCESS:
      return { ...state, history: { data: action.payload, error: null } };
    case GET_TICKET_HISTORY_FAILURE:
      return { ...state, history: { data: [], error: action.payload } };
    default:
      return { ...state };
  }
};
