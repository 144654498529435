import {
  CREATE_SERVICE,
  CREATE_SERVICE_SUCCESS,
  CREATE_SERVICE_FAILURE,
  GET_SERVICES_SUCCESS,
  GET_SERVICES,
  GET_SERVICES_FAILURE,
  EDIT_SERVICE_SUCCESS,
  EDIT_SERVICE_FAILURE,
  GET_SINGLE_SERVICE_SUCCESS,
  GET_SINGLE_SERVICE_FAILURE,
  DELETE_SERVICE_SUCCESS,
  DELETE_SERVICE_FAILURE,
  CATEGORY_SPECIFIC_SERVICES_SUCCESS,
  GET_SERVICE_TASKS,
  GET_SERVICE_TASKS_SUCCESS,
  GET_SERVICE_TASKS_FAILURE,
  ADD_SERVICE_TO_PROVIDER,
  ADD_SERVICE_TO_PROVIDER_SUCCESS,
  ADD_SERVICE_TO_PROVIDER_FAILURE,
  GET_PROVIDER_TASKS_SUCCESS,
  REMOVE_PROVIDER_TASKS,
  REMOVE_PROVIDER_TASKS_SUCCESS,
  REMOVE_PROVIDER_TASKS_FAILURE,
  CATEGORY_SPECIFIC_SERVICES_FAILURE,
  GET_CATEGORY_SPECIFIC_SERVICES,
  GET_ADMINISTRATOR_LIST_WITH_SERVICE_ID_AND_PROVIDER_ID_SUCCESS,
  GET_ADMINISTRATOR_LIST_WITH_SERVICE_ID_AND_PROVIDER_ID,
  UPDATE_SERVICE_TO_PROVIDER_SUCCESS,
  UPDATE_SERVICE_TO_PROVIDER,
  GET_USER_LIST_WITH_SERVICE_ID,
  GET_USER_LIST_WITH_SERVICE_ID_SUCCESS,
} from '../actions';
import { updateItemInArray, removeItemFromArray } from '../utils/mutation';

const INITIAL_STATE = {
  success: false,
  loading: true,
  error: null,
  data: [],
  create: {
    success: false,
    error: null,
    status: false,
  },
  edit: {
    success: false,
    error: null,
  },
  delete: {
    success: false,
    error: null,
  },
  categorySpecificServices: {
    success: false,
    error: null,
    loader: false,
    data: [],
  },
  providerTasks: {
    success: false,
    error: null,
    data: [],
    delete: {
      success: false,
      error: null,
    },
  },
  serviceSpecificPersonnels: {
    success: false,
    data: [],
  },
  tasks: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATE_SERVICE:
      return {
        ...state,
        create: { ...state.create, success: false, error: null, status: false },
      };
    case CREATE_SERVICE_SUCCESS:
      return {
        ...state,
        create: { ...state.create, success: true, status: true, error: null },
      };
    case CREATE_SERVICE_FAILURE:
      return {
        ...state,
        create: {
          ...state.create,
          success: false,
          status: false,
          error: action.payload,
        },
      };
    case GET_SERVICES:
      return { ...state, success: false, loading: true };
    case GET_SERVICES_SUCCESS:
      return { ...state, success: true, data: action.payload, loading: false };
    case GET_SERVICES_FAILURE:
      return { ...state, error: action };
    case EDIT_SERVICE_SUCCESS:
      return {
        ...state,
        edit: { ...state.edit, success: true },
        data: Array.isArray(state.data)
          ? { ...state.data }
          : {
              ...state.data,
              data: updateItemInArray(
                state.data.data,
                state.data.data.findIndex((b) => b.id === action.payload.id),
                action.payload,
                true
              ),
            },
      };
    case EDIT_SERVICE_FAILURE:
      return { ...state, edit: { ...state.edit, error: action.payload } };
    case GET_SINGLE_SERVICE_SUCCESS:
      return {
        ...state,
        singleData: action.payload,
        edit: { ...state.edit, success: false },
      };
    case GET_SINGLE_SERVICE_FAILURE:
      return { ...state, edit: { ...state.edit, error: action.payload } };
    case DELETE_SERVICE_SUCCESS:
      return {
        ...state,
        delete: { ...state.edit, success: true },
        data: Array.isArray(state.data)
          ? { ...state.data }
          : {
              ...state.data,
              data: removeItemFromArray(
                state.data.data,
                state.data.data.findIndex((x) => x.id == action.payload.id)
              ),
              total: state.data.total - 1,
            },
      };
    case DELETE_SERVICE_FAILURE:
      return { ...state, delete: { ...state.edit, error: action.payload } };

    case GET_CATEGORY_SPECIFIC_SERVICES:
      return { ...state, categorySpecificServices: { data: [], loader: true } };
    case CATEGORY_SPECIFIC_SERVICES_FAILURE:
      return {
        ...state,
        categorySpecificServices: { data: [], loader: false },
      };
    case CATEGORY_SPECIFIC_SERVICES_SUCCESS:
      return {
        ...state,
        categorySpecificServices: {
          data: action.payload.length
            ? action.payload
            : action.payload.data && action.payload.data.length
            ? action.payload.data
            : [],
          loader: false,
        },
      };

    case GET_SERVICE_TASKS_SUCCESS:
      return { ...state, tasks: action.payload };
    case ADD_SERVICE_TO_PROVIDER:
      return {
        ...state,
        providerTasks: { ...state.providerTasks, success: false, error: null },
      };
    case ADD_SERVICE_TO_PROVIDER_SUCCESS:
      return {
        ...state,
        providerTasks: { ...state.providerTasks, success: true, error: null },
      };
    case ADD_SERVICE_TO_PROVIDER_FAILURE:
      return {
        ...state,
        providerTasks: {
          ...state.providerTasks,
          success: false,
          error: action.payload,
          data: [...state.providerTasks.data, action.payload],
        },
      };

    case GET_PROVIDER_TASKS_SUCCESS:
      return {
        ...state,
        providerTasks: { ...state.providerTasks, data: action.payload },
      };
    case REMOVE_PROVIDER_TASKS:
      return {
        ...state,
        providerTasks: {
          ...state.providerTasks,
          delete: { success: false, error: null },
        },
      };
    case REMOVE_PROVIDER_TASKS_SUCCESS:
      return {
        ...state,
        providerTasks: {
          ...state.providerTasks,
          data: removeItemFromArray(
            state.providerTasks.data,
            state.providerTasks.data.findIndex((x) => x.id == action.payload.id)
          ),
          delete: { success: true, error: null },
        },
      };
    case REMOVE_PROVIDER_TASKS_FAILURE:
      return {
        ...state,
        providerTasks: {
          ...state.providerTasks,
          delete: { success: false, error: action.payload },
        },
      };
    case GET_ADMINISTRATOR_LIST_WITH_SERVICE_ID_AND_PROVIDER_ID:
      return {
        ...state,
        serviceSpecificPersonnels: {
          ...state.serviceSpecificPersonnels,
          data: [],
          success: false,
        },
      };
    case GET_ADMINISTRATOR_LIST_WITH_SERVICE_ID_AND_PROVIDER_ID_SUCCESS:
      return {
        ...state,
        serviceSpecificPersonnels: {
          ...state.serviceSpecificPersonnels,
          data: action.payload,
          success: true,
        },
      };
    case UPDATE_SERVICE_TO_PROVIDER:
      return {
        ...state,
        edit: { ...state.edit, success: false },
      };
    case UPDATE_SERVICE_TO_PROVIDER_SUCCESS:
      let data = state.data && state.data.data ? state.data.data : state.data;
      let index = data.findIndex(
        (b) => b.providerTask.id === action.payload.id
      );
      let payloadData = data[index];
      payloadData.providerTask = action.payload;
      return {
        ...state,
        edit: { ...state.edit, success: true },
        data: {
          ...state.data,
          data: updateItemInArray(
            data,
            data.findIndex((x) => x.providerTask.id == action.payload.id),
            payloadData,
            true
          ),
        },
      };
    case GET_USER_LIST_WITH_SERVICE_ID:
      return {
        ...state,
        serviceSpecificUsers: {
          ...state.serviceSpecificUsers,
          data: [],
          success: false,
        },
      };
    case GET_USER_LIST_WITH_SERVICE_ID_SUCCESS:
      return {
        ...state,
        serviceSpecificUsers: {
          ...state.serviceSpecificUsers,
          data: action.payload,
          success: true,
        },
      };
    default:
      return { ...state };
  }
};
