/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const OTP_PASSWORD = 'OTP_PASSWORD';
export const OTP_PASSWORD_SUCCESS = 'OTP_PASSWORD_SUCCESS';
export const OTP_PASSWORD_ERROR = 'OTP_PASSWORD_ERROR';

/* MENU */
export const CREATE_ADMIN = 'CREATE_ADMIN';
export const CREATE_ADMIN_SUCCESS = 'CREATE_ADMIN_SUCCESS';
export const CREATE_ADMIN_FAILURE = 'CREATE_ADMIN_FAILURE';
export const GET_ALL_ADMINS = 'GET_ALL_ADMINS';
export const GET_ALL_ADMINS_SUCCESS = 'GET_ALL_ADMINS_SUCCESS';
export const GET_ALL_ADMINS_FAILURE = 'GET_ALL_ADMINS_FAILURE';
export const EDIT_ADMIN = 'EDIT_ADMIN';
export const EDIT_ADMIN_SUCCESS = 'EDIT_ADMIN_SUCCESS';
export const EDIT_ADMIN_FAILURE = 'EDIT_ADMIN_FAILURE';
export const GET_SINGLE_ADMIN = 'GET_SINGLE_ADMIN';
export const GET_SINGLE_ADMIN_SUCCESS = 'GET_SINGLE_ADMIN_SUCCESS';
export const GET_SINGLE_ADMIN_FAILURE = 'GET_SINGLE_ADMIN_FAILURE';
export const DELETE_ADMIN = 'DELETE_ADMIN';
export const DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS';
export const DELETE_ADMIN_FAILURE = 'DELETE_ADMIN_FAILURE';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

/* CATEGORY */
export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAILURE = 'CREATE_CATEGORY_FAILURE';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAILURE = 'GET_CATEGORIES_FAILURE';
export const EDIT_CATEGORY = 'EDIT_CATEGORY';
export const EDIT_CATEGORY_SUCCESS = 'EDIT_CATEGORY_SUCCESS';
export const EDIT_CATEGORY_FAILURE = 'EDIT_CATEGORY_FAILURE';
export const GET_SINGLE_CATEGORY = 'GET_SINGLE_CATEGORY';
export const GET_SINGLE_CATEGORY_SUCCESS = 'GET_SINGLE_CATEGORY_SUCCESS';
export const GET_SINGLE_CATEGORY_FAILURE = 'GET_SINGLE_CATEGORY_FAILURE';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAILURE = 'DELETE_CATEGORY_FAILURE';
export const GET_CATEGORY_SPECIFIC_SERVICES = 'GET_CATEGORY_SPECIFIC_SERVICES';
export const CATEGORY_SPECIFIC_SERVICES_SUCCESS =
  'CATEGORY_SPECIFIC_SERVICES_SUCCESS';
export const CATEGORY_SPECIFIC_SERVICES_FAILURE =
  'CATEGORY_SPECIFIC_SERVICES_FAILURE';

/* TAG */
export const CREATE_TAG = 'CREATE_TAG';
export const CREATE_TAG_SUCCESS = 'CREATE_TAG_SUCCESS';
export const CREATE_TAG_FAILURE = 'CREATE_TAG_FAILURE';
export const GET_TAGS = 'GET_TAGS';
export const GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS';
export const GET_TAGS_FAILURE = 'GET_TAGS_FAILURE';
export const EDIT_TAG = 'EDIT_TAG';
export const EDIT_TAG_SUCCESS = 'EDIT_TAG_SUCCESS';
export const EDIT_TAG_FAILURE = 'EDIT_TAG_FAILURE';
export const GET_SINGLE_TAG = 'GET_SINGLE_TAG';
export const GET_SINGLE_TAG_SUCCESS = 'GET_SINGLE_TAG_SUCCESS';
export const GET_SINGLE_TAG_FAILURE = 'GET_SINGLE_TAG_FAILURE';
export const DELETE_TAG = 'DELETE_TAG';
export const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS';
export const DELETE_TAG_FAILURE = 'DELETE_TAG_FAILURE';
export const APPROVE_TAG = 'APPROVE_TAG';
export const APPROVE_TAG_SUCCESS = 'APPROVE_TAG_SUCCESS';
export const APPROVE_TAG_FAILURE = 'APPROVE_TAG_FAILURE';

/* services */
export const CREATE_SERVICE = 'CREATE_SERVICE';
export const CREATE_SERVICE_SUCCESS = 'CREATE_SERVICE_SUCCESS';
export const CREATE_SERVICE_FAILURE = 'CREATE_SERVICE_FAILURE';
export const GET_SERVICES_SUCCESS = 'GET_SERVICES_SUCCESS';
export const GET_SERVICES_FAILURE = 'GET_SERVICES_FAILURE';
export const GET_SERVICES = 'GET_SERVICES';
export const EDIT_SERVICE = 'EDIT_SERVICE';
export const EDIT_SERVICE_SUCCESS = 'EDIT_SERVICE_SUCCESS';
export const EDIT_SERVICE_FAILURE = 'EDIT_SERVICE_FAILURE';
export const GET_SINGLE_SERVICE = 'GET_SINGLE_SERVICE';
export const GET_SINGLE_SERVICE_SUCCESS = 'GET_SINGLE_SERVICE_SUCCESS';
export const GET_SINGLE_SERVICE_FAILURE = 'GET_SINGLE_SERVICE_FAILURE';
export const DELETE_SERVICE = 'DELETE_SERVICE';
export const DELETE_SERVICE_SUCCESS = 'DELETE_SERVICE_SUCCESS';
export const DELETE_SERVICE_FAILURE = 'DELETE_SERVICE_FAILURE';
export const GET_SERVICE_TASKS = 'GET_SERVICE_TASKS';
export const GET_SERVICE_TASKS_SUCCESS = 'GET_SERVICE_TASKS_SUCCESS';
export const GET_SERVICE_TASKS_FAILURE = 'GET_SERVICE_TASKS_FAILURE';
export const GET_PROVIDER_TASKS = 'GET_PROVIDER_TASKS';
export const GET_PROVIDER_TASKS_SUCCESS = 'GET_PROVIDER_TASKS_SUCCESS';
export const GET_PROVIDER_TASKS_FAILURE = 'GET_PROVIDER_TASKS_FAILURE';
export const REMOVE_PROVIDER_TASKS = 'REMOVE_PROVIDER_TASKS';
export const REMOVE_PROVIDER_TASKS_SUCCESS = 'REMOVE_PROVIDER_TASKS_SUCCESS';
export const REMOVE_PROVIDER_TASKS_FAILURE = 'REMOVE_PROVIDER_TASKS_FAILURE';
/* USER ROLES */
export const CREATE_USER_ROLES = 'CREATE_USER_ROLES';
export const CREATE_USER_ROLES_SUCCESS = 'CREATE_USER_ROLES_SUCCESS';
export const CREATE_USER_ROLES_FAILURE = 'CREATE_USER_ROLES_FAILURE';
export const GET_USER_ROLES = 'GET_USER_ROLES';
export const GET_USER_ROLES_SUCCESS = 'GET_USER_ROLES_SUCCESS';
export const GET_USER_ROLES_FAILURE = 'GET_USER_ROLES_FAILURE';
export const DELETE_USER_ROLE = 'DELETE_USER_ROLE';
export const DELETE_USER_ROLE_SUCCESS = 'DELETE_USER_ROLE_SUCCESS';
export const DELETE_USER_ROLE_FAILURE = 'DELETE_USER_ROLE_FAILURE';
export const EDIT_USER_ROLE = 'EDIT_USER_ROLE';
export const EDIT_USER_ROLE_SUCCESS = 'EDIT_USER_ROLE_SUCCESS';
export const EDIT_USER_ROLE_FAILURE = 'EDIT_USER_ROLE_FAILURE ';

/* PROVIDERS */
export const CREATE_NEW_PROVIDER = 'CREATE_NEW_PROVIDER';
export const CREATE_NEW_PROVIDER_SUCCESS = 'CREATE_NEW_PROVIDER_SUCCESS';
export const CREATE_NEW_PROVIDER_FAILURE = 'CREATE_NEW_PROVIDER_FAILURE';
export const GET_ALL_PROVIDERS = 'GET_ALL_PROVIDERS';
export const GET_ALL_PROVIDERS_SUCCESS = 'GET_ALL_PROVIDERS_SUCCESS';
export const GET_ALL_PROVIDERS_FAILURE = 'GET_ALL_PROVIDERS_FAILURE';
export const DELETE_PROVIDER = 'DELETE_PROVIDER';
export const DELETE_PROVIDER_SUCCESS = 'DELETE_PROVIDER_SUCCESS';
export const DELETE_PROVIDER_FAILURE = 'DELETE_PROVIDER_FAILURE';
export const EDIT_PROVIDER = 'EDIT_PROVIDER';
export const EDIT_PROVIDER_SUCCESS = 'EDIT_PROVIDER_SUCCESS';
export const EDIT_PROVIDER_FAILURE = 'EDIT_PROVIDER_FAILURE';
export const GET_SINGLE_PROVIDER = 'GET_SINGLE_PROVIDER';
export const GET_SINGLE_PROVIDER_SUCCESS = 'GET_SINGLE_PROVIDER_SUCCESS';
export const GET_SINGLE_PROVIDER_FAILURE = 'GET_SINGLE_PROVIDER_FAILURE';
export const ADD_SERVICE_TO_PROVIDER = 'ADD_SERVICE_TO_PROVIDER';
export const UPDATE_SERVICE_TO_PROVIDER = 'UPDATE_SERVICE_TO_PROVIDER';
export const UPDATE_SERVICE_TO_PROVIDER_SUCCESS = 'UPDATE_SERVICE_TO_PROVIDER_SUCCESS';

export const GET_ADMINISTRATOR_LIST_WITH_SERVICE_ID_AND_PROVIDER_ID= 'GET_ADMINISTRATOR_LIST_WITH_SERVICE_ID_AND_PROVIDER_ID';
export const GET_ADMINISTRATOR_LIST_WITH_SERVICE_ID_AND_PROVIDER_ID_SUCCESS = 'GET_ADMINISTRATOR_LIST_WITH_SERVICE_ID_AND_PROVIDER_ID_SUCCESS';
export const GET_USER_LIST_WITH_SERVICE_ID = 'GET_USER_LIST_WITH_SERVICE_ID';
export const GET_USER_LIST_WITH_SERVICE_ID_SUCCESS = 'GET_USER_LIST_WITH_SERVICE_ID_SUCCESS';
export const ADD_SERVICE_TO_PROVIDER_SUCCESS =
  'ADD_SERVICE_TO_PROVIDER_SUCCESS';
export const ADD_SERVICE_TO_PROVIDER_FAILURE =
  'ADD_SERVICE_TO_PROVIDER_FAILURE';
/* bookings */
export const CREATE_BOOKING = 'CREATE_BOOKING';
export const CREATE_BOOKING_SUCCESS = 'CREATE_BOOKING_SUCCESS';
export const CREATE_BOOKING_FAILURE = 'CREATE_BOOKING_FAILURE';
export const GET_BOOKINGS = 'GET_BOOKINGS';
export const GET_BOOKINGS_SUCCESS = 'GET_BOOKINGS_SUCCESS';
export const GET_BOOKINGS_FAILURE = 'GET_BOOKINGS_FAILURE';
export const EDIT_BOOKING = 'EDIT_BOOKING';
export const EDIT_BOOKING_SUCCESS = 'EDIT_BOOKING_SUCCESS';
export const EDIT_BOOKING_FAILURE = 'EDIT_BOOKING_FAILURE';
export const GET_SINGLE_BOOKING = 'GET_SINGLE_BOOKING';
export const GET_SINGLE_BOOKING_SUCCESS = 'GET_SINGLE_BOOKING_SUCCESS';
export const GET_SINGLE_BOOKING_FAILURE = 'GET_SINGLE_BOOKING_FAILURE';
export const DELETE_BOOKING = 'DELETE_BOOKING';
export const DELETE_BOOKING_SUCCESS = 'DELETE_BOOKING_SUCCESS';
export const DELETE_BOOKING_FAILURE = 'DELETE_BOOKING_FAILURE';
export const GET_BOOKINGS_WITH_STATUS = 'GET_BOOKINGS_WITH_STATUS';
export const GET_BOOKINGS_WITH_STATUS_SUCCESS =
  'GET_BOOKINGS_WITH_STATUS_SUCCESS';
export const GET_BOOKINGS_WITH_STATUS_FAILURE =
  'GET_BOOKINGS_WITH_STATUS_FAILURE';
export const GET_BOOKINGS_WITH_SERVICES = 'GET_BOOKINGS_WITH_SERVICES';
export const GET_BOOKINGS_WITH_SERVICES_SUCCESS =
  'GET_BOOKINGS_WITH_SERVICES_SUCCESS';
export const GET_BOOKINGS_WITH_SERVICES_FAILURE =
  'GET_BOOKINGS_WITH_SERVICES_FAILURE';
export const GET_REVIEWS_FOR_PROVIDER = 'GET_REVIEWS_FOR_PROVIDER';

/* SUPPORT TICKET */
export const CREATE_SUPPORT_TICKET = 'CREATE_SUPPORT_TICKET';
export const CREATE_SUPPORT_TICKET_SUCCESS = 'CREATE_SUPPORT_TICKET_SUCCESS';
export const CREATE_SUPPORT_TICKET_FAILURE = 'CREATE_SUPPORT_TICKET_FAILURE';
export const GET_SUPPORT_TICKETS = 'GET_SUPPORT_TICKETS';
export const GET_SUPPORT_TICKETS_SUCCESS = 'GET_SUPPORT_TICKETS_SUCCESS';
export const GET_SUPPORT_TICKETS_FAILURE = 'GET_SUPPORT_TICKETS_FAILURE';
export const EDIT_SUPPORT_TICKET = 'EDIT_SUPPORT_TICKET';
export const EDIT_SUPPORT_TICKET_SUCCESS = 'EDIT_SUPPORT_TICKET_SUCCESS';
export const EDIT_SUPPORT_TICKET_FAILURE = 'EDIT_SUPPORT_TICKET_FAILURE';
export const GET_SINGLE_SUPPORT_TICKET = 'GET_SINGLE_SUPPORT_TICKET';
export const GET_SINGLE_SUPPORT_TICKET_SUCCESS =
  'GET_SINGLE_SUPPORT_TICKET_SUCCESS';
export const GET_SINGLE_SUPPORT_TICKET_FAILURE =
  'GET_SINGLE_SUPPORT_TICKET_FAILURE';
export const DELETE_SUPPORT_TICKET = 'DELETE_SUPPORT_TICKET';
export const DELETE_SUPPORT_TICKET_SUCCESS = 'DELETE_SUPPORT_TICKET_SUCCESS';
export const DELETE_SUPPORT_TICKET_FAILURE = 'DELETE_SUPPORT_TICKET_FAILURE';
export const GET_TICKET_HISTORY = 'GET_TICKET_HISTORY';
export const GET_TICKET_HISTORY_SUCCESS = 'GET_TICKET_HISTORY_SUCCESS';
export const GET_TICKET_HISTORY_FAILURE = 'GET_TICKET_HISTORY_FAILURE';
export const GET_TICKETS_BY_SERVICE = 'GET_TICKETS_BY_SERVICE';
export const GET_TICKETS_BY_SERVICE_SUCCESS = 'GET_TICKETS_BY_SERVICE_SUCCESS';
export const GET_TICKETS_BY_SERVICE_FAILURE = 'GET_TICKETS_BY_SERVICE_FAILURE';
export const ADD_TICKET_HISTORY = 'ADD_TICKET_HISTORY_BY_TICKET_ID';
// Upload image
// Upload image
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILURE = 'UPLOAD_IMAGE_FAILURE';
export const UPLOAD_IMAGE_RESET = 'UPLOAD_IMAGE_RESET';
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';
export const GET_MEDIAS = 'GET_MEDIAS';
export const GET_MEDIA_SUCCESS = 'GET_MEDIA_SUCCESS';
export const GET_MEDIA_FAILURE = 'GET_MEDIA_FAILURE';
export const CREATE_MEDIA = 'CREATE_MEDIA';
export const CREATE_MEDIA_SUCCESS = 'CREATE_MEDIA_SUCCESS';
export const CREATE_MEDIA_FAILURE = 'CREATE_MEDIA_FAILURE';

//meta-media
export const CREATE_MEDIA_META = 'CREATE_MEDIA_META';
export const CREATE_MEDIA_META_SUCCESS = 'CREATE_MEDIA_META_SUCCESS';
export const CREATE_MEDIA_META_FAILURE = 'CREATE_MEDIA_META_FAILURE';
// CLIENTS
export const CREATE_NEW_CLIENT = 'CREATE_NEW_CLIENT';
export const CREATE_NEW_CLIENT_SUCCESS = 'CREATE_NEW_CLIENT_SUCCESS';
export const CREATE_NEW_CLIENT_FAILURE = 'CREATE_NEW_CLIENT_FAILURE';
export const GET_ALL_CLIENTS = 'GET_ALL_CLIENTS';
export const GET_ALL_CLIENTS_SUCCESS = 'GET_ALL_CLIENTS_SUCCESS';
export const GET_ALL_CLIENTS_FAILURE = 'GET_ALL_CLIENTS_FAILURE';
export const GET_ALL_EMPLOYEES = 'GET_ALL_EMPLOYEES';
export const GET_ALL_EMPLOYEES_SUCCESS = 'GET_ALL_EMPLOYEES_SUCCESS';
export const GET_ALL_EMPLOYEES_FAILURE = 'GET_ALL_EMPLOYEES_FAILURE';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS';
export const DELETE_CLIENT_FAILURE = 'DELETE_CLIENT_FAILURE';
export const EDIT_CLIENT = 'EDIT_CLIENT';
export const EDIT_CLIENT_SUCCESS = 'EDIT_CLIENT_SUCCESS';
export const EDIT_CLIENT_FAILURE = 'EDIT_CLIENT_FAILURE';
export const GET_SINGLE_CLIENT = 'GET_SINGLE_CLIENT';
export const GET_SINGLE_CLIENT_SUCCESS = 'GET_SINGLE_CLIENT_SUCCESS';
export const GET_SINGLE_CLIENT_FAILURE = 'GET_SINGLE_CLIENT_FAILURE';
export const EDIT_CLIENT_BY_ADMIN = 'EDIT_CLIENT_BY_ADMIN';
export const EDIT_CLIENT_BY_ADMIN_SUCCESS = 'EDIT_CLIENT_BY_ADMIN_SUCCESS';
export const EDIT_CLIENT_BY_ADMIN_FAILURE = 'EDIT_CLIENT_BY_ADMIN_FAILURE';

// locations
export const CREATE_LOCATION = 'CREATE_LOCATION';
export const CREATE_LOCATION_SUCCESS = 'CREATE_LOCATION_SUCCESS';
export const CREATE_LOCATION_FAILURE = 'CREATE_LOCATION_FAILURE';
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS';
export const GET_LOCATIONS_FAILURE = 'GET_LOCATIONS_FAILURE';
export const GET_LOCATIONS = 'GET_LOCATIONS';
export const EDIT_LOCATION = 'EDIT_LOCATION';
export const EDIT_LOCATION_SUCCESS = 'EDIT_LOCATION_SUCCESS';
export const EDIT_LOCATION_FAILURE = 'EDIT_LOCATION_FAILURE';
export const GET_SINGLE_LOCATION = 'GET_SINGLE_LOCATION';
export const GET_SINGLE_LOCATION_SUCCESS = 'GET_SINGLE_LOCATION_SUCCESS';
export const GET_SINGLE_LOCATION_FAILURE = 'GET_SINGLE_LOCATION_FAILURE';
export const DELETE_LOCATION = 'DELETE_LOCATION';
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS';
export const DELETE_LOCATION_FAILURE = 'DELETE_LOCATION_FAILURE';
export const GET_CURRENT_LOCATION = 'GET_CURRENT_LOCATION';
export const GET_CURRENT_LOCATION_SUCCESS = 'GET_CURRENT_LOCATION_SUCCESS';
export const GET_LOCATIONS_SEARCH = 'GET_LOCATIONS_SEARCH';

// ads
export const CREATE_AD = 'CREATE_AD';
export const CREATE_AD_SUCCESS = 'CREATE_AD_SUCCESS';
export const CREATE_AD_FAILURE = 'CREATE_AD_FAILURE';
export const GET_ADS_SUCCESS = 'GET_ADS_SUCCESS';
export const GET_ADS_FAILURE = 'GET_ADS_FAILURE';
export const GET_ADS = 'GET_ADS';
export const EDIT_AD = 'EDIT_AD';
export const EDIT_AD_SUCCESS = 'EDIT_AD_SUCCESS';
export const EDIT_AD_FAILURE = 'EDIT_AD_FAILURE';
export const GET_SINGLE_AD = 'GET_SINGLE_AD';
export const GET_SINGLE_AD_SUCCESS = 'GET_SINGLE_AD_SUCCESS';
export const GET_SINGLE_AD_FAILURE = 'GET_SINGLE_AD_FAILURE';
export const DELETE_AD = 'DELETE_AD';
export const DELETE_AD_SUCCESS = 'DELETE_AD_SUCCESS';
export const DELETE_AD_FAILURE = 'DELETE_AD_FAILURE';
export const GET_CURRENT_AD = 'GET_CURRENT_AD';
export const GET_CURRENT_AD_SUCCESS = 'GET_CURRENT_AD_SUCCESS';
export const GET_ALL_ADS = 'GET_ALL_ADS';
export const GET_ALL_ADS_SUCCESS = 'GET_ALL_ADS_SUCCESS';
export const GET_ALL_ADS_FAILURE = 'GET_ALL_ADS_FAILURE';

//certificates
export const CREATE_CERTIFICATE = 'CREATE_CERTIFICATE';
export const CREATE_CERTIFICATE_SUCCESS = 'CREATE_CERTIFICATE_SUCCESS';
export const CREATE_CERTIFICATE_FAILURE = 'CREATE_CERTIFICATE_FAILURE';
export const GET_CERTIFICATES_SUCCESS = 'GET_CERTIFICATES_SUCCESS';
export const GET_CERTIFICATES_FAILURE = 'GET_CERTIFICATES_FAILURE';
export const GET_CERTIFICATES = 'GET_CERTIFICATES';
export const GET_All_CERTIFICATES_SUCCESS = 'GET_All_CERTIFICATES_SUCCESS';
export const GET_All_CERTIFICATES_FAILURE = 'GET_All_CERTIFICATES_FAILURE';
export const GET_All_CERTIFICATES = 'GET_All_CERTIFICATES';
export const EDIT_CERTIFICATE = 'EDIT_CERTIFICATE';
export const EDIT_CERTIFICATE_SUCCESS = 'EDIT_CERTIFICATE_SUCCESS';
export const EDIT_CERTIFICATE_FAILURE = 'EDIT_CERTIFICATE_FAILURE';
export const GET_SINGLE_CERTIFICATE = 'GET_SINGLE_CERTIFICATE';
export const GET_SINGLE_CERTIFICATE_SUCCESS = 'GET_SINGLE_CERTIFICATE_SUCCESS';
export const GET_SINGLE_CERTIFICATE_FAILURE = 'GET_SINGLE_CERTIFICATE_FAILURE';
export const DELETE_CERTIFICATE = 'DELETE_CERTIFICATE';
export const DELETE_CERTIFICATE_SUCCESS = 'DELETE_CERTIFICATE_SUCCESS';
export const DELETE_CERTIFICATE_FAILURE = 'DELETE_CERTIFICATE_FAILURE';

// GET ALL USERS
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAILURE = 'GET_ALL_USERS_FAILURE';

// PROVIDER SPECIFIC SERVICES
export const GET_PROVIDER_SPECIFIC_SERVICES = 'GET_PROVIDER_SPECIFIC_SERVICES';
export const PROVIDER_SPECIFIC_SERVICES_SUCCESS =
  'PROVIDER_SPECIFIC_SERVICES_SUCCESS';
export const PROVIDER_SPECIFIC_SERVICES_FAILURE =
  'PROVIDER_SPECIFIC_SERVICES_FAILURE';

export const GET_REVIEWS = 'GET_REVIEWS';
export const GET_REVIEWS_SUCCESS = 'GET_REVIEWS_SUCCESS';
export const GET_REVIEWS_FAILURE = 'GET_REVIEWS_FAILURE';
export const EDIT_REVIEW = 'EDIT_REVIEW';
export const EDIT_REVIEW_SUCCESS = 'EDIT_REVIEW_SUCCESS';
export const EDIT_REVIEW_FAILURE = 'EDIT_REVIEW_FAILURE';

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE';
export const MARK_NOTIFICATION_READ = 'MARK_NOTIFICATION_READ';
export const MARK_NOTIFICATION_READ_SUCCESS = 'MARK_NOTIFICATION_READ_SUCCESS';
export const MARK_NOTIFICATION_READ_FAILURE = 'MARK_NOTIFICATION_READ_FAILURE';
export const MARK_NOTIFICATION_READ_BY_PROVIDER = 'MARK_NOTIFICATION_READ_BY_PROVIDER'

// Financial
export const GET_ALL_TRANSACTIONS = 'GET_ALL_TRANSACTIONS';
export const GET_ALL_TRANSACTIONS_SUCCESS = 'GET_ALL_TRANSACTIONS_SUCCESS';
export const GET_ALL_TRANSACTIONS_FAILURE = 'GET_ALL_TRANSACTIONS_FAILURE';

// survey
export const SUBMIT_SURVEY = 'SUBMIT_SURVEY';
export const SUBMIT_SURVEY_SUCCESS = 'SUBMIT_SURVEY_SUCCESS';
export const SUBMIT_SURVEY_FAILURE = 'SUBMIT_SURVEY_FAILURE';
export const GET_SURVEY_ANSWERS = 'GET_SURVEY_ANSWERS';
export const GET_SURVEY_ANSWERS_SUCCESS = 'GET_SURVEY_ANSWERS_SUCCESS';
export const GET_SURVEY_ANSWERS_FAILURE = 'GET_SURVEY_ANSWERS_SUCCESS';

// User-Clients
export const GET_USER_CLIENTS = 'GET_USER_CLIENTS';
export const GET_USER_CLIENTS_SUCCESS = 'GET_USER_CLIENTS_SUCCESS';
export const GET_USER_CLIENTS_FAILURE = 'GET_USER_CLIENTS_FAILURE';
export const DELETE_USER_CLIENTS = 'DELETE_USER_CLIENTS';
export const DELETE_USER_CLIENTS_SUCCESS = 'DELETE_USER_CLIENTS_SUCCESS';
export const DELETE_USER_CLIENTS_FAILURE = 'DELETE_USER_CLIENTS_FAILURE';
export const EDIT_USER_CLIENTS = 'EDIT_USER_CLIENTS';
export const EDIT_USER_CLIENTS_SUCCESS = 'EDIT_USER_CLIENTS_SUCCESS';
export const EDIT_USER_CLIENTS_FAILURE = 'EDIT_USER_CLIENTS_FAILURE';
export const GET_SINGLE_USER_CLIENTS = 'GET_SINGLE_USER_CLIENTS';
export const GET_SINGLE_USER_CLIENTS_SUCCESS =
  'GET_SINGLE_USER_CLIENTS_SUCCESS';
export const GET_SINGLE_USER_CLIENTS_FAILURE =
  'GET_SINGLE_USER_CLIENTS_FAILURE';

//Login as provider
export const LOGIN_AS_PROVIDER = 'LOGIN_AS_PROVIDER'

export const GET_ALL_BCGS = 'GET_ALL_BCGS';
export const GET_ALL_BCGS_SUCCESS = 'GET_ALL_BCGS_SUCCESS';
export const GET_ALL_BCGS_FAILURE = 'GET_ALL_BCGS_FAILURE';
export const DELETE_BCG = 'DELETE_BCG';
export const DELETE_BCG_SUCCESS = 'DELETE_BCG_SUCCESS';
export const DELETE_BCG_FAILURE = 'DELETE_BCG_FAILURE';
export const EDIT_BCG = 'EDIT_BCG';
export const EDIT_BCG_SUCCESS = 'EDIT_BCG_SUCCESS';
export const EDIT_BCG_FAILURE = 'EDIT_BCG_FAILURE';
export const GET_SINGLE_BCG = 'GET_SINGLE_BCG';
export const GET_SINGLE_BCG_SUCCESS = 'GET_SINGLE_BCG_SUCCESS';
export const GET_SINGLE_BCG_FAILURE = 'GET_SINGLE_BCG_FAILURE';

export const EDIT_APP_CONFIGURATION = 'EDIT_APP_CONFIGURATION';
export const EDIT_APP_CONFIGURATION_SUCCESS = 'EDIT_APP_CONFIGURATION_SUCCESS';
export const EDIT_APP_CONFIGURATION_FAILURE = 'EDIT_APP_CONFIGURATION_FAILURE';
export const GET_APP_CONFIGURATIONS = 'GET_APP_CONFIGURATIONS';
export const GET_APP_CONFIGURATIONS_SUCCESS = 'GET_APP_CONFIGURATIONS_SUCCESS';
export const GET_APP_CONFIGURATIONS_FAILURE = 'GET_APP_CONFIGURATIONS_FAILURE';

export const GET_PERSONAL_REGTD_YEAR = 'GET_PERSONAL_REGTD_YEAR';
export const GET_PERSONAL_REGTD_YEAR_SUCCESS =
  'GET_PERSONAL_REGTD_YEAR_SUCCESS';
export const GET_PERSONAL_REGTD_YEAR_ERROR = 'GET_PERSONAL_REGTD_YEAR_ERROR';

export const GET_AUTOSUGGEST_COMPANY = 'GET_AUTOSUGGEST_COMPANY';
export const AUTOSUGGEST_COMPANY_SUCCESS = 'AUTOSUGGEST_COMPANY_SUCCESS';
export const AUTOSUGGEST_COMPANY_FAILURE = 'AUTOSUGGEST_COMPANY_FAILURE';

export * from './menu/actions';
export * from './settings/actions';
export * from './auth/actions';
export * from './categories/actions';
export * from './services/actions';
export * from './user-roles/actions';
export * from './providers/actions';
export * from './media/actions';
export * from './clients/actions';
export * from './locations/actions';
export * from './certificates/actions';
export * from './media-meta/actions';
export * from './bookings/actions';
export * from './reviews/actions';
export * from './financial/actions';
export * from './notifications/actions';
export * from './admin/actions';
export * from './survey/actions';
export * from './user-clients/actions';
export * from './bcg-request/actions';
export * from './app-configuration/actions';
export * from './graph/actions';
export * from './users-meta/actions';
