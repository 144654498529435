import {
	CREATE_SERVICE,
	CREATE_SERVICE_SUCCESS,
	CREATE_SERVICE_FAILURE,
	GET_SERVICES_SUCCESS,
	GET_SERVICES_FAILURE,
	GET_SERVICES,
	EDIT_SERVICE,
	EDIT_SERVICE_SUCCESS,
	EDIT_SERVICE_FAILURE,
	GET_SINGLE_SERVICE,
	GET_SINGLE_SERVICE_SUCCESS,
	GET_SINGLE_SERVICE_FAILURE,
	DELETE_SERVICE,
	DELETE_SERVICE_SUCCESS,
	DELETE_SERVICE_FAILURE,
	GET_CATEGORY_SPECIFIC_SERVICES,
	CATEGORY_SPECIFIC_SERVICES_SUCCESS,
	CATEGORY_SPECIFIC_SERVICES_FAILURE,
	GET_SERVICE_TASKS,
	GET_SERVICE_TASKS_SUCCESS,
	GET_SERVICE_TASKS_FAILURE,
	ADD_SERVICE_TO_PROVIDER,
	ADD_SERVICE_TO_PROVIDER_SUCCESS,
	ADD_SERVICE_TO_PROVIDER_FAILURE,
	GET_PROVIDER_TASKS,
	GET_PROVIDER_TASKS_SUCCESS,
	GET_PROVIDER_TASKS_FAILURE,
	REMOVE_PROVIDER_TASKS,
	REMOVE_PROVIDER_TASKS_SUCCESS,
	REMOVE_PROVIDER_TASKS_FAILURE,
	UPDATE_SERVICE_TO_PROVIDER,
	UPDATE_SERVICE_TO_PROVIDER_SUCCESS,
	GET_ADMINISTRATOR_LIST_WITH_SERVICE_ID_AND_PROVIDER_ID,
	GET_ADMINISTRATOR_LIST_WITH_SERVICE_ID_AND_PROVIDER_ID_SUCCESS,
	GET_USER_LIST_WITH_SERVICE_ID,
	GET_USER_LIST_WITH_SERVICE_ID_SUCCESS,
} from "../actions";

export const createService = data => {
	return {
		type: CREATE_SERVICE,
		payload: data
	};
};

export const createServiceSuccess = msg => {
	return {
		type: CREATE_SERVICE_SUCCESS,
		payload: msg
	};
};

export const createServiceFailure = error => {
	return {
		type: CREATE_SERVICE_FAILURE,
		payload: error
	};
};

export const getServices = (data) => {
	return {
		type: GET_SERVICES,
		payload: data
	};
};

export const serviceSuccess = data => {
	return {
		type: GET_SERVICES_SUCCESS,
		payload: data
	};
};

export const serviceFailure = error => {
	return {
		type: GET_SERVICES_FAILURE,
		payload: error
	};
};

export const editService = data => {
	return {
		type: EDIT_SERVICE,
		payload: data
	};
};

export const editServiceSuccess = (data) => {
	return {
		type: EDIT_SERVICE_SUCCESS,
		payload: data
	};
};

export const editServiceFailure = error => {
	return {
		type: EDIT_SERVICE_FAILURE,
		payload: error
	};
};

export const getSingleService = id => {
	return {
		type: GET_SINGLE_SERVICE,
		payload: id
	};
};

export const getSingleServiceSuccess = data => {
	return {
		type: GET_SINGLE_SERVICE_SUCCESS,
		payload: data
	};
};

export const getSingleServiceFailure = error => {
	return {
		type: GET_SINGLE_SERVICE_FAILURE,
		payload: error
	};
};

export const deleteService = id => {
	return {
		type: DELETE_SERVICE,
		payload: id
	}
}
export const deleteServiceSuccess = (data) => {
	return {
		type: DELETE_SERVICE_SUCCESS,
		payload: data
	}
}
export const deleteServiceFailure = error => {
	return {
		type: DELETE_SERVICE_FAILURE,
		payload: error
	}
}

export const categorySpecificServices = catId => {
	return {
		type: GET_CATEGORY_SPECIFIC_SERVICES,
		payload: catId
	}
}
export const getServiceTasks = id => {
	return {
		type: GET_SERVICE_TASKS,
		payload: id
	}
}

export const categorySpecificServicesSuccess = data => {
	return {
		type: CATEGORY_SPECIFIC_SERVICES_SUCCESS,
		payload: data
	}
}
export const categorySpecificServicesFailure = error => {
	return {
		type: CATEGORY_SPECIFIC_SERVICES_FAILURE,
		payload: error
	}
}

export const getServiceTasksSuccess = data => {
	return {
		type: GET_SERVICE_TASKS_SUCCESS,
		payload: data
	};
};

export const getServiceTasksFailure = error => {
	return {
		type: GET_SERVICE_TASKS_FAILURE,
		payload: error
	};
};

export const addServiceToProvider = (data) => {
	return {
		type: ADD_SERVICE_TO_PROVIDER,
		payload: data
	};
}

export const addServiceToProviderSuccess = data => {
	return {
		type: ADD_SERVICE_TO_PROVIDER_SUCCESS,
		payload: data
	};
};

export const updateServiceToProvider = (data) => {
	return {
		type: UPDATE_SERVICE_TO_PROVIDER,
		payload: data
	};
}

export const updateServiceToProviderSuccess = (data) => {
	return {
		type: UPDATE_SERVICE_TO_PROVIDER_SUCCESS,
		payload: data
	};
}



export const addServiceToProviderFailure = error => {
	return {
		type: ADD_SERVICE_TO_PROVIDER_FAILURE,
		payload: error
	};
};

export const getProviderTasks = data => {
	return {
		type: GET_PROVIDER_TASKS,
		payload: data
	}
}

export const getProviderTasksSuccess = data => {
	return {
		type: GET_PROVIDER_TASKS_SUCCESS,
		payload: data
	};
};

export const getProviderTasksFailure = error => {
	return {
		type: GET_PROVIDER_TASKS_FAILURE,
		payload: error
	};
};

export const removeProviderTasks = data => {
	return {
		type: REMOVE_PROVIDER_TASKS,
		payload: data
	};
};

export const removeProviderTasksSuccess = data => {
	return {
		type: REMOVE_PROVIDER_TASKS_SUCCESS,
		payload: data
	};
};

export const removeProviderTasksFailure = error => {
	return {
		type: REMOVE_PROVIDER_TASKS_FAILURE,
		payload: error
	};
};

export const getAdminstratorPersonnelWithServiceId = data => {
	return {
		type: GET_ADMINISTRATOR_LIST_WITH_SERVICE_ID_AND_PROVIDER_ID,
		payload: data
	}
}

export const getAdminstratorPersonnelWithServiceIdSuccess = data => {
	return {
		type: GET_ADMINISTRATOR_LIST_WITH_SERVICE_ID_AND_PROVIDER_ID_SUCCESS,
		payload: data
	};
};

export const getUserListWithServiceId = data => {
	return {
		type: GET_USER_LIST_WITH_SERVICE_ID,
		payload: data
	};
};

export const getUserListWithServiceIdSuccess = data => {
	return {
		type: GET_USER_LIST_WITH_SERVICE_ID_SUCCESS,
		payload: data
	};
};

// export const getAdminstratorPersonnelWithServiceIdFailure = error => {
// 	return {
// 		type: GET_SERVICE_TASKS_FAILURE,
// 		payload: error
// 	};
// };
