import { put, call, takeLatest } from "redux-saga/effects";
import { fetchNotifications, patchNotifications , markNotificationReadByProviderAdmin } from "./api";
import {
    getNotificationsSuccess,
    getNotificationsFailure,
    markNotificationReadSuccess,
    markNotificationReadFailure,
    markNotificationReadByProvider
} from "./actions";

function* getNotificationsSaga(action) {
    const response = yield call(fetchNotifications, action.payload)
    if (response !== undefined) yield put(getNotificationsSuccess(response))
    else yield put(getNotificationsFailure(response))
}

function* markNotificationReadSaga(action) {
  const response = yield call(patchNotifications, action.payload)
  if (response !== undefined) yield put(markNotificationReadSuccess(response))
  else yield put(markNotificationReadFailure(response))
}

function* markNotificationReadByProviderSaga(action) {
  const response = yield call(markNotificationReadByProviderAdmin, action.payload)
  // if (response !== undefined) yield put(markNotificationReadSuccess(response))
  // else yield put(markNotificationReadFailure(response))
}

export default function* rootSaga() {
  yield takeLatest("GET_NOTIFICATIONS", getNotificationsSaga);
  yield takeLatest("MARK_NOTIFICATION_READ", markNotificationReadSaga);
  yield takeLatest("MARK_NOTIFICATION_READ_BY_PROVIDER", markNotificationReadByProviderSaga);
}
