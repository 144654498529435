/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import client from '../../feathers';

const bookingService = client.service('bookings');
const serviceService = client.service('services');
const reviewService = client.service('reviews');

export const create = async (data) => {
  try {
    return await bookingService.create({
      name: data.name,
      timeRequested: data.timeRequested,
      paidAmount: data.paidAmount,
      categoryId: data.categoryId,
      serviceId: data.serviceId,
      status: data.status.value,
      currency: data.currency,
      // employeeId: data.employeeId,
      // providerId: data.providerId
    });
  } catch (error) {
    return error;
  }
};

export const get = async (data) => {
  try {
    if(data){
      if(data.isPaginateFalse){
        // fixed error for dashboard pie chart booking data
        delete data.isPaginateFalse;
        let resp =  await bookingService.find({
          query: {
            ...(data || {}),
            // paginate: false,
            status: (data || {}).status || {
              $in: ['closed', 'cancelled', 'pending', 'progress'],
            },
          },
        });
        return resp.data;
      }
    }
    if (!data || (!data.limit && !data.skip)) {

      let resp =  await bookingService.find({
        query: {
          ...(data || {}),
          paginate: false,
          status: (data || {}).status || {
            $in: ['closed', 'cancelled', 'pending', 'progress'],
          },
        },
      });
      return resp;
    }
    else {
      data.$limit = data.limit;
      data.$skip = data.skip;
      delete data.limit;
      delete data.skip;
      let resp =  await bookingService.find({
        query: {
          ...data,
          $sort: {
            id: -1
          },
          status: data.status || {
            $in: ['closed', 'cancelled', 'pending', 'progress'],
          },
        },
      });
      return resp;
    }
  } catch (error) {
    return error;
  }
};

export const getOne = async (id) => {
  try {
    return await bookingService.get(id);
  } catch (error) {
    return error;
  }
};

export const update = async (id, data) => {
  try {
    return await bookingService.patch(id, data);
  } catch (error) {
    return error;
  }
};

export const updateRating = async (id, data) => {
  try {
    let rating = {};
    if (id == null && data.rating > 0) {
      rating = await reviewService.create({
        rating: data.rating,
        createdBy: data.createdBy,
        providerId: data.providerId,
        bookingId: data.bookingId,
        comment: data.comment,
        status: false,
      });
    } else if (id == null && data.rating == 0) {
      rating = { id: null };
    } else {
      rating = await reviewService.patch(id, data);
    }
    return rating;
  } catch (error) {
    return error;
  }
};

export const remove = async (id) => {
  try {
    return await bookingService.remove(id);
  } catch (error) {
    return error;
  }
};

export const getWithServices = async () => {
  try {
    const iii = await bookingService.find({
      include: [
        {
          model: serviceService.Model,
          as: 'eeeee',
          // query: {
          //     $select: [
          //         'name'
          //     ]
          // }
        },
      ],
    });
    return iii;
  } catch (error) {
    return error;
  }
};

// export const update = async () => {
//   try {
//     return await bookingService.update
//   }
// }
