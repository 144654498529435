import {
  GET_PROVIDER_SPECIFIC_SERVICES,
  PROVIDER_SPECIFIC_SERVICES_SUCCESS,
  PROVIDER_SPECIFIC_SERVICES_FAILURE,
  GET_ALL_TRANSACTIONS,
  GET_ALL_TRANSACTIONS_SUCCESS,
  GET_ALL_TRANSACTIONS_FAILURE
} from "../actions";

export const providerSpecificServices = query => {
  return {
    type: GET_PROVIDER_SPECIFIC_SERVICES,
    payload: query
  }
}
export const getSpecificServicesByProviderSuccess = data => {
  return {
    type: PROVIDER_SPECIFIC_SERVICES_SUCCESS,
    payload: data
  }
}

export const getSpecificServicesByProviderFailure = error => {
  return {
    type: PROVIDER_SPECIFIC_SERVICES_FAILURE,
    payload: error
  }
}

export const getAllTransactions = query => {
  return {
    type: GET_ALL_TRANSACTIONS,
    payload: query
  }
}
export const getAllTransactionsSuccess = data => {
  return {
    type: GET_ALL_TRANSACTIONS_SUCCESS,
    payload: data
  }
}
export const getAllTransactionsFailure = error => {
  return {
    type: GET_ALL_TRANSACTIONS_FAILURE,
    payload: error
  }
}