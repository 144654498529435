import { put, call, takeLatest } from "redux-saga/effects";
import { create, get, getOne, update, remove, getAd, getAllAdsApi } from "./api";
import {
  adSuccess,
  adFailure,
  createAdSuccess,
  createAdFailure,
  getSingleAdSuccess,
  getSingleAdFailure,
  editAdSuccess,
  editAdFailure,
  deleteAdSuccess,

  deleteAdFailure,
  getCurrentAdSuccess,
  adAllSuccess,
  adAllFailure
} from "./actions";

function* createAd(action) {
  const response = yield call(create, action.payload);
  if (response.hasOwnProperty("id")) yield put(createAdSuccess(response));
  else yield put(createAdFailure(response.errors[0].message));
}

function* getAds(data) {
  const response = yield call(get, data.payload);
  if (response !== undefined) yield put(adSuccess(response));
  else yield put(adFailure(response));
}

function* getAllAds() {
  const response = yield call(getAllAdsApi);
  if (response !== undefined) yield put(adAllSuccess(response));
  else yield put(adAllFailure(response));
}

function* getSingleAd(action) {
  const response = yield call(getOne, action.payload);
  if (response.hasOwnProperty('id')) yield put(getSingleAdSuccess(response));
  else yield put(getSingleAdFailure(response));
}

function* updateAd(action) {
  const response = yield call(update, action.payload.id, action.payload)
  if (response.hasOwnProperty('id')) yield put(editAdSuccess(response));
  else yield put(editAdFailure(response));
}

function* deleteAd(action) {
  const response = yield call(remove, action.payload)
  if (response.hasOwnProperty('id')) yield put(deleteAdSuccess(response));
  else yield put(deleteAdFailure(response));
}

function* getCurrentAd() {
  const response = yield call(getAd);
  if (response.hasOwnProperty('data')) yield put(getCurrentAdSuccess(response));
  // else yield put(getSingleAdFailure(response));
}

export default function* rootSaga() {
  yield takeLatest("CREATE_AD", createAd);
  yield takeLatest("GET_ADS", getAds);
  yield takeLatest("GET_SINGLE_AD", getSingleAd);
  yield takeLatest("EDIT_AD", updateAd);
  yield takeLatest("DELETE_AD", deleteAd);
  yield takeLatest("GET_CURRENT_AD", getCurrentAd);
  yield takeLatest("GET_ALL_ADS", getAllAds);
}
