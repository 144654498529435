import {
  CREATE_TAG,
  CREATE_TAG_SUCCESS,
  CREATE_TAG_FAILURE,
  GET_TAGS_SUCCESS,
  GET_TAGS_FAILURE,
  GET_TAGS,
  EDIT_TAG,
  EDIT_TAG_SUCCESS,
  EDIT_TAG_FAILURE,
  GET_SINGLE_TAG,
  GET_SINGLE_TAG_SUCCESS,
  GET_SINGLE_TAG_FAILURE,
  DELETE_TAG,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAILURE,
  APPROVE_TAG,
  APPROVE_TAG_SUCCESS,
  APPROVE_TAG_FAILURE
} from "../actions";

export const createTag = name => {
  return {
    type: CREATE_TAG,
    payload: name
  };
};

export const createTagSuccess = msg => {
  return {
    type: CREATE_TAG_SUCCESS,
    payload: msg
  };
};

export const createTagFailure = error => {
  return {
    type: CREATE_TAG_FAILURE,
    payload: error
  };
};

export const getTags = (data) => {
  return {
    type: GET_TAGS,
    payload: data
  };
};

export const tagSuccess = data => {
  return {
    type: GET_TAGS_SUCCESS,
    payload: data
  };
};

export const tagFailure = error => {
  return {
    type: GET_TAGS_FAILURE,
    payload: error
  };
};

export const editTag = data => {
  return {
    type: EDIT_TAG,
    payload: data
  };
};

export const editTagSuccess = (data) => {
  return {
	type: EDIT_TAG_SUCCESS,
	payload:data
  };
};

export const editTagFailure = error => {
  return {
    type: EDIT_TAG_FAILURE,
    payload: error
  };
};

export const getSingleTag = id => {
  return {
    type: GET_SINGLE_TAG,
    payload: id
  };
};

export const getSingleTagSuccess = data => {
  return {
    type: GET_SINGLE_TAG_SUCCESS,
    payload: data
  };
};

export const getSingleTagFailure = error => {
  return {
    type: GET_SINGLE_TAG_FAILURE,
    payload: error
  };
};

export const deleteTag = id => {
  return {
    type: DELETE_TAG,
    payload: id
  }
}
export const deleteTagSuccess = (data) => {
  return {
    type: DELETE_TAG_SUCCESS,
	payload:data
  }
}
export const deleteTagFailure = error => {
  return {
    type: DELETE_TAG_FAILURE,
    payload: error
  }
}
export const approveTag = id => {
  return {
    type: APPROVE_TAG,
    payload: id
  };
};
export const approveTagSuccess = () => {
  return {
    type: APPROVE_TAG_SUCCESS
  };
};
export const approveTagFailure = error => {
  return {
    type: APPROVE_TAG_FAILURE,
    payload: error
  };
};