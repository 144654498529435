import client from '../../feathers';

const usersService = client.service('users');
const resetPasswordService = client.service('reset-password');
export async function signup(payload) {
  try {
    return await usersService.create(payload);
  } catch (err) {
    return err;
  }
}

export async function login(payload) {
  try {
    return await client.authenticate({
      strategy: 'local',
      allowedRoles: ['admin', 'provider'],
      ...payload,
    });
  } catch (err) {
    if (process.env.NODE_ENV == 'development') {
      console.log('err', err);
    }
    return err;
  }
}

export const resetpasswordApi = async (data) => {
  try {
    return await resetPasswordService.create(data);
  } catch (err) {
    return err;
  }
};
