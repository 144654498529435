import client from "../../feathers";

const userClientService = client.service("users");
const userRolesService = client.service("user-roles");

export const getUserClientsApi = async data => {
    try {
        return await userClientService.find(data);
    } catch (error) {
        return error;
    }
};

export const getClientRoleId = async data => {
  try {
    const roleId = await userRolesService.find({
      query: {
        type: "client"
      }
    })
    return roleId
  } catch (error) {
      return error;
  }
};

export const removeApi = async id => {
    try {
      return await userClientService.remove(id)
    } catch (error) {
      return error
    }
  }
  
  export const updateApi = async (id, data) => {
    try {
      return await userClientService.patch(id, data)
    } catch (error) {
      return error;
    }
  }

  export const getOneApi = async id => {
    try {
      return await userClientService.get(id);
    } catch (error) {
      return error;
    }
  };