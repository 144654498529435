import {
  CREATE_AD,
  CREATE_AD_SUCCESS,
  CREATE_AD_FAILURE,
  GET_ADS_SUCCESS,
  GET_ADS_FAILURE,
  EDIT_AD_SUCCESS,
  EDIT_AD_FAILURE,
  GET_SINGLE_AD_SUCCESS,
  GET_SINGLE_AD_FAILURE,
  DELETE_AD,
  DELETE_AD_SUCCESS,
  DELETE_AD_FAILURE,
  GET_CURRENT_AD_SUCCESS,
  GET_ALL_ADS_SUCCESS,
  GET_ALL_ADS_FAILURE,
  GET_SINGLE_AD,
  EDIT_AD,
} from '../actions';
import { updateItemInArray, removeItemFromArray } from '../utils/mutation';

const INITIAL_STATE = {
  success: false,
  error: {},
  data: [],
  dataWithCategory: [],
  create: {
    success: false,
    error: null,
  },
  edit: {
    success: false,
    error: null,
  },
  delete: {
    success: false,
    error: null,
  },
  currentAd: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATE_AD:
      return { ...state, create: { ...INITIAL_STATE.create } };
    case CREATE_AD_SUCCESS:
      return {
        ...state,
        create: { ...state.create, success: true },
        data: Array.isArray(state.data)
          ? { ...state.data }
          : {
              ...state.data,
              data: [...state.data.data, action.payload],
            },
      };
    case CREATE_AD_FAILURE:
      return { ...state, create: { ...state.create, error: action.payload } };
    case GET_ADS_SUCCESS:
      return {
        ...INITIAL_STATE,
        data: action.payload,
        dataWithCategory: state.dataWithCategory,
      };
    case GET_ADS_FAILURE:
      return {
        ...INITIAL_STATE,
        error: action,
        dataWithCategory: state.dataWithCategory,
      };
    case GET_ALL_ADS_SUCCESS:
      return { ...state, dataWithCategory: action.payload };
    case GET_ALL_ADS_FAILURE:
      return { ...INITIAL_STATE, error: action };

    case EDIT_AD:
      return { ...state, edit: { ...state.edit, success: false, data: null } };
    case EDIT_AD_SUCCESS:
      return {
        ...state,
        edit: { ...state.edit, success: true },
        data: Array.isArray(state.data)
          ? { ...state.data }
          : {
              ...state.data,
              data: updateItemInArray(
                state.data.data,
                state.data.data.findIndex((b) => b.id === action.payload.id),
                action.payload,
                true
              ),
            },
      };
    case EDIT_AD_FAILURE:
      return { ...state, edit: { ...state.edit, error: action.payload } };
    case GET_SINGLE_AD:
      return {
        ...state,
        edit: { ...state.edit, success: false, data: null, error: null },
      };
    case GET_SINGLE_AD_SUCCESS:
      return {
        ...state,
        edit: { ...state.edit, success: false, data: action.payload },
      };
    case GET_SINGLE_AD_FAILURE:
      return { ...state, edit: { ...state.edit, error: action.payload } };
    case DELETE_AD:
      return { ...state, delete: { ...INITIAL_STATE.delete } };
    case DELETE_AD_SUCCESS:
      return {
        ...state,
        delete: { ...state.edit, success: true },
        data: Array.isArray(state.data)
          ? { ...state.data }
          : {
              ...state.data,
              data: removeItemFromArray(
                state.data.data,
                state.data.data.findIndex((x) => x.id == action.payload.id)
              ),
            },
      };
    case DELETE_AD_FAILURE:
      return { ...state, delete: { ...state.edit, error: action.payload } };
    case GET_CURRENT_AD_SUCCESS:
      return { ...state, currentAd: action.payload.data };
    default:
      return { ...state };
  }
};
