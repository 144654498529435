import {
  CREATE_BOOKING,
  CREATE_BOOKING_SUCCESS,
  CREATE_BOOKING_FAILURE,
  GET_BOOKINGS_SUCCESS,
  GET_BOOKINGS_FAILURE,
  GET_BOOKINGS,
  EDIT_BOOKING,
  EDIT_BOOKING_SUCCESS,
  EDIT_BOOKING_FAILURE,
  GET_SINGLE_BOOKING,
  GET_SINGLE_BOOKING_SUCCESS,
  GET_SINGLE_BOOKING_FAILURE,
  DELETE_BOOKING,
  DELETE_BOOKING_SUCCESS,
  DELETE_BOOKING_FAILURE,
  GET_BOOKINGS_WITH_STATUS,
  GET_BOOKINGS_WITH_STATUS_SUCCESS,
  GET_BOOKINGS_WITH_STATUS_FAILURE,
  GET_BOOKINGS_WITH_SERVICES,
} from '../actions';

export const createBooking = (data) => {
  return {
    type: CREATE_BOOKING,
    payload: data,
  };
};

export const createBookingSuccess = (msg) => {
  return {
    type: CREATE_BOOKING_SUCCESS,
    payload: msg,
  };
};

export const createBookingFailure = (error) => {
  return {
    type: CREATE_BOOKING_FAILURE,
    payload: error,
  };
};

export const getBookings = (data) => {
  return {
    type: GET_BOOKINGS,
    payload: data,
  };
};

export const getBookingsWithServices = () => {
  return {
    type: GET_BOOKINGS_WITH_SERVICES,
  };
};

export const bookingSuccess = (data) => {
  return {
    type: GET_BOOKINGS_SUCCESS,
    payload: data,
  };
};

export const bookingFailure = (error) => {
  return {
    type: GET_BOOKINGS_FAILURE,
    payload: error,
  };
};

export const editBooking = (data) => {
  return {
    type: EDIT_BOOKING,
    payload: data,
  };
};

export const editBookingSuccess = (data) => {
  return {
    type: EDIT_BOOKING_SUCCESS,
    payload: data,
  };
};

export const editBookingFailure = (error) => {
  return {
    type: EDIT_BOOKING_FAILURE,
    payload: error,
  };
};

export const getSingleBooking = (id) => {
  return {
    type: GET_SINGLE_BOOKING,
    payload: id,
  };
};

export const getSingleBookingSuccess = (data) => {
  return {
    type: GET_SINGLE_BOOKING_SUCCESS,
    payload: data,
  };
};

export const getSingleBookingFailure = (error) => {
  return {
    type: GET_SINGLE_BOOKING_FAILURE,
    payload: error,
  };
};

export const deleteBooking = (id) => {
  return {
    type: DELETE_BOOKING,
    payload: id,
  };
};
export const deleteBookingSuccess = (data) => {
  return {
    type: DELETE_BOOKING_SUCCESS,
    payload: data,
  };
};
export const deleteBookingFailure = (error) => {
  return {
    type: DELETE_BOOKING_FAILURE,
    payload: error,
  };
};
export const getBookingsWithStatus = (data) => {
  return {
    type: GET_BOOKINGS_WITH_STATUS,
    payload: data,
  };
};
export const bookingWithStatusSuccess = (data) => {
  return {
    type: GET_BOOKINGS_WITH_STATUS_SUCCESS,
    payload: data,
  };
};
export const bookingWithStatusFailure = () => {
  return {
    type: GET_BOOKINGS_WITH_STATUS_FAILURE,
  };
};
