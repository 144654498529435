import {
  CREATE_MEDIA_META, CREATE_MEDIA_META_SUCCESS, CREATE_MEDIA_META_FAILURE
} from "../actions";

export const createMediaMeta = data => {
  return {
    type: CREATE_MEDIA_META,
    payload: data
  };
};


export const createMediaMetaSuccess = msg => {
  return {
    type: CREATE_MEDIA_META_SUCCESS,
    payload: msg
  };
};

export const createMediaMetaFailure = error => {
  return {
    type: CREATE_MEDIA_META_FAILURE,
    payload: error
  };
};