import { put, call, takeLatest } from "redux-saga/effects";
import { get } from "./api";

import {
    getSurveyAnswersSuccess,
    surveyFailure
  } from "./actions";
  
function* getSurveys(action) {
    const response = yield call(get, action.payload);
    if (response !== undefined) yield put(getSurveyAnswersSuccess(response));
    else yield put(surveyFailure(response));
}

export default function* rootSaga() {
    yield takeLatest("GET_SURVEY_ANSWERS", getSurveys);
}
