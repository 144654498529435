// src/feathers.js
import feathers from '@feathersjs/client';
import rest from '@feathersjs/rest-client';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { NotificationManager } from './components/common/react-notifications';
import history from './history';

var restClient = rest(process.env.REACT_APP_API_URL);

const axiosInstance = axios.create({
  timeout: 180000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
  },
});

axiosRetry(axiosInstance, { retries: 3 });

const showErrorToast = (message) => {
  NotificationManager.error(message, 'Error', 5000, null, null, '');
};

const onErrorResponseInterceptor = async (error) => {
  const { response } = error;
  const statusMessage =
    response && response.status !== 408
      ? `Error ${response.status}`
      : 'Cannot connect to the server';

  if (!response || response.status === 408) {
    console.warn(statusMessage);
    // showErrorToast(statusMessage); do not show this message
  } else if (response.status === 401) {
    console.warn(statusMessage, response.data.message);
    if (!window.location.pathname.endsWith('/user/login')) {
      history.push('/user/login');
      setTimeout(() => window.location.reload(), 300);
    }
  } else if (response.status === 429 || response.status === 404) {
    console.warn(statusMessage, response.data.message);
  } else if (response.status === 500) {
    console.warn(statusMessage, JSON.stringify(response, null, 2));
    showErrorToast('Internal server error');
  } else if (response.status !== 400) {
    console.warn(statusMessage, JSON.stringify(response, null, 2));
    // showErrorToast(response.data.message || statusMessage);
  }

  return await Promise.reject(error);
};

axiosInstance.interceptors.response.use(
  (response) => response,
  onErrorResponseInterceptor
);

const client = feathers()
  .configure(restClient.axios(axiosInstance))
  .configure(
    feathers.authentication({
      storage: window.localStorage,
    })
  );
export default client;
