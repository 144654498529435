import client from "../../feathers";

const surveyService = client.service("survey-answers");

export const get = async (data) => {
  try {
    return await surveyService.find();
  } catch (error) {
    return error;
  }
};