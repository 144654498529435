import { put, call, takeLatest } from "redux-saga/effects";
import { create, get, getOne, update, remove, getCategorySpecificServices, getServiceTasksApi, createServiceTask,
   getProviderServiceTasksApi, removeProviderTasksApi, getProviderTasks,updateServiceTask, getAdminstratorPersonnelWithServiceId,getUserListWithServiceId} from "./api";
import {
  serviceSuccess,
  serviceFailure,
  createServiceSuccess,
  createServiceFailure,
  getSingleServiceSuccess,
  getSingleServiceFailure,
  editServiceSuccess,
  editServiceFailure,
  deleteServiceSuccess,
  deleteServiceFailure,
  categorySpecificServicesSuccess,
  categorySpecificServicesFailure,
  getServiceTasksSuccess,
  getServiceTasksFailure,
  addServiceToProviderSuccess,
  addServiceToProviderFailure,
  getProviderTasksSuccess,
  getProviderTasksFailure,
  removeProviderTasksSuccess,
  removeProviderTasksFailure,
  getAdminstratorPersonnelWithServiceIdSuccess,
  updateServiceToProviderSuccess,
  getUserListWithServiceIdSuccess
} from "./actions";

function* createService(action) {
  const response = yield call(create, action.payload);
  if (response.hasOwnProperty("id")) yield put(createServiceSuccess(response));
  else yield put(createServiceFailure(response.errors[0].message));
}

function* getServices(action) {
  let response = undefined
  if(action.payload && action.payload.providerTasks != undefined){
    response = yield call(getProviderTasks, action.payload);
  }else{
    response = yield call(get, action.payload);
  }
  if (response !== undefined) yield put(serviceSuccess(response));
  else yield put(serviceFailure(response));
}

function* getSingleService(action) {
  const response = yield call(getOne, action.payload);
  if (response.hasOwnProperty('id')) yield put(getSingleServiceSuccess(response));
  else yield put(getSingleServiceFailure(response));
}


function* getSpecificServices(action) {
  const response = yield call(getCategorySpecificServices, action.payload);
  if (response !== undefined) yield put(categorySpecificServicesSuccess(response));
  else yield put(categorySpecificServicesFailure(response));
}

function* updateService(action) {
  const response = yield call(update, action.payload.id, action.payload.data)
  if (response.hasOwnProperty('id')) yield put(editServiceSuccess(response));
  else yield put(editServiceFailure(response));
}

function* deleteService(action) {
  const response = yield call(remove, action.payload)
  if (response.hasOwnProperty('id')) yield put(deleteServiceSuccess(response));
  else yield put(deleteServiceFailure(response));
}

function* getServiceTasks(action) {
  const response = yield call(getServiceTasksApi, action.payload);
  if (response !== undefined) yield put(getServiceTasksSuccess(response));
  else yield put(getServiceTasksFailure(response));
}

function* addServiceToProvider(action) {
  const response = yield call(createServiceTask, action.payload);
  if (response !== undefined) yield put(addServiceToProviderSuccess(response));
  else yield put(addServiceToProviderFailure(response.errors[0].message));
}

function* updateServiceToProvider(action) {
  const response = yield call(updateServiceTask, action.payload);
  if (response !== undefined) yield put(updateServiceToProviderSuccess(response));
  else yield put(addServiceToProviderFailure(response.errors[0].message));
}

function* getProviderServiceTasks(action) {
  const response = yield call(getProviderServiceTasksApi, action.payload);
  yield put(addServiceToProviderFailure(""))
  if (response !== undefined) yield put(getProviderTasksSuccess(response));
  else yield put(getProviderTasksFailure(response));
}

function* removeProviderTasks(action) {
  const response = yield call(removeProviderTasksApi, action.payload);
  if (response !== undefined) yield put(removeProviderTasksSuccess(response));
  else yield put(removeProviderTasksFailure(response));
}


function* getAdminstratorPersonnelListWithServiceId(action) {
  const response = yield call(getAdminstratorPersonnelWithServiceId, action.payload);
  if (response !== undefined) yield put(getAdminstratorPersonnelWithServiceIdSuccess(response));
  // else yield put(removeProviderTasksFailure(response));
}

function* getServiceProviderAndEmployee(action) {
  const response = yield call(getUserListWithServiceId, action.payload);
  if (response !== undefined) yield put(getUserListWithServiceIdSuccess(response));
}

export default function* rootSaga() {
  yield takeLatest("CREATE_SERVICE", createService);
  yield takeLatest("GET_SERVICES", getServices);
  yield takeLatest("GET_SINGLE_SERVICE", getSingleService);
  yield takeLatest("GET_CATEGORY_SPECIFIC_SERVICES", getSpecificServices);
  yield takeLatest("EDIT_SERVICE", updateService);
  yield takeLatest("DELETE_SERVICE", deleteService);
  yield takeLatest("GET_SERVICE_TASKS", getServiceTasks);
  yield takeLatest("ADD_SERVICE_TO_PROVIDER", addServiceToProvider);
  yield takeLatest("UPDATE_SERVICE_TO_PROVIDER", updateServiceToProvider);
  yield takeLatest("GET_PROVIDER_TASKS", getProviderServiceTasks);
  yield takeLatest("REMOVE_PROVIDER_TASKS", removeProviderTasks);
  yield takeLatest("GET_ADMINISTRATOR_LIST_WITH_SERVICE_ID_AND_PROVIDER_ID", getAdminstratorPersonnelListWithServiceId)
  yield takeLatest("GET_USER_LIST_WITH_SERVICE_ID", getServiceProviderAndEmployee)

}
