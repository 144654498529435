import client from "../../feathers";

const providersService = client.service("users");
const usersMeta = client.service("users-meta");

export const create = async data => {
  try {
    return await providersService.create(
      data
    );
  } catch (error) {
    return error;
  }
};

export const getAll = async (data) => {
  try {
    return await providersService.find({
      query: {
        role: data.role, ...(data.params||{}), $sort: {
          createdAt: -1
        }
      }
    });
  } catch (error) {
    return error;
  }
};

export const getOne = async id => {
  try {
    return await providersService.get(id);
  } catch (error) {
    return error;
  }
};

export const remove = async (id) => {
  try {
    let result = await providersService.remove(id)
    return result[0][0]
  } catch (error) {
    return error
  }
}

export const update = async (id, data) => {
  try {
    return await providersService.patch(id, data)
  } catch (error) {
    return error;
  }
}

export const getAllUser = async data => {
  try {
    return await providersService.find(data);
  } catch (error) {
    return error;
  }
};