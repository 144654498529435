import client from "../../feathers";

const usersMeta = client.service("users-meta");
const users = client.service("users");
const locationService = client.service('locations');

export const filterProvidersCompany = async (payload) => {
    try {
        return await usersMeta.find({ query: {value: {$like: `%${payload.company}%`}, key: 'companyName'}});
    } catch (error) {
        return error;
    }
};


export const filterClientByEmail = async (payload) => {
    try {
        return await users.find({ query: {email: {$like: `%${payload.email}%`}}});
    } catch (error) {
        return error;
    }
};

export const filterClientBynameOrEmail = async (payload) => {
    try {
        let query = { query: {nameOrEmail:payload.value, role:payload.role}}
        return await users.find(query);
    } catch (error) {
        return error;
    }
};


export const filterClientByPhone = async (payload) => {
    try {
        return await users.find({ query: {phone: {$like: `%${payload.phone}%`}}});
    } catch (error) {
        return error;
    }
};


export const filterClientByName = async (payload) => {
    payload = payload.name.split(" ");

    let _query = { query: {value: {$like: `%${payload[0]}%`}, key: 'firstName'}};

    try {
        return await usersMeta.find(_query);
    } catch (error) {
        return error;
    }
};


export const getClientUserById = async (payload) => {
    try {
        let locations = await locationService.find({
            query: { paginate: false },
        });
        let user = await users.find({ query: { id: payload.userId } });
        const locationId = user.data[0].meta.location ? parseInt(user.data[0].meta.location) : null;
        if (locationId) {
            const location = locations.find(p => p.id == locationId);
            user.data[0].meta.address = location.address;
        }
        return user;
    } catch (error) {
        return error;
    }
};