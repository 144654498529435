import {
  GET_ALL_ADMINS,
  GET_ALL_ADMINS_SUCCESS,
  GET_ALL_ADMINS_FAILURE,
  GET_SINGLE_ADMIN,
  GET_SINGLE_ADMIN_SUCCESS,
  GET_SINGLE_ADMIN_FAILURE,
  EDIT_ADMIN,
  EDIT_ADMIN_SUCCESS,
  EDIT_ADMIN_FAILURE,
  CREATE_ADMIN,
  CREATE_ADMIN_SUCCESS,
  CREATE_ADMIN_FAILURE,
  DELETE_ADMIN,
  DELETE_ADMIN_SUCCESS,
  DELETE_ADMIN_FAILURE,
} from '../actions';
import { updateItemInArray, removeItemFromArray } from '../utils/mutation';

const INITIAL_STATE = {
  data: [],
  success: false,
  error: null,
  edit: {
    data: {},
    success: false,
    error: null,
  },
  create: {
    success: false,
    error: null,
  },
  delete: {
    success: false,
    error: null,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Create Admin
    case CREATE_ADMIN:
      return {
        ...state,
        create: { ...INITIAL_STATE.create },
      };
    case CREATE_ADMIN_SUCCESS:
      return {
        ...state,
        create: { ...state.create, success: true },
        data: Array.isArray(state.data)
          ? { ...state.data }
          : {
              ...state.data,
              data: [...state.data.data, action.payload],
              total: state.data.total + 1,
            },
      };
    case CREATE_ADMIN_FAILURE:
      return {
        ...state,
        create: { ...INITIAL_STATE.create, error: action.payload },
      };

    // Get All Admins
    case GET_ALL_ADMINS:
      return { ...state, error: null };
    case GET_ALL_ADMINS_SUCCESS:
      return { ...INITIAL_STATE, data: action.payload };
    case GET_ALL_ADMINS_FAILURE:
      return { ...state, error: action.payload };

    // Get Single Admin
    case GET_SINGLE_ADMIN:
      return { ...state, edit: { ...INITIAL_STATE.edit } };
    case GET_SINGLE_ADMIN_SUCCESS:
      return { ...state, edit: { ...state.edit, data: action.payload } };
    case GET_SINGLE_ADMIN_FAILURE:
      return { ...state, edit: { ...state.edit, error: action.payload } };

    // Update Admin
    case EDIT_ADMIN:
      return { ...state, edit: { ...INITIAL_STATE.edit } };
    case EDIT_ADMIN_SUCCESS:
      const response =
        state.data && state.data.data && state.data.data.length > 0
          ? {
              ...state.data,
              data: updateItemInArray(
                state.data.data,
                state.data.data.findIndex((b) => b.id === action.payload.id),
                action.payload,
                true
              ),
            }
          : action.payload;
      return {
        ...state,
        edit: { ...INITIAL_STATE.edit, success: true },
        data: response,
      };
    case EDIT_ADMIN_FAILURE:
      return { ...state, edit: { ...state.edit, error: action.payload } };

    // Delete Admin
    case DELETE_ADMIN:
      return { ...state, delete: { ...INITIAL_STATE.delete } };
    case DELETE_ADMIN_SUCCESS:
      return {
        ...state,
        delete: { ...INITIAL_STATE.delete, success: true },
        data: Array.isArray(state.data)
          ? { ...state.data }
          : {
              ...state.data,
              data: removeItemFromArray(
                state.data.data,
                state.data.data.findIndex((x) => x.id == action.payload.id)
              ),
              total: state.data.total - 1,
            },
      };
    case DELETE_ADMIN_FAILURE:
      return {
        ...state,
        delete: { ...INITIAL_STATE.delete, error: action.payload },
      };

    default:
      return { ...state };
  }
};
