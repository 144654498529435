import client from '../../feathers'

const NotificationsService = client.service('notifications')

export const fetchNotifications = async data => {
    try {
        return await NotificationsService.find({ query: { ...data, $limit: 10, $sort: { createdAt: -1 } } })
    }
    catch(error){
        return error
    }
}

export const patchNotifications = async id => {
    try {
        return await NotificationsService.patch(id, { status: true })
    }
    catch(error){
        return error
    }
}

export const markNotificationReadByProviderAdmin = async ({id,type}) => {
    try {
        if(id){
            let query;
            if(type == 'admin'){
                query= {readStatusAdmin:1}
            } else {
                query = {readStatus:1}
            }
            if(!query){ return false}
            return await NotificationsService.patch(id, query)
        }
    }
    catch(error){
        return error
    }
}

