import {
  CREATE_USER_ROLES,
  CREATE_USER_ROLES_SUCCESS,
  CREATE_USER_ROLES_FAILURE,
  GET_USER_ROLES,
  GET_USER_ROLES_SUCCESS,
  GET_USER_ROLES_FAILURE,
  DELETE_USER_ROLE,
  DELETE_USER_ROLE_SUCCESS,
  DELETE_USER_ROLE_FAILURE,
  EDIT_USER_ROLE,
  EDIT_USER_ROLE_SUCCESS,
  EDIT_USER_ROLE_FAILURE,
} from "../actions";

export const createUserRole = data => {
  return {
    type: CREATE_USER_ROLES,
    payload: data
  };
};

export const createUserRoleSuccess = (data) => {
  return {
    type: CREATE_USER_ROLES_SUCCESS,
	payload:data
  };
};

export const createUserRoleFailure = error => {
  return {
    type: CREATE_USER_ROLES_FAILURE,
    payload: error
  };
};

export const getUserRole = () => {
  return {
    type: GET_USER_ROLES
  };
};

export const getUserRoleSuccess = data => {
  return {
    type: GET_USER_ROLES_SUCCESS,
    payload: data
  };
};

export const getUserRoleFailure = error => {
  return {
    type: GET_USER_ROLES_FAILURE,
    payload: error
  };
};

export const deleteUserRole = id => {
  return {
    type: DELETE_USER_ROLE,
    payload: id
  };
};

export const deleteUserRoleSuccess = (data) => {
  return {
    type: DELETE_USER_ROLE_SUCCESS,
	payload:data
  };
};

export const deleteUserRoleFailure = error => {
  return {
    type: DELETE_USER_ROLE_FAILURE,
    payload: error
  };
};

export const editUserRole = data => {
  return {
    type: EDIT_USER_ROLE,
    payload: data
  };
};

export const editUserRoleSuccess = (data) => {
  return {
    type: EDIT_USER_ROLE_SUCCESS,
	payload:data
  };
};

export const editUserRoleFailure = error => {
  return {
    type: EDIT_USER_ROLE_FAILURE,
    payload: error
  };
};