import {
  GET_SERVICES_SUCCESS,
  GET_SERVICES_FAILURE,
  PROVIDER_SPECIFIC_SERVICES_FAILURE,
  PROVIDER_SPECIFIC_SERVICES_SUCCESS,
  GET_ALL_TRANSACTIONS_SUCCESS,
  GET_ALL_TRANSACTIONS_FAILURE
} from "../actions";

const INITIAL_STATE = {
  success: false,
  error: {},
  data: [],
  create: {
    success: false,
    error: null
  },
  edit: {
    success: false,
    error: null
  },
  delete: {
    success: false,
    error: null
  },
  providerSpecificServices: {
    success: false,
    error: null,
    data: []
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_TRANSACTIONS_SUCCESS:
      return { ...INITIAL_STATE, data: action.payload, error: null };
    case GET_ALL_TRANSACTIONS_FAILURE:
      return { ...INITIAL_STATE, error: action.payload };

    case PROVIDER_SPECIFIC_SERVICES_SUCCESS:
      return { ...state, providerSpecificServices: { data: action.payload } };
    case PROVIDER_SPECIFIC_SERVICES_FAILURE:
      return { ...INITIAL_STATE, error: action.payload };
    default:
      return { ...state };
  }
};
