import { UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_FAILURE, UPLOAD_IMAGE_RESET, UPLOAD_PROGRESS, UPLOAD_IMAGE, GET_MEDIA_SUCCESS, GET_MEDIA_FAILURE } from "../actions";
// import { statement } from "@babel/template";

const INITIAL_STATE = {
  upload: {
    url: null,
    success: false,
    error: null,
    name: null,
    progress: 0
  },
  data: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // upload image
    case UPLOAD_IMAGE:
      return { ...state, upload: { ...state.upload, name: action.payload.name } }
    case UPLOAD_IMAGE_RESET:
      return { ...INITIAL_STATE, data: state.data };
    case UPLOAD_PROGRESS:
      return { ...state, upload: { ...state.upload, progress: action.payload } }
    case UPLOAD_IMAGE_SUCCESS:
      return { ...state, upload: { ...state.upload, url: action.payload.url, name: action.payload.name, id: action.payload.id, success: true } };
    case UPLOAD_IMAGE_FAILURE:
      return {
        ...state,
        upload: { ...state.upload, error: action.payload }
      };
    case GET_MEDIA_SUCCESS:
      return { ...INITIAL_STATE, data: action.payload };
    case GET_MEDIA_FAILURE:
      return { ...INITIAL_STATE, error: action };
    default:
      return { ...state };
  }
};
