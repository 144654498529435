import client from '../../feathers';

const certificateService = client.service('certificates');

export const create = async (data) => {
  try {
    return await certificateService.create(data);
  } catch (error) {
    return error;
  }
};

export const get = async (data) => {
  try {
    if (!data.$limit && !data.$skip) {
      return await certificateService.find({ query: data });
    } else {
      if (!data.title) {
        delete data.title;
      }
      return await certificateService.find({ query: { ...data } });
    }
  } catch (error) {
    return error;
  }
};

export const getAll = async (data) => {
  try {
    return await certificateService.find({
      query: {
        ...data,
        paginate: false,
      },
    });
  } catch (error) {
    return error;
  }
};

export const getOne = async (id) => {
  try {
    return await certificateService.get(id);
  } catch (error) {
    return error;
  }
};

export const update = async (id, data) => {
  try {
    return await certificateService.patch(id, data);
  } catch (error) {
    return error;
  }
};

export const remove = async (id) => {
  try {
    return await certificateService.remove(id);
  } catch (error) {
    return error;
  }
};

export const getCertificate = async (id) => {
  const axios = require('axios');
  try {
    return await axios.get('https://ipapi.co/json/');
  } catch (error) {
    return error;
  }
};

// export const update = async () => {
//   try {
//     return await certificateService.update
//   }
// }
