import {
  GET_ALL_ADMINS,
  GET_ALL_ADMINS_SUCCESS,
  GET_ALL_ADMINS_FAILURE,
  CREATE_ADMIN,
  CREATE_ADMIN_SUCCESS,
  CREATE_ADMIN_FAILURE,
  EDIT_ADMIN,
  EDIT_ADMIN_SUCCESS,
  EDIT_ADMIN_FAILURE,
  GET_SINGLE_ADMIN,
  GET_SINGLE_ADMIN_SUCCESS,
  GET_SINGLE_ADMIN_FAILURE,
  DELETE_ADMIN,
  DELETE_ADMIN_SUCCESS,
  DELETE_ADMIN_FAILURE
} from "../actions";

export const getAdmins = data => {
  return {
    type: GET_ALL_ADMINS,
    payload: data
  };
};

export const getAdminsSuccess = data => {
  return {
    type: GET_ALL_ADMINS_SUCCESS,
    payload: data
  };
};

export const getAdminsFailure = error => {
  return {
    type: GET_ALL_ADMINS_FAILURE,
    payload: error
  };
};
export const createAdmin = data =>{
  return {
    type:CREATE_ADMIN,
    payload:data
  }
}

export const createAdminSuccess = (data) => {
  return {
	type: CREATE_ADMIN_SUCCESS,
	payload:data
  };
};

export const createAdminFailure = error => {
  return {
    type: CREATE_ADMIN_FAILURE,
    payload: error
  };
};

export const editAdmin = data => {
  return {
    type: EDIT_ADMIN,
    payload: data
  };
};

export const editAdminSuccess = data => {
  return {
    type: EDIT_ADMIN_SUCCESS,
    payload: data
  };
};

export const editAdminFailure = error => {
  return {
    type: EDIT_ADMIN_FAILURE,
    payload: error
  };
};

export const getSingleAdmin = id => {
  return {
    type: GET_SINGLE_ADMIN,
    payload: parseInt(id)
  };
};

export const getSingleAdminSuccess = data => {
  return {
    type: GET_SINGLE_ADMIN_SUCCESS,
    payload: data
  };
};

export const getSingleAdminFailure = error => {
  return {
    type: GET_SINGLE_ADMIN_FAILURE,
    payload: error
  };
};


export const deleteAdmin = id => {
  return {
    type: DELETE_ADMIN,
    payload: id
  };
};

export const deleteAdminSuccess = (data) => {
  return {
	type: DELETE_ADMIN_SUCCESS,
	payload:data
  };
};

export const deleteAdminFailure = error => {
  return {
    type: DELETE_ADMIN_FAILURE,
    payload: error
  };
};