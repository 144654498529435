import { put, call, takeLatest } from "redux-saga/effects";
import { get } from "./api";
import {
    getPersonalRegtdYearSuccess,
    getPersonalRegtdYearError,
} from "./actions";

function* getPersonalRegtdYear(action) {
    const response = yield call(get, action.payload);
    if (response !== undefined) yield put(getPersonalRegtdYearSuccess(response));
    else yield put(getPersonalRegtdYearError(response));
}

export default function* rootSaga() {
    yield takeLatest("GET_PERSONAL_REGTD_YEAR", getPersonalRegtdYear);
}
