import {
    SUBMIT_SURVEY,
    SUBMIT_SURVEY_SUCCESS,
    SUBMIT_SURVEY_FAILURE,
    GET_SURVEY_ANSWERS,
    GET_SURVEY_ANSWERS_SUCCESS,
    GET_SURVEY_ANSWERS_FAILURE
} from '../actions';

const INIT_STATE = {
    surveyCompleted: localStorage.getItem('surveyCompleted') || false,
    loading: false,
    error: '',
    success: false,
    data: []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SUBMIT_SURVEY:
            return { ...state, loading: true, error: '' };
        case SUBMIT_SURVEY_SUCCESS:
            localStorage.setItem('surveyCompleted', true)
            return { ...state, loading: false, surveyCompleted: true, error: '' };
        case SUBMIT_SURVEY_FAILURE:
            return { ...state, loading: false, surveyCompleted: false, error: action.payload.message };
        case GET_SURVEY_ANSWERS:
            return {...state, data: []}
        case GET_SURVEY_ANSWERS_SUCCESS:
            return {...state, data: action.payload}
        case GET_SURVEY_ANSWERS_FAILURE:
            return {...state, data: []}
        default: return { ...state };
    }
}
