import client from '../../feathers';

const ticketService = client.service('support-tickets');
const ticketHistory = client.service('ticket-history');

export const create = async (data) => {
  try {
    return await ticketService.create({
      title: data.title,
      description: data.description,
      attachment: data.attachment,
      status: data.status,
      priority: data.priorty,
      bookingId: data.bookingId,
      providerId: data.providerId,
      userId: data.userId,
    });
  } catch (error) {
    return error;
  }
};

export const createHistory = async (payload) => {
  const { comment, modifiedBy, status, priority, attachment } = payload.data;
  const ticketId = payload.id;
  try {
    return await ticketHistory.create({
      comment: comment || '',
      attachment,
      modifiedBy,
      status,
      priority,
      ticketId,
    });
  } catch (error) {
    return error;
  }
};

export const get = async (data) => {
  try {
    return await ticketService.find({ query: data });
  } catch (error) {
    return error;
  }
};

export const getOne = async (id) => {
  try {
    return await ticketService.get(id);
  } catch (error) {
    return error;
  }
};

export const getHistory = async (id) => {
  try {
    return await ticketHistory.find({
      query: {
        ticketId: id,
      },
    });
  } catch (error) {
    return error;
  }
};

export const update = async (id, data) => {
  try {
    return await ticketService.patch(id, data);
  } catch (error) {
    return error;
  }
};

export const remove = async (id) => {
  try {
    return await ticketService.remove(id);
  } catch (error) {
    return error;
  }
};

// export const update = async () => {
//   try {
//     return await ticketService.update
//   }
// }
