import { put, call, takeLatest } from "redux-saga/effects";
import { create, get, getOne, update, remove, approve } from "./api";
import {
  tagSuccess,
  tagFailure,
  createTagSuccess,
  createTagFailure,
  getSingleTagSuccess,
  getSingleTagFailure,
  editTagSuccess,
  editTagFailure,
  deleteTagSuccess,

  deleteTagFailure,
  approveTagSuccess,
  approveTagFailure,
} from "./actions";

function* createTag(action) {
  const response = yield call(create, action.payload);
  if (response.hasOwnProperty("id")) yield put(createTagSuccess(response));
  else yield put(createTagFailure(response.errors[0].message));
}

function* getTags(action) {
  const response = yield call(get,action.payload);
  if (response !== undefined) yield put(tagSuccess(response));
  else yield put(tagFailure(response));
}

function* getSingleTag(action) {
  const response = yield call(getOne, action.payload);
  if (response.hasOwnProperty('id')) yield put(getSingleTagSuccess(response));
  else yield put(getSingleTagFailure(response));
}

function* updateTag(action) {
    const response = yield call(update, action.payload.id, action.payload.data)
    if (response.hasOwnProperty('id')) yield put(editTagSuccess(response));
    else yield put(editTagFailure(response));
}

function* deleteTag(action) {
    const response = yield call(remove, action.payload)
    if (response.hasOwnProperty('id')) yield put(deleteTagSuccess(response));
    else yield put(deleteTagFailure(response));
}

function* approveTag(action) {
  const response = yield call(approve, action.payload)
  if (response.hasOwnProperty('id')) yield put(approveTagSuccess(response));
  else yield put(approveTagFailure(response));
}

export default function* rootSaga() {
  yield takeLatest("CREATE_TAG", createTag);
  yield takeLatest("GET_TAGS", getTags);
  yield takeLatest("GET_SINGLE_TAG", getSingleTag);
  yield takeLatest("EDIT_TAG", updateTag);
  yield takeLatest("DELETE_TAG", deleteTag);
  yield takeLatest("APPROVE_TAG", approveTag); 

}
