import client from "../../feathers";

const userRolesService = client.service("user-roles");

export const create = async data => {
  try {
    return await userRolesService.create({
      name: data.roleName,
      type: data.roleType,
      permissions: data.permissions
    });
  } catch (error) {
    return error;
  }
};

export const getAll = async () => {
  try {
    return await userRolesService.find();
  } catch (error) {
    return error;
  }
};

export const remove = async id => {
  try {
    return await userRolesService.remove(id);
  } catch (error) {
    return error;
  }
};
export const update = async (id, data) => {
  try {
    return await userRolesService.patch(id, {
      name: data.roleName,
      type: data.roleType,
      permissions: data.permissions
    })
  } catch (error) {
    return error;
  }
}