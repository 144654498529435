import { put, call, takeLatest } from 'redux-saga/effects';
import { getUserClientsApi, removeApi, updateApi, getOneApi, getClientRoleId } from './api';

import {
    // getUserClients,
    getUserClientsSuccess,
    getUserClientsFailure,
    // deleteUserClient,
    deleteUserClientSuccess,
    deleteUserClientFailure,
    // editUserClient,
    editUserClientSuccess,
    editUserClientFailure,
    getSingleUserClientSuccess,
    getSingleUserClientFailure
} from './actions';

import {
    GET_USER_CLIENTS,
    DELETE_USER_CLIENTS,
    EDIT_USER_CLIENTS,
    GET_SINGLE_USER_CLIENTS
} from '../actions';

import { getAll as getRoles } from '../user-roles/api'

function* getUserClient(action) {
    let response = yield call(getUserClientsApi, action.payload)
    response = response && response.length > 0 && response.sort((a, b) => (b.id - a.id))
    if (response !== undefined) {
        // const userRoles = yield call(getRoles)
        const role = yield call(getClientRoleId)
        const roleId = role[0].id
        const clients = response && response.length > 0 && response.filter(user => {
            if (user.userRoleId == roleId) return user
        })

        yield put(getUserClientsSuccess(clients))
    } else yield put(getUserClientsFailure(response))
}

function* deleteUserClientByID(action) {
    const response = yield call(removeApi, action.payload)
    if (response.hasOwnProperty('id')) yield put(deleteUserClientSuccess(response))
    else yield put(deleteUserClientFailure(response))
}

function* updateUserClient(action) {
    const response = yield call(updateApi, action.payload.id, action.payload.data)
    if (response.hasOwnProperty('id')) yield put(editUserClientSuccess(response))
    else yield put(editUserClientFailure(response.errors))
}

function* getSingleUserClient(action) {
    const response = yield call(getOneApi, action.payload)
    if (response.hasOwnProperty('id'))
        yield put(getSingleUserClientSuccess(response))
    else yield put(getSingleUserClientFailure(response))
}

export default function* rootSaga() {
    yield takeLatest(GET_USER_CLIENTS, getUserClient)
    yield takeLatest(DELETE_USER_CLIENTS, deleteUserClientByID)
    yield takeLatest(EDIT_USER_CLIENTS, updateUserClient)
    yield takeLatest(GET_SINGLE_USER_CLIENTS, getSingleUserClient)
}