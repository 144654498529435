// import client from "../../feathers";

// const uploadMediaService = client.service("media");

// export const create = async data => {
//   try {
//     return await uploadMediaService.create(data);
//   } catch (error) {
//     console.log(error);
//     return error;
//   }
// };
import axios from 'axios'
import client from "../../feathers";

const mediaService = client.service("media");
const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000
});

export const get = async (data) => {
  try {
    return await mediaService.find({query: data});
  } catch (error) {
    return error;
  }
};

export const create = (data, onUploadProgress) => {
  API.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('feathers-jwt');
  return API.post('/media', data, { onUploadProgress })
}