import { put, call, takeLatest } from 'redux-saga/effects';
import { create, get, getOne, update, remove } from './api';
import {
  categorySuccess,
  categoryFailure,
  createCategorySuccess,
  createCategoryFailure,
  getSingleCategorySuccess,
  getSingleCategoryFailure,
  editCategorySuccess,
  editCategoryFailure,
  deleteCategorySuccess,
  deleteCategoryFailure,
} from './actions';

function* createCategory(action) {
  const response = yield call(create, action.payload);
  if (response.hasOwnProperty('id')) yield put(createCategorySuccess(response));
  else yield put(createCategoryFailure(response.errors[0].message));
}

function* getCategories(params) {
  const response = yield call(get, params.payload);
  // response = response.sort((a, b) => (b.id - a.id))
  if (response && (response.data || Array.isArray(response)))
    yield put(categorySuccess(response));
  else yield put(categoryFailure(response));
}

function* getSingleCategory(action) {
  const response = yield call(getOne, action.payload);
  if (response.hasOwnProperty('id'))
    yield put(getSingleCategorySuccess(response));
  else yield put(getSingleCategoryFailure(response));
}

function* updateCategory(action) {
  const response = yield call(update, action.payload.id, action.payload.data);
  if (response.hasOwnProperty('id')) yield put(editCategorySuccess(response));
  else yield put(editCategoryFailure(response));
}

function* deleteCategory(action) {
  const response = yield call(remove, action.payload);
  if (response.hasOwnProperty('id')) yield put(deleteCategorySuccess(response));
  else yield put(deleteCategoryFailure(response));
}

export default function* rootSaga() {
  yield takeLatest('CREATE_CATEGORY', createCategory);
  yield takeLatest('GET_CATEGORIES', getCategories);
  yield takeLatest('GET_SINGLE_CATEGORY', getSingleCategory);
  yield takeLatest('EDIT_CATEGORY', updateCategory);
  yield takeLatest('DELETE_CATEGORY', deleteCategory);
}
