import {
	CREATE_USER_ROLES_SUCCESS,
	CREATE_USER_ROLES_FAILURE,
	GET_USER_ROLES_SUCCESS,
	GET_USER_ROLES_FAILURE,
	DELETE_USER_ROLE_SUCCESS,
	DELETE_USER_ROLE_FAILURE,
	EDIT_USER_ROLE_SUCCESS,
	EDIT_USER_ROLE_FAILURE,
} from "../actions";
import { updateItemInArray, removeItemFromArray } from '../utils/mutation';

const INITIAL_STATE = {
	data: [],
	success: false,
	error: null,
	create: {
		success: false,
		error: null
	},
	edit: {
		data: {},
		success: false,
		error: null
	},
	delete: {
		success: false,
		error: null
	}
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CREATE_USER_ROLES_SUCCESS:
			return { ...state, create: { ...state.create, success: true }, data: [...state.data, action.payload] };
		case CREATE_USER_ROLES_FAILURE:
			return {
				...state,
				create: { ...INITIAL_STATE.create, error: action.payload }
			};

		case GET_USER_ROLES_SUCCESS:
			return { ...INITIAL_STATE, data: action.payload };
		case GET_USER_ROLES_FAILURE:
			return { ...INITIAL_STATE, error: action.payload };

		case DELETE_USER_ROLE_SUCCESS:
			return {
				...state,
				delete: { ...INITIAL_STATE.delete, success: true },
				data: removeItemFromArray(state.data, state.data.findIndex(x => x.id == action.payload.id))

			};
		case DELETE_USER_ROLE_FAILURE:
			return {
				...state,
				delete: { ...INITIAL_STATE.delete, error: action.payload }
			};

		// Update UserRole
		case EDIT_USER_ROLE_SUCCESS:
			return {
				...state,
				edit: { ...INITIAL_STATE.edit, success: true },
				data: updateItemInArray(
					state.data,
					state.data.findIndex(b => b.id === action.payload.id),
					action.payload,
					true,
				)
			};
		case EDIT_USER_ROLE_FAILURE:
			return { ...state, edit: { ...state.edit, error: action.payload } };

		default:
			return { ...state };
	}
};
