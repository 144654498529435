import { EDIT_APP_CONFIGURATION, GET_APP_CONFIGURATIONS } from '../actions';
import { put, call, takeLatest } from 'redux-saga/effects';
import { get, update } from './api';
import {
  appConfigurationsSuccess,
  appConfigurationsFailure,
  editAppConfigurationSuccess,
  editAppConfigurationFailure,
} from './actions';

function* getAppConfigurations() {
  const response = yield call(get);
  if (response !== undefined) yield put(appConfigurationsSuccess(response));
  else yield put(appConfigurationsFailure(response));
}

function* updateAppConfiguration(action) {
  const response = yield call(update, action.payload.id, action.payload);
  if (response.hasOwnProperty('id'))
    yield put(editAppConfigurationSuccess(response));
  else yield put(editAppConfigurationFailure(response));
}

export default function* rootSaga() {
  yield takeLatest(GET_APP_CONFIGURATIONS, getAppConfigurations);
  yield takeLatest(EDIT_APP_CONFIGURATION, updateAppConfiguration);
}
