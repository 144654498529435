import {
    GET_AUTOSUGGEST_COMPANY,
    AUTOSUGGEST_COMPANY_SUCCESS,
    AUTOSUGGEST_COMPANY_FAILURE,
} from "../actions";

export const providersCompanySuccess = (data) => {
    return {
        type: AUTOSUGGEST_COMPANY_SUCCESS,
        payload: data
    };
};

export const providersCompanyFailure = (error) => {
    return {
        type: AUTOSUGGEST_COMPANY_FAILURE,
        payload: error
    };
};

export const getProvidersCompany = (data) => {
    return {
        type: GET_AUTOSUGGEST_COMPANY,
        payload: data,
    };
};