import {
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_FAILURE,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAILURE,
  EDIT_CATEGORY_SUCCESS,
  EDIT_CATEGORY_FAILURE,
  GET_SINGLE_CATEGORY_SUCCESS,
  GET_SINGLE_CATEGORY_FAILURE,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAILURE,
} from '../actions';
import { updateItemInArray, removeItemFromArray } from '../utils/mutation';

const INITIAL_STATE = {
  success: false,
  error: {},
  data: [],
  create: {
    success: false,
    error: null,
  },
  edit: {
    success: false,
    error: null,
  },
  delete: {
    success: false,
    error: null,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        create: { ...state.create, success: true },
        data: Array.isArray(state.data)
          ? { ...state.data }
          : {
              ...state.data,
              data: [...state.data.data, action.payload],
              total: state.data.total + 1,
            },
      };
    case CREATE_CATEGORY_FAILURE:
      return { ...state, create: { ...state.create, error: action.payload } };
    case GET_CATEGORIES_SUCCESS:
      return { ...INITIAL_STATE, data: action.payload };
    case GET_CATEGORIES_FAILURE:
      return { ...state, error: action };
    case EDIT_CATEGORY_SUCCESS:
      return {
        ...state,
        edit: { ...state.edit, success: true },
        data: {
          ...state.data,
          data: updateItemInArray(
            state.data.data,
            state.data.data.findIndex((b) => b.id === action.payload.id),
            action.payload,
            true
          ),
        },
      };
    case EDIT_CATEGORY_FAILURE:
      return { ...state, edit: { ...state.edit, error: action.payload } };
    case GET_SINGLE_CATEGORY_SUCCESS:
      return { ...state, edit: { ...state.edit, success: true } };
    case GET_SINGLE_CATEGORY_FAILURE:
      return { ...state, edit: { ...state.edit, error: action.payload } };
    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        delete: { ...state.edit, success: true },
        data: Array.isArray(state.data)
          ? { ...state.data }
          : {
              ...state.data,
              data: removeItemFromArray(
                state.data.data,
                state.data.data.findIndex((x) => x.id == action.payload.id)
              ),
              total: state.data.total - 1,
            },
      };
    case DELETE_CATEGORY_FAILURE:
      return { ...state, delete: { ...state.edit, error: action.payload } };
    default:
      return { ...state };
  }
};
