import client from "../../feathers";
import { ThemeColors } from '../../helpers/ThemeColors'
const colors = ThemeColors();

const serviceService = client.service("services");
const usersService = client.service("users");
const paymentService =  client.service("payments");

export const getProviderSpecificServices = async query => {
  try {
    const providers = await usersService.find(query)
    const providerListLabel = [];
    const serviceResultList = []
    const chartDataServices = [];

    let providerData;
    try{
      providers.data.map(ele=>ele)
      providerData = providers.data
    }catch(err){
      providerData = providers
    }
    providerData.forEach(provider => {
      if (provider.meta && provider.meta.serviceType) {
        providerListLabel.push(provider.id)
        getService(provider.meta.serviceType).then(res => {
          serviceResultList.push(res);
          chartDataServices.push({
            label: res.name,
            borderColor: colors.themeColor1,
            backgroundColor: colors.themeColor1_10,
            data: [res.price],
            borderWidth: 1
          });
        });
      }
    })

    const chartData = await {
      labels: providerListLabel,
      datasets: chartDataServices
    }
    return chartData
  } catch (error) {
    return error;
  }
};

const getService = serviceType => {
  return new Promise((resolve, reject) => {
    const result = (serviceService.get(parseInt(serviceType)));
    resolve(result)
  })
}

export const getAll = async data => {
  try {
    return await paymentService.find({ query: data })
  } catch (error) {
    return error;
  }
};

// export const getOne = async id => {
//   try {
//     return await paymentService.get(id);
//   } catch (error) {
//     return error;
//   }
// };
