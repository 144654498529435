import { put, call, takeLatest } from "redux-saga/effects";
import { getProviderSpecificServices ,getAll} from "./api";
import {
  getSpecificServicesByProviderSuccess,
  getSpecificServicesByProviderFailure,
  getAllTransactionsSuccess,
  getAllTransactionsFailure
} from "./actions";
import {
  GET_PROVIDER_SPECIFIC_SERVICES,
  GET_ALL_TRANSACTIONS
} from "../actions";


function* getSpecificServicesByProvider(action) {
  const response = yield call(getProviderSpecificServices, action.payload);
  if (response !== undefined) yield put(getSpecificServicesByProviderSuccess(response));
  else yield put(getSpecificServicesByProviderFailure(response));
}

function* getTransactions(action) {
  const response = yield call(getAll, action.payload);
  if (response !== undefined) yield put(getAllTransactionsSuccess(response));
  else yield put(getAllTransactionsFailure(response));
}

export default function* rootSaga() {
  yield takeLatest(GET_PROVIDER_SPECIFIC_SERVICES, getSpecificServicesByProvider);
  yield takeLatest(GET_ALL_TRANSACTIONS, getTransactions);
}
