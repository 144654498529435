import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  EDIT_ADMIN_SUCCESS,
  EDIT_PROVIDER_SUCCESS,
  OTP_PASSWORD,
  OTP_PASSWORD_SUCCESS,
  OTP_PASSWORD_ERROR,
} from '../actions';

const INIT_STATE = {
  user: localStorage.getItem('feathers-jwt'),
  userDetails: localStorage.getItem('userDetails'),
  forgotUserMail: '',
  newPassword: '',
  resetPasswordCode: '',
  loading: false,
  error: '',
  success: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true, error: '' };
    case LOGIN_USER_SUCCESS:
      const userRole = action.payload?.user?.role ? action.payload?.user?.role : '';
      const userId = action.payload?.user?.id ? action.payload?.user?.id : '';
      const email = action.payload?.user?.email ? action.payload?.user?.email : '';
      const { firstName, lastName, profileImage } = action.payload?.user?.meta;
      const generatePassword = action.payload?.user?.generatePassword
        ? action.payload?.user?.generatePassword
        : '';
      const userDetails = {
        id: userId,
        role: userRole,
        email: email,
        firstName: firstName ? firstName : '',
        lastName: lastName ? lastName : '',
        profileImg: profileImage ? profileImage : '',
        generatePassword,
      };
      localStorage.setItem('userDetails', JSON.stringify(userDetails));
      return {
        ...state,
        loading: false,
        user: action.payload.accessToken,
        generatePassword,
        userDetails: JSON.stringify(userDetails),
        error: '',
      };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        loading: false,
        user: '',
        error: action.payload?.message,
      };
    case FORGOT_PASSWORD:
      return { ...state, loading: true, error: '' };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        forgotUserMail: action?.payload,
        error: '',
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        forgotUserMail: '',
        error: action.payload?.message,
      };
    case RESET_PASSWORD:
      return {
        ...state,
        loading: true,
        error: '',
        otpSuccess: {},
        forgotUserMail: {},
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        newPassword: action.payload,
        resetPasswordCode: '',
        error: '',
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        newPassword: '',
        resetPasswordCode: '',
        error: action.payload?.message,
      };

    case OTP_PASSWORD:
      return {
        ...state,
        loading: true,
        error: '',
        otpSuccess: '',
        otpError: '',
        forgotUserMail: '',
      };
    case OTP_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        otpSuccess: action.payload,
        otpError: '',
      };
    case OTP_PASSWORD_ERROR:
      return { ...state, loading: false, otpSuccess: '', otpError: 'error' };

    case REGISTER_USER:
      return { ...state, loading: true, error: '' };
    case REGISTER_USER_SUCCESS:
      return { ...state, loading: false, user: null, error: '', success: true };
    case REGISTER_USER_ERROR:
      return {
        ...state,
        loading: false,
        user: '',
        error: action.payload?.message,
      };
    case LOGOUT_USER:
      return { ...INIT_STATE, user: null, error: '' };
    case EDIT_ADMIN_SUCCESS:
      const newDetails = {
        id: action?.payload?.id,
        role: 'admin',
        email: action.payload.email,
        firstName: action?.payload?.meta
          ? action?.payload?.meta?.firstName
            ? action?.payload?.meta?.firstName
            : ''
          : '',
        lastName: action?.payload?.meta
          ? action?.payload?.meta?.lastName
            ? action?.payload?.meta?.lastName
            : ''
          : '',
        profileImg: action?.payload?.meta?.profileImage,
      };
      return { ...state, userDetails: JSON.stringify(newDetails) };
    case EDIT_PROVIDER_SUCCESS:
      if (action?.payload?.id == JSON.parse(state.userDetails)?.id) {
        const providerDetails = {
          id: action?.payload?.id,
          role: action?.payload?.role,
          email: action?.payload?.email,
          firstName: action?.payload?.meta
            ? action?.payload?.meta?.firstName
              ? action?.payload?.meta?.firstName
              : ''
            : '',
          lastName: action?.payload?.meta
            ? action?.payload?.meta?.lastName
              ? action?.payload?.meta?.lastName
              : ''
            : '',
          profileImg: action?.payload?.meta?.profileImage,
        };
        return { ...state, userDetails: JSON.stringify(providerDetails) };
      } else {
        return { ...state };
      }
    default:
      return { ...state };
  }
};
