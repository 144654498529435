import client from '../../feathers';

const locationService = client.service('locations');

export const create = async (data) => {
  try {
    return await locationService.create(data);
  } catch (error) {
    return error;
  }
};

export const get = async (data) => {
  try {
    if (!data || (data && !data.$limit && !data.$skip)) {
      return await locationService.find({
        query: { ...(data || {}), paginate: false},
      });
    } else {
      return await locationService.find({ 
        query: {...data,$sort:{
          createdAt:-1
        }}
      });
    }
  } catch (error) {
    return error;
  }
};

export const getOne = async (id) => {
  try {
    return await locationService.get(id);
  } catch (error) {
    return error;
  }
};

export const update = async (id, data) => {
  try {
    return await locationService.patch(id, data);
  } catch (error) {
    return error;
  }
};

export const remove = async (id) => {
  try {
    return await locationService.remove(id);
  } catch (error) {
    return error;
  }
};

export const getLocation = async (id) => {
  const axios = require('axios');
  try {
    return await axios.get('https://ipapi.co/json/');
  } catch (error) {
    return error;
  }
};

// export const update = async () => {
//   try {
//     return await locationService.update
//   }
// }

export const searchByAddressCityAddress = async (payload) => {
  try {
    return await locationService.find({
      query: {
        searchOnly: true,
        $or: [
          { address: { $like: `%${payload.searchInput}%` } },
          { city: { $like: `%${payload.searchInput}%` } },
          { country: { $like: `%${payload.searchInput}%` } },
        ],
      },
    });
  } catch (error) {
    return error;
  }
};
