import {
  CREATE_NEW_PROVIDER_SUCCESS,
  CREATE_NEW_PROVIDER_FAILURE,
  GET_ALL_PROVIDERS,
  GET_ALL_PROVIDERS_SUCCESS,
  GET_ALL_PROVIDERS_FAILURE,
  DELETE_PROVIDER_SUCCESS,
  DELETE_PROVIDER_FAILURE,
  GET_SINGLE_PROVIDER,
  GET_SINGLE_PROVIDER_SUCCESS,
  GET_SINGLE_PROVIDER_FAILURE,
  EDIT_PROVIDER,
  EDIT_PROVIDER_SUCCESS,
  EDIT_PROVIDER_FAILURE,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAILURE,
} from '../actions';
import { updateItemInArray, removeItemFromArray } from '../utils/mutation';

const INITIAL_STATE = {
  data: [],
  success: false,
  error: null,
  create: {
    success: false,
    error: null,
  },
  edit: {
    data: {},
    success: false,
    error: null,
    editSuccess: undefined,
  },
  delete: {
    success: false,
    error: null,
  },
  allUsersCount: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Create Provider
    case CREATE_NEW_PROVIDER_SUCCESS:
      const responseData =
        state.data && state.data.data && state.data.data.length > 0
          ? { ...state.data, data: [...state.data.data, action.payload] }
          : action.payload;
      return {
        ...state,
        create: { ...state.create, success: true },
        data: responseData,
      };
    case CREATE_NEW_PROVIDER_FAILURE:
      return {
        ...state,
        create: { ...INITIAL_STATE.create, error: action.payload },
      };

    // Get All Providers
    case GET_ALL_PROVIDERS:
      return { ...INITIAL_STATE, loading: true };
    case GET_ALL_PROVIDERS_SUCCESS:
      return { ...INITIAL_STATE, loading: false, data: action.payload };
    case GET_ALL_PROVIDERS_FAILURE:
      return { ...INITIAL_STATE, loading: false, error: action.payload };

    // Get Single Provider
    case GET_SINGLE_PROVIDER:
      return { ...state, edit: { data: {}, error: null } };
    case GET_SINGLE_PROVIDER_SUCCESS:
      return { ...state, edit: { data: action.payload, error: null } };
    case GET_SINGLE_PROVIDER_FAILURE:
      return { ...state, edit: { ...state.edit, error: action.payload } };

    // Delete Provider
    case DELETE_PROVIDER_SUCCESS:
      return {
        ...state,
        delete: { ...INITIAL_STATE.delete, success: true },
        data: {
          ...state.data,
          data: removeItemFromArray(
            state.data.data,
            state.data.data.findIndex((x) => x.id == action.payload.id)
          ),
        },
      };
    case DELETE_PROVIDER_FAILURE:
      return {
        ...state,
        delete: { ...INITIAL_STATE.delete, error: action.payload },
      };

    // Update Provider
    case EDIT_PROVIDER:
      return {
        ...state,
        edit: { ...INITIAL_STATE.edit, success: false, editSuccess: undefined },
      };
    case EDIT_PROVIDER_SUCCESS:
      const response =
        state.data && state.data.data && state.data.data.length > 0
          ? {
              ...state.data,
              data: updateItemInArray(
                state.data.data,
                state.data.data.findIndex((b) => b.id === action.payload.id),
                action.payload,
                true
              ),
            }
          : action.payload;
      return {
        ...state,
        edit: { ...INITIAL_STATE.edit, success: true, data: action.payload, status: action.payload.status || '' },
        data: response,
      };
    case EDIT_PROVIDER_FAILURE:
      return {
        ...state,
        edit: { edit: false, error: action.payload, editSuccess: false },
      };

    // GET ALL USERS
    case GET_ALL_USERS_SUCCESS:
      // const allUsers = action.payload
      // const counts = {
      //   provider: 0,
      //   employee: 0,
      //   admin: 0,
      //   total: 0
      // }
      // allUsers && allUsers.data && allUsers.data.length > 0 && allUsers.data.forEach(b => {
      //   if(b.userRoleId==1)b.roleType='admin';
      //   counts[b.roleType] = b.count
      //   counts.total += b.count
      // })

      return { ...state, allUsersCount: action.payload.data };
    case GET_ALL_USERS_FAILURE:
      return { ...state, error: action.payload };

    default:
      return { ...state };
  }
};
