import client from "../../feathers";

const mediaMetaService = client.service("media-meta");

export const create = async data => {
  try {
    const arr = [
      { key: "title", value: data.title, mediaId: data.mediaId },
      { key: "categoryId", value: data.categoryId, mediaId: data.mediaId },
      { key: "locationId", value: data.locationId, mediaId: data.mediaId },
      { key: "url", value: data.url, mediaId: data.mediaId }
    ]
    return await mediaMetaService.create(arr);
  } catch (error) {
    return error;
  }
};
