import client from "../../feathers";

const graphService = client.service("graph");


export const get = async payload => {
    try {
        return await graphService.find({ query: {id: payload.id} });
    } catch (error) {
        return error;
    }
};