import {
    GET_USER_CLIENTS,
    GET_USER_CLIENTS_SUCCESS,
    GET_USER_CLIENTS_FAILURE,
    DELETE_USER_CLIENTS,
    DELETE_USER_CLIENTS_SUCCESS,
    DELETE_USER_CLIENTS_FAILURE,
    EDIT_USER_CLIENTS,
    EDIT_USER_CLIENTS_SUCCESS,
    EDIT_USER_CLIENTS_FAILURE,
    GET_SINGLE_USER_CLIENTS,
    GET_SINGLE_USER_CLIENTS_SUCCESS,
    GET_SINGLE_USER_CLIENTS_FAILURE,

} from '../actions';

export const getUserClients = data => {
    return {
        type: GET_USER_CLIENTS,
        payload: data
    };
};

export const getUserClientsSuccess = data => {
    return {
        type: GET_USER_CLIENTS_SUCCESS,
        payload: data
    };
};

export const getUserClientsFailure = error => {
    return {
        type: GET_USER_CLIENTS_FAILURE,
        payload: error
    };
};

export const deleteUserClient = id => {
    return {
        type: DELETE_USER_CLIENTS,
        payload: id
    };
};
export const deleteUserClientSuccess = (data) => {
  return {
    type: DELETE_USER_CLIENTS_SUCCESS,
	payload:data
  };
};

export const deleteUserClientFailure = error => {
  return {
    type: DELETE_USER_CLIENTS_FAILURE,
    payload: error
  };
};

export const editUserClient = data => {
  return {
    type: EDIT_USER_CLIENTS,
    payload: data
  };
};

export const editUserClientSuccess = data => {
  return {
    type: EDIT_USER_CLIENTS_SUCCESS,
    payload: data
  };
};

export const editUserClientFailure = error => {
  return {
    type: EDIT_USER_CLIENTS_FAILURE,
    payload: error
  };
};

export const getSingleUserClient = id => {
  return {
    type: GET_SINGLE_USER_CLIENTS,
    payload: parseInt(id)
  };
};

export const getSingleUserClientSuccess = data => {
  return {
    type: GET_SINGLE_USER_CLIENTS_SUCCESS,
    payload: data
  };
};

export const getSingleUserClientFailure = error => {
  return {
    type: GET_SINGLE_USER_CLIENTS_FAILURE,
    payload: error
  };
};