import client from "../../feathers";

const adService = client.service("ads");

export const create = async data => {
  try {
    return await adService.create(data);
  } catch (error) {
    return error;
  }
};

export const get = async (data) => {
  try {
    return await adService.find({ query: {
      ...(data||{})
    }});
  } catch (error) {
    return error;
  }
};

export const getOne = async id => {
  try {
    return await adService.get(id);
  } catch (error) {
    return error;
  }
};

export const update = async (id, data) => {
  try {
    return await adService.patch(id, data)
  } catch (error) {
    return error;
  }
}

export const remove = async id => {
  try {
    return await adService.remove(id)
  } catch (error) {
    return error
  }
}

export const getAd = async id => {
  const axios = require('axios');
  try {
    return await axios.get("https://ipapi.co/json/")
  } catch (error) {
    return error
  }
}

export const getAllAdsApi = async () => {
  try {
    return await adService.find({
      query: {
        $group: 'categoryId'
      }
    });
  } catch (error) {
    return error;
  }
}

// export const update = async () => {
//   try {
//     return await adService.update
//   }
// }
