import {
	CREATE_TAG,
	CREATE_TAG_SUCCESS,
	CREATE_TAG_FAILURE,
	GET_TAGS_SUCCESS,
	GET_TAGS_FAILURE,
	EDIT_TAG_SUCCESS,
	EDIT_TAG_FAILURE,
	GET_SINGLE_TAG_SUCCESS,
	GET_SINGLE_TAG_FAILURE,
	DELETE_TAG_SUCCESS,
	DELETE_TAG_FAILURE,
	// APPROVE_TAG,
	APPROVE_TAG_SUCCESS,
	APPROVE_TAG_FAILURE
} from "../actions";
import { updateItemInArray, removeItemFromArray } from '../utils/mutation';

const INITIAL_STATE = {
	success: false,
	error: {},
	data: [],
	create: {
		success: false,
		error: null
	},
	edit: {
		success: false,
		error: null
	},
	delete: {
		success: false,
		error: null
	},
	approve: {
		success: false,
		error: null
	}
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CREATE_TAG:
			return { ...state };
		case CREATE_TAG_SUCCESS:
			return { ...state, create: { ...state.create, success: true }, data: [...state.data, action.payload] };
		case CREATE_TAG_FAILURE:
			return { ...state, create: { ...state.create, error: action.payload } };
		case GET_TAGS_SUCCESS:
			return { ...INITIAL_STATE, data: action.payload };
		case GET_TAGS_FAILURE:
			return { ...INITIAL_STATE, error: action };
		case EDIT_TAG_SUCCESS:
			return {
				...state,
				edit: { ...state.edit, success: true },
				data: updateItemInArray(
					state.data,
					state.data.findIndex(b => b.id === action.payload.id),
					action.payload,
					true,
				)
			};
		case EDIT_TAG_FAILURE:
			return { ...state, edit: { ...state.edit, error: action.payload } };
		case GET_SINGLE_TAG_SUCCESS:
			return { ...state, edit: { ...state.edit, success: true } };
		case GET_SINGLE_TAG_FAILURE:
			return { ...state, edit: { ...state.edit, error: action.payload } };
		case DELETE_TAG_SUCCESS:
			return {
				...state,
				delete: { ...state.edit, success: true },
				data: removeItemFromArray(state.data, state.data.findIndex(x => x.id == action.payload.id))

			};
		case DELETE_TAG_FAILURE:
			return { ...state, delete: { ...state.edit, error: action.payload } };
		case APPROVE_TAG_SUCCESS:
			return { ...state, approve: { ...state.approve, success: true } };
		case APPROVE_TAG_FAILURE:
			return { ...state, approve: { ...state.approve, error: action.payload } };
		default:
			return { ...state };
	}
};