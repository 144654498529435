import {
	CREATE_AD,
	CREATE_AD_SUCCESS,
	CREATE_AD_FAILURE,
	GET_ADS_SUCCESS,
	GET_ADS_FAILURE,
	GET_ADS,
	EDIT_AD,
	EDIT_AD_SUCCESS,
	EDIT_AD_FAILURE,
	GET_SINGLE_AD,
	GET_SINGLE_AD_SUCCESS,
	GET_SINGLE_AD_FAILURE,
	DELETE_AD,
	DELETE_AD_SUCCESS,
	DELETE_AD_FAILURE,
	GET_CURRENT_AD,
	GET_CURRENT_AD_SUCCESS,
	GET_ALL_ADS,
	GET_ALL_ADS_SUCCESS,
	GET_ALL_ADS_FAILURE
} from "../actions";

export const getCurrentAd = () => {
	return {
		type: GET_CURRENT_AD
	};
};

export const getCurrentAdSuccess = data => {
	return {
		type: GET_CURRENT_AD_SUCCESS,
		payload: data
	};
};

export const createAd = data => {
	return {
		type: CREATE_AD,
		payload: data
	};
};

export const createAdSuccess = msg => {
	return {
		type: CREATE_AD_SUCCESS,
		payload: msg
	};
};

export const createAdFailure = error => {
	return {
		type: CREATE_AD_FAILURE,
		payload: error
	};
};

export const getAds = (payload) => {
	return {
		type: GET_ADS,
		payload
	};
};

export const getAllAds = () => {
	return {
		type: GET_ALL_ADS
	};
};

export const adAllSuccess = data => {
	return {
		type: GET_ALL_ADS_SUCCESS,
		payload: data
	};
};

export const adAllFailure = error => {
	return {
		type: GET_ALL_ADS_FAILURE,
		payload: error
	};
};

export const adSuccess = data => {
	return {
		type: GET_ADS_SUCCESS,
		payload: data
	};
};

export const adFailure = error => {
	return {
		type: GET_ADS_FAILURE,
		payload: error
	};
};

export const editAd = data => {
	return {
		type: EDIT_AD,
		payload: data
	};
};

export const editAdSuccess = data => {
	return {
		type: EDIT_AD_SUCCESS,
		payload: data
	};
};

export const editAdFailure = error => {
	return {
		type: EDIT_AD_FAILURE,
		payload: error
	};
};

export const getSingleAd = id => {
	return {
		type: GET_SINGLE_AD,
		payload: id
	};
};

export const getSingleAdSuccess = data => {
	return {
		type: GET_SINGLE_AD_SUCCESS,
		payload: data
	};
};

export const getSingleAdFailure = error => {
	return {
		type: GET_SINGLE_AD_FAILURE,
		payload: error
	};
};

export const deleteAd = id => {
	return {
		type: DELETE_AD,
		payload: id
	}
}
export const deleteAdSuccess = (data) => {
	return {
		type: DELETE_AD_SUCCESS,
		payload: data
	}
}
export const deleteAdFailure = error => {
	return {
		type: DELETE_AD_FAILURE,
		payload: error
	}
}