import { put, call, takeLatest } from "redux-saga/effects";
import { create, get, getOne, update, remove, getLocation, searchByAddressCityAddress } from "./api";
import {
  locationSuccess,
  locationFailure,
  createLocationSuccess,
  createLocationFailure,
  getSingleLocationSuccess,
  getSingleLocationFailure,
  editLocationSuccess,
  editLocationFailure,
  deleteLocationSuccess,

  deleteLocationFailure,
  getCurrentLocationSuccess
} from "./actions";

function* createLocation(action) {
  
  const response = yield call(create, action.payload);
  if (response.hasOwnProperty("id")) yield put(createLocationSuccess(response));
  else yield put(createLocationFailure(response.errors[0].message));
 
}

function* getLocations(action) {
  const response = yield call(get, action.payload);
  if (response !== undefined) yield put(locationSuccess(response));
  else yield put(locationFailure(response));
}

function* getLocationsSearch(action) {
  const response = yield call(searchByAddressCityAddress, action.payload);
  if (response !== undefined) yield put(locationSuccess(response));
  else yield put(locationFailure(response));
}

function* getSingleLocation(action) {
  const response = yield call(getOne, action.payload);
  if (response.hasOwnProperty('id')) yield put(getSingleLocationSuccess(response));
  else yield put(getSingleLocationFailure(response));
}

function* updateLocation(action) {
    const response = yield call(update, action.payload.id, action.payload.data)
    if (response.hasOwnProperty('id')) yield put(editLocationSuccess(response));
    else yield put(editLocationFailure(response));
}

function* deleteLocation(action) {
    const response = yield call(remove, action.payload)
    if (response.hasOwnProperty('id')) yield put(deleteLocationSuccess(response));
    else yield put(deleteLocationFailure(response));
}

function* getCurrentLocation() {
  const response = yield call(getLocation);
  if (response.hasOwnProperty('data')) yield put(getCurrentLocationSuccess(response));
  // else yield put(getSingleLocationFailure(response));
}

export default function* rootSaga() {
  yield takeLatest("CREATE_LOCATION", createLocation);
  yield takeLatest("GET_LOCATIONS", getLocations);
  yield takeLatest("GET_SINGLE_LOCATION", getSingleLocation);
  yield takeLatest("EDIT_LOCATION", updateLocation);
  yield takeLatest("DELETE_LOCATION", deleteLocation);
  yield takeLatest("GET_CURRENT_LOCATION", getCurrentLocation);
  yield takeLatest("GET_LOCATIONS_SEARCH", getLocationsSearch);
}
