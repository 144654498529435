import { all, call, fork, put, takeEvery, delay } from 'redux-saga/effects';
import { signup, login } from './api';
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  OTP_PASSWORD,
} from '../actions';

import {
  loginUserSuccess,
  loginUserError,
  logoutUserSucess,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  submitOTPSuccess,
  submitOTPError,
  resetPasswordSuccess,
  resetPasswordError,
} from './actions';
import client from '../../feathers';

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

function* loginWithEmailPassword({ payload,loginProvider }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const loginUser = yield call(login, { email: email, password: password });
    // const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
    if (!loginUser.message) {
      yield put(loginUserSuccess(loginUser));
      if(loginProvider){
        window.location.href = '/provider/dashboard'
      }
      history.push('/');
    } else {
      yield put(loginUserError(loginUser.message));
    }
  } catch (error) {
    yield put(loginUserError(error));
  }

}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

function* registerWithEmailPassword({ payload }) {
  const { history } = payload;
  try {
    const registerUser = yield call(signup, payload.values);
    if (!registerUser.message) {
      yield put(registerUserSuccess(registerUser));
      yield delay(5000);
      history.push('/');
    } else {
      yield put(
        registerUserError(
          registerUser.message + ': ' + registerUser.errors[0].message
        )
      );
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
  await client.logout();
  window.location.replace('/user/login'); //refresh
};

function* logout({ payload }) {
  const { history } = payload;
  try {
    yield call(logoutAsync, history);
    localStorage.removeItem('user_id');
    localStorage.removeItem('userDetails');
    yield put(logoutUserSucess());
  } catch (error) {}
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

export function* watchResetPasswordRequest() {
  yield takeEvery(OTP_PASSWORD, submitOTP);
}

const forgotPasswordAsync = async (email) => {
  return await client.service('reset-password').create({ email });
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (forgotPasswordStatus && forgotPasswordStatus.id > 0) {
      yield put(forgotPasswordSuccess('success'));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

const ValidateOtpRequest = async (otp) => {
  const query = { query: { token: otp } };
  return await client.service('reset-password').find(query);
};

function* submitOTP({ payload }) {
  const { otp } = payload.otp;
  try {
    const res = yield call(ValidateOtpRequest, otp);
    if (res && res.length > 0) {
      res[0].success = 'success';
      yield put(submitOTPSuccess(res));
    } else {
      yield put(submitOTPSuccess('failure'));
    }
  } catch (error) {
    yield put(submitOTPError(error));
  }
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (data) => {
  const id = data.id;
  return await client.service('reset-password').patch(id, {
    password: data.password,
    token: data.Otp,
  });
};

function* resetPassword({ payload }) {
  try {
    const resetPasswordStatus = yield call(resetPasswordAsync, payload);
    if (resetPasswordStatus) {
      yield put(resetPasswordSuccess('success'));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPasswordRequest),
    fork(watchResetPassword),
  ]);
}
