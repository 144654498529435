import client from "../../feathers";

const reviewService = client.service("reviews");

export const get = async (data) => {
  try {
    return await reviewService.find({ query: data });
  } catch (error) {
    return error;
  }
};

export const update = async (id, data) => {
  try {
    return await reviewService.patch(id, data)
  } catch (error) {
    return error;
  }
}
