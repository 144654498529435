import { put, call, takeLatest } from "redux-saga/effects";
import { create, getAll, remove, getOne,getAllEmployess, update, updateByAdmin } from "./api";
import {
  createClientSuccess,
  createClientFailure,
  getEmployeesSuccess,
  getEmployeesFailure,
  getClientsSuccess,
  getClientsFailure,
  deleteClientSuccess,
  deleteClientFailure,
  getSingleClientSuccess,
  getSingleClientFailure,
  editClientSuccess,
  editClientFailure,
  editClientByAdminSuccess,
  editClientByAdminFailure
} from "./actions";
import {
  CREATE_NEW_CLIENT,
  GET_ALL_CLIENTS,
  DELETE_CLIENT,
  GET_SINGLE_CLIENT,
  GET_ALL_EMPLOYEES,
  EDIT_CLIENT,
  EDIT_CLIENT_BY_ADMIN
} from "../actions";

function* getSingleClient(action) {
  const response = yield call(getOne, action.payload);
  if (response.hasOwnProperty("id"))
    yield put(getSingleClientSuccess(response));
  else yield put(getSingleClientFailure(response));
}

function* getAllClients(action) {
  const response = yield call(getAll,action.payload);
  if (response !== undefined) yield put(getClientsSuccess(response));
  else yield put(getClientsFailure(response));
}


function* getAllEmployees(action) {
  const response = yield call(getAllEmployess,action.payload);
  if (response !== undefined) yield put(getEmployeesSuccess(response));
  else yield put(getEmployeesFailure(response));
}

function* createNewClient(action) {
  try{
  const response = yield call(create, action.payload);
  if (response.hasOwnProperty("id")) yield put(createClientSuccess(response));
  else yield put(createClientFailure(response));
  }catch(e){
	if (process.env.NODE_ENV == 'development'){
		console.log("err", e);
	}
  }
}

function* deleteClientByID(action) {
  const response = yield call(remove, action.payload.id);
  if (response.hasOwnProperty("id")) yield put(deleteClientSuccess(response));
  else yield put(deleteClientFailure(response));
}

function* updateClient(action) {
  const response = yield call(update, action.payload.id, action.payload.data);
  if (response.hasOwnProperty("id")) yield put(editClientSuccess(response));
  else yield put(editClientFailure(response.errors));
}

function* updateClientByAdmin(action) {
  const response = yield call(updateByAdmin, action.payload.id, action.payload.data);
  if (response.hasOwnProperty("id")) yield put(editClientByAdminSuccess(response));
  else yield put(editClientByAdminFailure(response.errors));
}

export default function* rootSaga() {
  yield takeLatest(CREATE_NEW_CLIENT, createNewClient);
  yield takeLatest(GET_ALL_CLIENTS, getAllClients);
  yield takeLatest(GET_ALL_EMPLOYEES, getAllEmployees);
  yield takeLatest(DELETE_CLIENT, deleteClientByID);
  yield takeLatest(GET_SINGLE_CLIENT, getSingleClient);
  yield takeLatest(EDIT_CLIENT, updateClient);
  yield takeLatest(EDIT_CLIENT_BY_ADMIN, updateClientByAdmin)
}
