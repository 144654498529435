import {
  CREATE_LOCATION,
  CREATE_LOCATION_SUCCESS,
  CREATE_LOCATION_FAILURE,
  GET_LOCATIONS,
  GET_LOCATIONS_SUCCESS,
  GET_LOCATIONS_FAILURE,
  EDIT_LOCATION_SUCCESS,
  EDIT_LOCATION_FAILURE,
  GET_SINGLE_LOCATION_SUCCESS,
  GET_SINGLE_LOCATION_FAILURE,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_FAILURE,
  GET_CURRENT_LOCATION_SUCCESS,
} from '../actions';
import { updateItemInArray, removeItemFromArray } from '../utils/mutation';

const INITIAL_STATE = {
  success: false,
  error: {},
  data: [],
  singleData: {},
  loading: false,
  create: {
    success: false,
    error: null,
  },
  edit: {
    success: false,
    error: null,
  },
  delete: {
    success: false,
    error: null,
  },
  currentLocation: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATE_LOCATION:
      return { ...state };
    case CREATE_LOCATION_SUCCESS:
      return { ...state, create: { ...state.create, success: true } };
    case CREATE_LOCATION_FAILURE:
      return { ...state, create: { ...state.create, error: action.payload } };
    case GET_LOCATIONS:
      return {
        ...state,
        loading: true,
      };
    case GET_LOCATIONS_SUCCESS:
      return { ...INITIAL_STATE, loading: false, data: action.payload };
    case GET_LOCATIONS_FAILURE:
      return { ...INITIAL_STATE, loading: false, error: action };
    case EDIT_LOCATION_SUCCESS:
      const response =
        state.data && state.data.length > 0
          ? updateItemInArray(
              state.data,
              state.data.findIndex((b) => b.id === action.payload.id),
              action.payload,
              true
            )
          : action.payload;
      return {
        ...state,
        edit: { ...state.edit, success: true },
        data: response,
      };
    case EDIT_LOCATION_FAILURE:
      return { ...state, edit: { ...state.edit, error: action.payload } };
    case GET_SINGLE_LOCATION_SUCCESS:
      return { ...state, singleData: action.payload };
    case GET_SINGLE_LOCATION_FAILURE:
      return { ...state, edit: { ...state.edit, error: action.payload } };
    case DELETE_LOCATION_SUCCESS:
      return {
        ...state,
        delete: { ...state.edit, success: true },
        data: removeItemFromArray(
          state.data.data,
          state.data.data.findIndex((x) => x.id == action.payload.id)
        ),
      };
    case DELETE_LOCATION_FAILURE:
      return { ...state, delete: { ...state.edit, error: action.payload } };
    case GET_CURRENT_LOCATION_SUCCESS:
      return { ...state, currentLocation: action.payload.data };
    default:
      return { ...state };
  }
};
