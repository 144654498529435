import { put, call, takeLatest } from 'redux-saga/effects';
import { get, update } from './api';
import {
  getReviewsSuccess,
  getReviewsFailure,
  editReviewSuccess,
  editReviewFailure,
} from './actions';
import { GET_REVIEWS, EDIT_REVIEW } from '../actions';

function* getReviewsAll(action) {
  const response = yield call(get, action.payload);
  if (
    response &&
    Array.isArray(response) &&
    action.payload &&
    action.payload.parentProviderId
  ) {
    response.forEach((r) => {
      r.parentProviderId = action.payload.parentProviderId;
    });
  }
  if (response !== undefined) yield put(getReviewsSuccess(response));
  else yield put(getReviewsFailure(response.errors));
}

function* updateReview(action) {
  const response = yield call(update, action.payload.id, action.payload.data);
  if (response.hasOwnProperty('id')) yield put(editReviewSuccess(response));
  else yield put(editReviewFailure(response));
}

export default function* rootSaga() {
  yield takeLatest(GET_REVIEWS, getReviewsAll);
  yield takeLatest(EDIT_REVIEW, updateReview);
}
