import {
	GET_ALL_BCGS,
	GET_ALL_BCGS_SUCCESS,
	GET_ALL_BCGS_FAILURE,
	DELETE_BCG,
	DELETE_BCG_SUCCESS,
	DELETE_BCG_FAILURE,
	EDIT_BCG,
	EDIT_BCG_SUCCESS,
	EDIT_BCG_FAILURE,
	GET_SINGLE_BCG,
	GET_SINGLE_BCG_SUCCESS,
	GET_SINGLE_BCG_FAILURE
} from "../actions";

export const getBcgs = data => {
	return {
		type: GET_ALL_BCGS,
		payload: data
	};
};

export const getBcgsSuccess = data => {
	return {
		type: GET_ALL_BCGS_SUCCESS,
		payload: data
	};
};

export const getBcgsFailure = error => {
	return {
		type: GET_ALL_BCGS_FAILURE,
		payload: error
	};
};


export const deleteBcg = id => {
	return {
		type: DELETE_BCG,
		payload: id
	};
};

export const deleteBcgSuccess = (data) => {
	return {
		type: DELETE_BCG_SUCCESS,
		payload: data
	};
};

export const deleteBcgFailure = error => {
	return {
		type: DELETE_BCG_FAILURE,
		payload: error
	};
};

export const editBcg = data => {
	return {
		type: EDIT_BCG,
		payload: data
	};
};

export const editBcgSuccess = data => {
	return {
		type: EDIT_BCG_SUCCESS,
		payload: data
	};
};

export const editBcgFailure = error => {
	return {
		type: EDIT_BCG_FAILURE,
		payload: error
	};
};

export const getSingleBcg = id => {
	return {
		type: GET_SINGLE_BCG,
		payload: parseInt(id)
	};
};

export const getSingleBcgSuccess = data => {
	return {
		type: GET_SINGLE_BCG_SUCCESS,
		payload: data
	};
};

export const getSingleBcgFailure = error => {
	return {
		type: GET_SINGLE_BCG_FAILURE,
		payload: error
	};
};

