import {
	CREATE_CATEGORY,
	CREATE_CATEGORY_SUCCESS,
	CREATE_CATEGORY_FAILURE,
	GET_CATEGORIES_SUCCESS,
	GET_CATEGORIES_FAILURE,
	GET_CATEGORIES,
	EDIT_CATEGORY,
	EDIT_CATEGORY_SUCCESS,
	EDIT_CATEGORY_FAILURE,
	GET_SINGLE_CATEGORY,
	GET_SINGLE_CATEGORY_SUCCESS,
	GET_SINGLE_CATEGORY_FAILURE,
	DELETE_CATEGORY,
	DELETE_CATEGORY_SUCCESS,
	DELETE_CATEGORY_FAILURE
} from "../actions";

export const createCategory = name => {
	return {
		type: CREATE_CATEGORY,
		payload: name
	};
};

export const createCategorySuccess = msg => {
	return {
		type: CREATE_CATEGORY_SUCCESS,
		payload: msg
	};
};

export const createCategoryFailure = error => {
	return {
		type: CREATE_CATEGORY_FAILURE,
		payload: error
	};
};

export const getCategories = (params) => {
	return {
		type: GET_CATEGORIES,
		payload: params
	};
};

export const categorySuccess = data => {
	return {
		type: GET_CATEGORIES_SUCCESS,
		payload: data
	};
};

export const categoryFailure = error => {
	return {
		type: GET_CATEGORIES_FAILURE,
		payload: error
	};
};

export const editCategory = data => {
	return {
		type: EDIT_CATEGORY,
		payload: data
	};
};

export const editCategorySuccess = (data) => {
	return {
		type: EDIT_CATEGORY_SUCCESS,
		payload: data
	};
};

export const editCategoryFailure = error => {
	return {
		type: EDIT_CATEGORY_FAILURE,
		payload: error
	};
};

export const getSingleCategory = id => {
	return {
		type: GET_SINGLE_CATEGORY,
		payload: id
	};
};

export const getSingleCategorySuccess = data => {
	return {
		type: GET_SINGLE_CATEGORY_SUCCESS,
		payload: data
	};
};

export const getSingleCategoryFailure = error => {
	return {
		type: GET_SINGLE_CATEGORY_FAILURE,
		payload: error
	};
};

export const deleteCategory = id => {
	return {
		type: DELETE_CATEGORY,
		payload: id
	}
}
export const deleteCategorySuccess = (data) => {
	return {
		type: DELETE_CATEGORY_SUCCESS,
		payload: data
	}
}
export const deleteCategoryFailure = error => {
	return {
		type: DELETE_CATEGORY_FAILURE,
		payload: error
	}
}