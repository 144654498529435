import {
    CREATE_LOCATION,
    CREATE_LOCATION_SUCCESS,
    CREATE_LOCATION_FAILURE,
    GET_LOCATIONS_SUCCESS,
    GET_LOCATIONS_FAILURE,
    GET_LOCATIONS,
    EDIT_LOCATION,
    EDIT_LOCATION_SUCCESS,
    EDIT_LOCATION_FAILURE,
    GET_SINGLE_LOCATION,
    GET_SINGLE_LOCATION_SUCCESS,
    GET_SINGLE_LOCATION_FAILURE,
    DELETE_LOCATION,
    DELETE_LOCATION_SUCCESS,
    DELETE_LOCATION_FAILURE,
    GET_CURRENT_LOCATION,
    GET_CURRENT_LOCATION_SUCCESS,
    GET_LOCATIONS_SEARCH,
  } from "../actions";
  
  export const getCurrentLocation = () => {
    return {
      type: GET_CURRENT_LOCATION
    };
  };

  export const getCurrentLocationSuccess = data => {
    return {
      type: GET_CURRENT_LOCATION_SUCCESS,
      payload: data
    };
  };

  export const createLocation = data => {
    return {
      type: CREATE_LOCATION,
      payload: data
    };
  };
  
  export const createLocationSuccess = msg => {
    return {
      type: CREATE_LOCATION_SUCCESS,
      payload: msg
    };
  };
  
  export const createLocationFailure = error => {
    return {
      type: CREATE_LOCATION_FAILURE,
      payload: error
    };
  };
  
  export const getLocations = (data) => {
    return {
      type: GET_LOCATIONS,
      payload: data
    };
  };

  export const getLocationsSearch = (data) => {
    return {
      type: GET_LOCATIONS_SEARCH,
      payload: data
    };
  };
  
  export const locationSuccess = data => {
    return {
      type: GET_LOCATIONS_SUCCESS,
      payload: data
    };
  };
  
  export const locationFailure = error => {
    return {
      type: GET_LOCATIONS_FAILURE,
      payload: error
    };
  };
  
  export const editLocation = data => {
    return {
      type: EDIT_LOCATION,
      payload: data
    };
  };
  
  export const editLocationSuccess = (data) => {
    return {
      type: EDIT_LOCATION_SUCCESS,
	  payload:data
    };
  };
  
  export const editLocationFailure = error => {
    return {
      type: EDIT_LOCATION_FAILURE,
      payload: error
    };
  };
  
  export const getSingleLocation = id => {
    return {
      type: GET_SINGLE_LOCATION,
      payload: id
    };
  };
  
  export const getSingleLocationSuccess = data => {
    return {
      type: GET_SINGLE_LOCATION_SUCCESS,
      payload: data
    };
  };
  
  export const getSingleLocationFailure = error => {
    return {
      type: GET_SINGLE_LOCATION_FAILURE,
      payload: error
    };
  };
  
  export const deleteLocation = id => {
    return {
      type: DELETE_LOCATION,
      payload: id
    }
  }
  export const deleteLocationSuccess = (data) => {
    return {
      type: DELETE_LOCATION_SUCCESS,
	  payload:data
    }
  }
  export const deleteLocationFailure = error => {
    return {
      type: DELETE_LOCATION_FAILURE,
      payload: error
    }
  }