import {
  SUBMIT_SURVEY,
  SUBMIT_SURVEY_SUCCESS,
  SUBMIT_SURVEY_FAILURE,
  GET_SURVEY_ANSWERS,
  GET_SURVEY_ANSWERS_SUCCESS,
  GET_SURVEY_ANSWERS_FAILURE
} from "../actions";

export const submitSurvey = () => {
  return {
    type: SUBMIT_SURVEY_SUCCESS,
    // payload: name
  };
};

export const submitSurveySuccess = msg => {
  return {
    type: SUBMIT_SURVEY_SUCCESS,
    payload: msg
  };
};

export const submitSurveyFailure = error => {
  return {
    type: SUBMIT_SURVEY_FAILURE,
    payload: error
  };
};

export const getSurveyAnswers = (data) => {
  return {
    type: GET_SURVEY_ANSWERS,
    payload: data
  };
};

export const getSurveyAnswersSuccess = (data) => {
  return {
    type: GET_SURVEY_ANSWERS_SUCCESS,
    payload: data
  };
};

export const surveyFailure = (data) => {
  return {
    type: GET_SURVEY_ANSWERS_FAILURE,
    payload: data
  }
}