import {
  CREATE_NEW_PROVIDER,
  CREATE_NEW_PROVIDER_SUCCESS,
  CREATE_NEW_PROVIDER_FAILURE,
  GET_ALL_PROVIDERS,
  GET_ALL_PROVIDERS_SUCCESS,
  GET_ALL_PROVIDERS_FAILURE,
  DELETE_PROVIDER,
  DELETE_PROVIDER_SUCCESS,
  DELETE_PROVIDER_FAILURE,
  EDIT_PROVIDER,
  EDIT_PROVIDER_SUCCESS,
  EDIT_PROVIDER_FAILURE,
  GET_SINGLE_PROVIDER,
  GET_SINGLE_PROVIDER_SUCCESS,
  GET_SINGLE_PROVIDER_FAILURE,
  GET_ALL_USERS,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAILURE,
  // CLEAR_PROFILE,
} from "../actions";

export const getProviders = data => {
  return {
    type: GET_ALL_PROVIDERS,
    payload: data
  };
};

export const getProvidersSuccess = data => {
  return {
    type: GET_ALL_PROVIDERS_SUCCESS,
    payload: data
  };
};

export const getProvidersFailure = error => {
  return {
    type: GET_ALL_PROVIDERS_FAILURE,
    payload: error
  };
};

export const createProvider = data => {
  return {
    type: CREATE_NEW_PROVIDER,
    payload: data
  };
};

export const createProviderSuccess = (data) => {
  return {
    type: CREATE_NEW_PROVIDER_SUCCESS,
	payload:data
  };
};

export const createProviderFailure = error => {
  return {
    type: CREATE_NEW_PROVIDER_FAILURE,
    payload: error
  };
};

export const deleteProvider = obj => {
  return {
    type: DELETE_PROVIDER,
    payload: obj
  };
};

export const deleteProviderSuccess = (data) => {
  return {
    type: DELETE_PROVIDER_SUCCESS,
	payload:data
  };
};

export const deleteProviderFailure = error => {
  return {
    type: DELETE_PROVIDER_FAILURE,
    payload: error
  };
};

export const editProvider = data => {
  return {
    type: EDIT_PROVIDER,
    payload: data
  };
};

export const editProviderSuccess = data => {
  return {
    type: EDIT_PROVIDER_SUCCESS,
    payload: data
  };
};

export const editProviderFailure = error => {
  return {
    type: EDIT_PROVIDER_FAILURE,
    payload: error
  };
};

export const getSingleProvider = id => {
  return {
    type: GET_SINGLE_PROVIDER,
    payload: parseInt(id)
  };
};

export const getSingleProviderSuccess = data => {
  return {
    type: GET_SINGLE_PROVIDER_SUCCESS,
    payload: data
  };
};

export const getSingleProviderFailure = error => {
  return {
    type: GET_SINGLE_PROVIDER_FAILURE,
    payload: error
  };
};

// GET ALL USERS
export const getAllUsers = data => {
  return {
    type: GET_ALL_USERS,
    payload: data
  };
};

export const getAllUsersSuccess = data => {
  return {
    type: GET_ALL_USERS_SUCCESS,
    payload: data
  };
};

export const getAllUsersFailure = error => {
  return {
    type: GET_ALL_USERS_FAILURE,
    payload: error
  };
};

// export const clearProfile = data => {
//   return {
//     type: CLEAR_PROFILE,
//     payload: {}
//   };
// };