import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import categories from './categories/reducer';
import tags from './tags/reducer';
import services from './services/reducer';
import userRoles from './user-roles/reducer';
import providers from './providers/reducer';
import bookings from './bookings/reducer';
import supportTicket from './support_tickets/reducer';
import media from './media/reducer';
import mediaMeta from './media-meta/reducer';
import employees from './clients/reducer';
import locations from './locations/reducer';
import ads from './ads/reducer';
import certificates from './certificates/reducer';
import financial from './financial/reducer';
import reviews from './reviews/reducer';
import notifications from './notifications/reducer';
import admin from './admin/reducer';
import survey from './survey/reducer';
import userClient from './user-clients/reducer';
import bcgRequest from './bcg-request/reducer';
import appConfigurations from './app-configuration/reducer';
import graph from './graph/reducer';
import usersMeta from './users-meta/reducer';

import { LOGIN_USER, LOGOUT_USER_SUCCESS } from './actions';

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  categories,
  tags,
  services,
  userRoles,
  providers,
  bookings,
  supportTicket,
  media,
  employees,
  locations,
  ads,
  certificates,
  mediaMeta,
  financial,
  reviews,
  notifications,
  admin,
  survey,
  userClient,
  bcgRequest,
  appConfigurations,
  graph,
  usersMeta,
});

const rootreducer = (state, action) => {
  if (action.type === LOGIN_USER || action.type === LOGOUT_USER_SUCCESS) {
    state = {
      authUser: { ...state.authUser },
    };
  }
  return reducers(state, action);
};
export default rootreducer;
