import { put, call, takeLatest } from "redux-saga/effects";
import { create, get, getOne, update, remove, getWithServices, updateRating } from "./api";
import {
  bookingSuccess,
  bookingFailure,
  createBookingSuccess,
  createBookingFailure,
  getSingleBookingSuccess,
  getSingleBookingFailure,
  editBookingSuccess,
  editBookingFailure,
  deleteBookingSuccess,
  deleteBookingFailure,
  bookingWithStatusSuccess,
  bookingWithStatusFailure
} from "./actions";

function* createBooking(action) {
  const response = yield call(create, action.payload);
  if (response.hasOwnProperty("id")) yield put(createBookingSuccess(response));
  else yield put(createBookingFailure(response.errors[0].message));
}

function* getBookings(action) {
  const response = yield call(get, action.payload);
  // response = response.sort((a, b) => (b.id - a.id))
  if (response !== undefined) yield put(bookingSuccess(response));
  else yield put(bookingFailure(response));
}

function* getSingleBooking(action) {
  const response = yield call(getOne, action.payload);
  if (response.hasOwnProperty('id')) yield put(getSingleBookingSuccess(response));
  else yield put(getSingleBookingFailure(response));
}

function* updateBooking(action) {
  const response = yield call(update, action.payload.id, action.payload.data)
  if (response.hasOwnProperty('id')) {
    if (action.payload.data.rating == null) {
      yield put(editBookingSuccess(response));
    }
    else {
      const reviewResponse = yield call(updateRating, action.payload.data.reviewId, { rating: action.payload.data.rating, createdBy: action.payload.data.providerId, providerId: action.payload.data.providerId, bookingId: action.payload.id, comment: action.payload.data.comment })
      if (reviewResponse.hasOwnProperty('id')) yield put(editBookingSuccess(response));
    }
  }
  else yield put(editBookingFailure(response));
}

function* deleteBooking(action) {
  const response = yield call(remove, action.payload)
  if (response.hasOwnProperty('id')) yield put(deleteBookingSuccess(response));
  else yield put(deleteBookingFailure(response));
}

function* getBookingsWithStatus(action) {
  const response = yield call(get, action.payload);
  if (response !== undefined) yield put(bookingWithStatusSuccess(response));
  else yield put(bookingWithStatusFailure(response));
}

function* getBookingsWithServices() {
  const response = yield call(getWithServices);
  if (response !== undefined) yield put(bookingSuccess(response));
  else yield put(bookingFailure(response));
}

export default function* rootSaga() {
  yield takeLatest("CREATE_BOOKING", createBooking);
  yield takeLatest("GET_BOOKINGS", getBookings);
  yield takeLatest("GET_SINGLE_BOOKING", getSingleBooking);
  yield takeLatest("EDIT_BOOKING", updateBooking);
  yield takeLatest("DELETE_BOOKING", deleteBooking)
  yield takeLatest("GET_BOOKINGS_WITH_STATUS", getBookingsWithStatus)
  yield takeLatest("GET_BOOKINGS_WITH_SERVICES", getBookingsWithServices)
}
