import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import categorySagas from './categories/sagas';
import tagSagas from './tags/sagas';
import servicesSagas from './services/sagas';
import userRoleSagas from './user-roles/sagas';
import providerSagas from './providers/saga';
import bookingSagas from './bookings/saga';
import supportTicketSagas from './support_tickets/sagas';
import mediaSagas from './media/saga';
import mediaMetaSagas from './media-meta/sagas';
import employeesSagas from './clients/saga';
import locationsSagas from './locations/sagas';
import adSagas from './ads/sagas';
import certificatesSagas from './certificates/sagas';
import financialSagas from './financial/sagas';
import reviews from './reviews/sagas';
import notifications from './notifications/saga';
import adminSagas from './admin/saga';
import userClientSagas from './user-clients/saga';
import surveySagas from './survey/saga';
import bcgRequest from './bcg-request/saga';
import appConfiguration from './app-configuration/sagas';
import graph from './graph/sagas';
import usersMeta from './users-meta/sagas';
import loginAsProvider from './login-as-provider/saga'

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    categorySagas(),
    tagSagas(),
    servicesSagas(),
    userRoleSagas(),
    providerSagas(),
    bookingSagas(),
    supportTicketSagas(),
    mediaSagas(),
    employeesSagas(),
    locationsSagas(),
    adSagas(),
    certificatesSagas(),
    mediaMetaSagas(),
    financialSagas(),
    reviews(),
    notifications(),
    adminSagas(),
    userClientSagas(),
    surveySagas(),
    bcgRequest(),
    appConfiguration(),
    graph(),
    usersMeta(),
    loginAsProvider()
  ]);
}
