import {
  CREATE_NEW_CLIENT,
  CREATE_NEW_CLIENT_SUCCESS,
  CREATE_NEW_CLIENT_FAILURE,
  GET_ALL_CLIENTS,
  GET_ALL_CLIENTS_SUCCESS,
  GET_ALL_CLIENTS_FAILURE,
  GET_ALL_EMPLOYEES,
  GET_ALL_EMPLOYEES_SUCCESS,
  GET_ALL_EMPLOYEES_FAILURE,
  DELETE_CLIENT,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAILURE,
  EDIT_CLIENT,
  EDIT_CLIENT_SUCCESS,
  EDIT_CLIENT_FAILURE,
  GET_SINGLE_CLIENT,
  GET_SINGLE_CLIENT_SUCCESS,
  GET_SINGLE_CLIENT_FAILURE,
  EDIT_CLIENT_BY_ADMIN,
  EDIT_CLIENT_BY_ADMIN_SUCCESS,
  EDIT_CLIENT_BY_ADMIN_FAILURE
} from "../actions";

export const getClients = (data) => {
  return {
    type: GET_ALL_CLIENTS,
    payload: data
  };
};

export const getClientsSuccess = data => {
  return {
    type: GET_ALL_CLIENTS_SUCCESS,
    payload: data
  };
};

export const getClientsFailure = error => {
  return {
    type: GET_ALL_CLIENTS_FAILURE,
    payload: error
  };
};

export const getEmployees = (data) => {
  return {
    type: GET_ALL_EMPLOYEES,
    payload: data
  };
};

export const getEmployeesSuccess = data => {
  return {
    type: GET_ALL_EMPLOYEES_SUCCESS,
    payload: data
  };
};

export const getEmployeesFailure = error => {
  return {
    type: GET_ALL_EMPLOYEES_FAILURE,
    payload: error
  };
};

export const createClient = data => {
  return {
    type: CREATE_NEW_CLIENT,
    payload: data
  };
};

export const createClientSuccess = (data) => {
  return {
    type: CREATE_NEW_CLIENT_SUCCESS,
	payload:data
  };
};

export const createClientFailure = error => {
  return {
    type: CREATE_NEW_CLIENT_FAILURE,
    payload: error
  };
};

export const deleteClient = id => {
  return {
    type: DELETE_CLIENT,
    payload: id
  };
};

export const deleteClientSuccess = (data) => {
  return {
    type: DELETE_CLIENT_SUCCESS,
	payload:data
  };
};

export const deleteClientFailure = error => {
  return {
    type: DELETE_CLIENT_FAILURE,
    payload: error
  };
};

export const editClient = data => {
  return {
    type: EDIT_CLIENT,
    payload: data
  };
};

export const editClientSuccess = (data) => {
  return {
    type: EDIT_CLIENT_SUCCESS,
	payload:data
  };
};

export const editClientFailure = error => {
  return {
    type: EDIT_CLIENT_FAILURE,
    payload: error
  };
};

export const getSingleClient = id => {
  return {
    type: GET_SINGLE_CLIENT,
    payload: parseInt(id)
  };
};

export const getSingleClientSuccess = data => {
  return {
    type: GET_SINGLE_CLIENT_SUCCESS,
    payload: data
  };
};

export const getSingleClientFailure = error => {
  return {
    type: GET_SINGLE_CLIENT_FAILURE,
    payload: error
  };
};

export const editClientByAdmin = data => {
  return {
    type: EDIT_CLIENT_BY_ADMIN,
    payload: data
  };
};

export const editClientByAdminSuccess = (data) => {
  return {
    type: EDIT_CLIENT_BY_ADMIN_SUCCESS,
	payload:data
  };
};

export const editClientByAdminFailure = error => {
  return {
    type: EDIT_CLIENT_BY_ADMIN_FAILURE,
    payload: error
  };
};
