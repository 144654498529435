import {
    GET_PERSONAL_REGTD_YEAR_SUCCESS,
    GET_PERSONAL_REGTD_YEAR_ERROR
} from "../actions";

const INITIAL_STATE = {
    success: false,
    error: {},
    data: [],
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_PERSONAL_REGTD_YEAR_SUCCESS:
            return { ...INITIAL_STATE, success: true, data: action.payload };
        case GET_PERSONAL_REGTD_YEAR_ERROR:
            return { ...INITIAL_STATE, error: action.payload };
        default:
            return { ...state };
	}
};