import {
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  MARK_NOTIFICATION_READ_SUCCESS,
  MARK_NOTIFICATION_READ_FAILURE,
  MARK_NOTIFICATION_READ,
} from '../actions';

const INITIAL_STATE = {
  success: false,
  error: null,
  notifications: [],
  count:0,
  update: {
    success: false,
    error: null,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...INITIAL_STATE,
        success: true,
        notifications: action.payload.data || action.payload,
        count: action.payload.total || 0
      };
    case GET_NOTIFICATIONS_FAILURE:
      return { ...state, success: false, notifications: action.payload };
    case MARK_NOTIFICATION_READ:
      return { ...state, update: { ...INITIAL_STATE.update } };
    case MARK_NOTIFICATION_READ_SUCCESS:
      return { ...state, update: { ...state.update, success: true } };
    case MARK_NOTIFICATION_READ_FAILURE:
      return { ...state, update: { ...state.update, error: action.payload } };
    default:
      return { ...state };
  }
};
