import {
  CREATE_MEDIA_META,
  CREATE_MEDIA_META_SUCCESS,
  CREATE_MEDIA_META_FAILURE
} from "../actions";

const INITIAL_STATE = {
  success: false,
  error: {},
  data: [],
  create: {
    success: false,
    error: null
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATE_MEDIA_META:
      return { ...INITIAL_STATE };
    case CREATE_MEDIA_META_SUCCESS:
      return { ...INITIAL_STATE, create: { ...state.create, success: true } };
    case CREATE_MEDIA_META_FAILURE:
      return { ...state, create: { ...state.create, error: action.payload } };
    default:
      return { ...state };
  }
};