import client from "../../feathers";

const clientsService = client.service("users");
const usersMeta = client.service("users-meta");
const locationService = client.service('locations');

export const create = async data => {
  try {
    return await clientsService.create(
      data
    );
  } catch (error) {
    return error;
  }
};

export const getAll = async data => {
  try {
    if(!data)
    {
      return await clientsService.find({ query: {paginate:false}})
    }
    else if(data && !data.$limit && !data.$skip)
    {
      return await clientsService.find({ query: {paginate:false, ...data,$sort:{id:-1}}})
    }
    else {
      const startDate = data.startDate;
      const endDate = data.endDate;
      delete data.endDate;
      delete data.startDate;
      let employees = null;
      if (startDate || endDate) {
        employees = await clientsService.find({ query: { createdAt: { $gte: startDate, $lte: endDate }, ...data } });
      }
      else {
        employees = await clientsService.find({ query: { ...data } });
      }
      let providers_set = new Set();

      let employeesData;

      try{
        employees.data.map(e=>e)
        employeesData = employees.data
      }catch(error){
        employeesData = employees
      }

      employeesData.map((employee)=>{
        providers_set.add(employee.createdBy);
      });

      let providers_meta = await usersMeta.find({ query: { userId: { $in: [...providers_set] } } });

      let locations = await locationService.find({
        query: { paginate: false },
      });
      employeesData.map((employee) => {
        let empData = {...employee}
        const locationId = employee.meta.location ? parseInt(employee.meta.location) : null;
        if (locationId) {
          const location = locations.find(p => p.id == locationId);
          employee.meta.address = location.address;
        }
        providers_meta.map((employeeMeta) => {
          if(employee.createdBy == employeeMeta.userId){
            if(employeeMeta.key == 'societyName' || employeeMeta.key == 'companyName') {
              employee.provider_company = employeeMeta.key == 'societyName' ? employeeMeta.value : employeeMeta.key == 'companyName' ? employeeMeta.value : '';
            }
            employee.provider = {
              logo: employeeMeta.key == 'profileImage' ? employeeMeta.value : '',
              logop: empData.provider ? empData.provider.meta ?  empData.provider.meta.profileImage ? empData.provider.meta.profileImage : ''  : ''   : ''
            }
          }
        });
      });
      employees.data = employeesData
      return employees
    }

  } catch (error) {
    return error;
  }
};

export const getAllEmployess = async data => {
  try {
    return await clientsService.find({ query: { ...data, paginate: false } })
  } catch (error) {
    return error;
  }
};

export const getOne = async id => {
  try {
    return await clientsService.get(id);
  } catch (error) {
    return error;
  }
};

export const remove = async id => {
  try {
    let result = await clientsService.remove(id)
    return result[0][0];
  } catch (error) {
    return error
  }
}

export const update = async (id, data) => {
  try {
    return await clientsService.patch(id, data)
  } catch (error) {
    return error;
  }
}

export const updateByAdmin = async (id, data) => {
  try {
    return await clientsService.patch(id, data)
  } catch (error) {
    return error;
  }
}

