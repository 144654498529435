import client from "../../feathers";

const adminsService = client.service("users");

export const create = async data => {
  try {
    return await adminsService.create(
      data
    );
  } catch (error) {
    return error;
  }
};
export const getAll = async (params) => {
  try {
    return await adminsService.find({ query: {role: "admin",...(params||{}), $sort:{createdAt:-1}} });
  } catch (error) {
    return error;
  }
};

export const getOne = async id => {
  try {
    return await adminsService.get(id);
  } catch (error) {
    return error;
  }
};

export const update = async (id, data) => {
  try {
    return await adminsService.patch(id, data)
  } catch (error) {
    return error;
  }
}
export const remove = async id => {
  try {
    return await adminsService.remove(id)
  } catch (error) {
    return error
  }
}