import {
  CREATE_CERTIFICATE,
  CREATE_CERTIFICATE_SUCCESS,
  CREATE_CERTIFICATE_FAILURE,
  GET_CERTIFICATES_SUCCESS,
  GET_CERTIFICATES_FAILURE,
  GET_CERTIFICATES,
  EDIT_CERTIFICATE,
  GET_All_CERTIFICATES,
  GET_All_CERTIFICATES_SUCCESS,
  GET_All_CERTIFICATES_FAILURE,
  EDIT_CERTIFICATE_SUCCESS,
  EDIT_CERTIFICATE_FAILURE,
  GET_SINGLE_CERTIFICATE,
  GET_SINGLE_CERTIFICATE_SUCCESS,
  GET_SINGLE_CERTIFICATE_FAILURE,
  DELETE_CERTIFICATE,
  DELETE_CERTIFICATE_SUCCESS,
  DELETE_CERTIFICATE_FAILURE
} from "../actions";

export const createCertificate = data => {
  return {
    type: CREATE_CERTIFICATE,
    payload: data
  };
};

export const createCertificateSuccess = msg => {
  return {
    type: CREATE_CERTIFICATE_SUCCESS,
    payload: msg
  };
};

export const createCertificateFailure = error => {
  return {
    type: CREATE_CERTIFICATE_FAILURE,
    payload: error
  };
};

export const getAllCertificate = (data) => {
  return {
    type: GET_All_CERTIFICATES,
    payload: data
  };
};

export const getAllCertificateSuccess = data => {
  return {
    type: GET_All_CERTIFICATES_SUCCESS,
    payload: data
  };
};

export const getAllCertificateFailure = error => {
  return {
    type: GET_All_CERTIFICATES_FAILURE,
    payload: error
  };
};

export const getCertificates = (data) => {
  return {
    type: GET_CERTIFICATES,
    payload: data
  };
};

export const certificateSuccess = data => {
  return {
    type: GET_CERTIFICATES_SUCCESS,
    payload: data
  };
};

export const certificateFailure = error => {
  return {
    type: GET_CERTIFICATES_FAILURE,
    payload: error
  };
};

export const editCertificate = data => {
  return {
    type: EDIT_CERTIFICATE,
    payload: data
  };
};

export const editCertificateSuccess = (data) => {
  return {
	type: EDIT_CERTIFICATE_SUCCESS,
	payload:data
  };
};

export const editCertificateFailure = error => {
  return {
    type: EDIT_CERTIFICATE_FAILURE,
    payload: error
  };
};

export const getSingleCertificate = id => {
  return {
    type: GET_SINGLE_CERTIFICATE,
    payload: id
  };
};

export const getSingleCertificateSuccess = data => {
  return {
    type: GET_SINGLE_CERTIFICATE_SUCCESS,
    payload: data
  };
};

export const getSingleCertificateFailure = error => {
  return {
    type: GET_SINGLE_CERTIFICATE_FAILURE,
    payload: error
  };
};

export const deleteCertificate = id => {
  return {
    type: DELETE_CERTIFICATE,
    payload: id
  }
}
export const deleteCertificateSuccess = (data) => {
  return {
    type: DELETE_CERTIFICATE_SUCCESS,
	payload:data
  }
}
export const deleteCertificateFailure = error => {
  return {
    type: DELETE_CERTIFICATE_FAILURE,
    payload: error
  }
}