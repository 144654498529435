import {
  CREATE_BOOKING_SUCCESS,
  CREATE_BOOKING_FAILURE,
  GET_BOOKINGS,
  GET_BOOKINGS_SUCCESS,
  GET_BOOKINGS_FAILURE,
  EDIT_BOOKING,
  EDIT_BOOKING_SUCCESS,
  EDIT_BOOKING_FAILURE,
  GET_SINGLE_BOOKING,
  GET_SINGLE_BOOKING_SUCCESS,
  GET_SINGLE_BOOKING_FAILURE,
  DELETE_BOOKING_SUCCESS,
  DELETE_BOOKING_FAILURE,
  // GET_BOOKINGS_WITH_STATUS,
  GET_BOOKINGS_WITH_STATUS_SUCCESS,
  GET_BOOKINGS_WITH_STATUS_FAILURE,
} from '../actions';
import { updateItemInArray, removeItemFromArray } from '../utils/mutation';

const INITIAL_STATE = {
  success: false,
  error: {},
  data: [],
  bookingData: [],
  loading: false,
  statusCount: {
    pending: 0,
    progress: 0,
    closed: 0,
    cancelled: 0,
    total: 0,
  },
  create: {
    success: false,
    error: null,
  },
  edit: {
    success: false,
    error: null,
  },
  delete: {
    success: false,
    error: null,
  },
  view: {
    loading: false,
    data: null,
    error: null,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATE_BOOKING_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          success: true,
          data: [...state.data, action.payload],
        },
      };
    case CREATE_BOOKING_FAILURE:
      return { ...state, create: { ...state.create, error: action.payload } };

    case GET_BOOKINGS:
      return { ...state, loading: true, error: null };
    case GET_BOOKINGS_SUCCESS:
      if (action.payload && action.payload.length > 0) {
        return { ...state, loading: false, bookingData: action.payload };
      }
      else {
        return { ...state, loading: false, data: action.payload };
      }
    case GET_BOOKINGS_FAILURE:
      return { ...state, loading: false, error: action };

    case EDIT_BOOKING:
      return { ...state, edit: { ...state.edit, success: false } };
    case EDIT_BOOKING_SUCCESS:
      return {
        ...state,
        edit: { ...state.edit, success: true },
        data: {
          data: updateItemInArray(
            state.data.data,
            state.data.data.findIndex((b) => b.id === action.payload.id),
            action.payload,
            true
          ),
        },
      };
    case EDIT_BOOKING_FAILURE:
      return { ...state, edit: { ...state.edit, error: action.payload } };

    case GET_SINGLE_BOOKING:
      return {
        ...state,
        view: { ...state.view, loading: true, error: null, data: null },
      };
    case GET_SINGLE_BOOKING_FAILURE:
      return {
        ...state,
        view: { ...state.view, loading: false, error: action.payload },
      };
    case GET_SINGLE_BOOKING_SUCCESS:
      return {
        ...state,
        view: { ...state.view, loading: false, data: action.payload },
      };

    case DELETE_BOOKING_SUCCESS:
      return {
        ...state,
        delete: { ...state.edit, success: true },
        data: removeItemFromArray(
          state.data,
          state.data.findIndex((x) => x.id == action.payload.id)
        ),
      };
    case DELETE_BOOKING_FAILURE:
      return { ...state, delete: { ...state.edit, error: action.payload } };
    case GET_BOOKINGS_WITH_STATUS_SUCCESS:
      const bookings = action.payload;

      const counts = {
        pending: 0,
        progress: 0,
        closed: 0,
        cancelled: 0,
        rejected: 0,
        total: 0,
        totalWithoutPayment: 0,
      };

      if (bookings && bookings.length) {
        bookings.forEach((b) => {
          if (b.rejectedByEmployee) {
            counts['rejected'] = b.count;
          } else {
            counts[b.status] = b.count;
          }
          counts.total += b.count;
          if (b.status.toLowerCase().indexOf('payment') === -1) {
            counts.totalWithoutPayment += b.count;
          }
        });
      }
      return { ...state, statusCount: counts };
    case GET_BOOKINGS_WITH_STATUS_FAILURE:
      return { ...state, error: action };
    default:
      return { ...state };
  }
};
