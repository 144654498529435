import client from '../../feathers';

const serviceService = client.service('services');
const taskService = client.service('services-checkbox');
const providerTaskService = client.service('provider-tasks');
const userService = client.service('users');
const userMetaService = client.service('users-meta');
const cateGoryService = client.service('categories');

export const create = async (data) => {
  try {
    return await serviceService.create({
      name: data.name,
      unitOfDefaultTime: data.timeUnit,
      commission: data.commission,
      currency: data.currency,
      defaultServiceTime: data.defaultServiceTime,
      price: data.price,
      tags: data.tagIds,
      categoryId: data.categoryId,
      tasks: data.tasks,
      termsAndConditions: data.termsAndConditions,
      description: data.description
    });
  } catch (error) {
    return error;
  }
};

export const get = async (data, customFilters) => {
  try {
    if (data) {
      if (data.providerId) {
        let authorisedPersonal = await userService.find({
          query: {
            createdBy: data.providerId,
            $select: ['id'],
            paginate: false,
          },
        });

        let _authorisedPersonal = [];

        authorisedPersonal &&
          authorisedPersonal.map((authoPers) => {
            _authorisedPersonal.push(authoPers.id);
            return authoPers;
          });

        let authorisedPersonalData = await userMetaService.find({
          query: {
            key: 'services',
            userId: {
              $in: _authorisedPersonal,
            },
          },
        });

        let servicesListOfUser = {};

        authorisedPersonalData.map((apd) => {
          let services = apd.value.split(';');
          services.map((service) => {
            if (servicesListOfUser.hasOwnProperty(service)) {
              servicesListOfUser[service].push({
                providerId: apd.userId,
              });
            } else {
              servicesListOfUser[service] = [
                {
                  providerId: apd.userId,
                },
              ];
            }
          });
          return apd;
        });

        const services = await serviceService.find({
          query: {
            $skip: data.skip,
            $limit: data.limit,
            ...(customFilters || {}),
          },
        });

        services.data &&
          services.data.map((service) => {
            if (servicesListOfUser[service.id]) {
              service['total_providers'] =
                servicesListOfUser[service.id].length;
            } else {
              service['total_providers'] = 0;
            }
            return service;
          });

        return services;
      } else {
        const query = { ...data };
        delete query.skip;
        delete query.limit;
        let services = await serviceService.find({
          query: {
            $skip: data.skip,
            $limit: data.limit,
            ...query,
            ...(customFilters || {}),
          },
        });
        let servicesData = services.data || [];
        let sids = servicesData.map((ser) => ser.id);
        const providerServices = await providerTaskService.find({
          query: { serviceId: { $in: sids } },
          paginate: false,
        });

        const employeeServices = await userMetaService.find({
          query: { key: 'services' },
          paginate: false,
        });

        if (servicesData.length) {
          for (let i = 0; i < servicesData.length; i++) {
            let sid = servicesData[i].id;
            let providers = providerServices.filter(
              (ele) => Number(ele.serviceId) == Number(sid)
            );
            let employee = employeeServices.filter((ele) => {
              let valueSids = ele.value ? ele.value.split(';') : [];
              return valueSids.includes(sid.toString());
            });
            servicesData[i].providerCount = providers.length;
            servicesData[i].employeeCount = employee.length;
          }
        }
        services.data = servicesData;
        return services;
      }
    } else {
      return await serviceService.find({
        query: { paginate: false, ...(customFilters || {}) },
      });
    }
  } catch (error) {
    return error;
  }
};

export const getProviderTasks = async (data) => {
  try {
    if (data) {
      if (data.providerId) {
        const providerTasks = await providerTaskService.find({
          query: { providerId: data.providerId },
        });
        if (!providerTasks.length) {
          return [];
        }
        let serviceIds = providerTasks.map((task) => task.serviceId);
        serviceIds = serviceIds.filter((item, i, ar) => ar.indexOf(item) === i);
        const services = await serviceService.find({
          query: {
            id: { $in: serviceIds },
            paginate: false,
          },
        });
        // get all employee created by this provider
        let employees = await userService.find({
          query: { createdBy: data.providerId, paginate: false },
        });
        services.forEach((s) => {
          s.providerTask = providerTasks.find((task) => task.serviceId == s.id);
          s.number_counts = employees.filter((ele) => {
            let services =
              ele.meta && ele.meta.services ? ele.meta.services.split(';') : [];
            return services.includes(s.id.toString()) ? true : false;
          });
          s.number_of_personnel = s.number_counts.length;
          s.task_id = s.providerTask.id;
        });
        // services.sort((a,b) => a.task_id - b.task_id)
        return services;
      } else {
        return await serviceService.find();
      }
    } else {
      return await serviceService.find();
    }
  } catch (error) {
    return error;
  }
};

export const getOne = async (id) => {
  try {
    return await serviceService.get(id);
  } catch (error) {
    return error;
  }
};

export const update = async (id, data) => {
  try {
    return await serviceService.patch(id, data);
  } catch (error) {
    return error;
  }
};

export const remove = async (id) => {
  try {
    return await serviceService.remove(id);
  } catch (error) {
    return error;
  }
};

export const getCategorySpecificServices = async (catId) => {
  try {
    return await serviceService.find({
      query: {
        categoryId: catId,
        paginate: false,
      },
    });
  } catch (error) {
    return error;
  }
};

export const getServiceTasksApi = async (id) => {
  try {
    return await taskService.find({
      query: {
        serviceId: id,
      },
    });
  } catch (error) {
    return error;
  }
};

export const createServiceTask = async (data) => {
  try {
    const { providerId, providerTasks } = data;
    // const resp = await providerTaskService.remove(null, { query: { providerId: providerId } })
    const resp = await providerTaskService.create(providerTasks);
    return resp;
  } catch (error) {
    return error;
  }
};

export const updateServiceTask = async (payload) => {
  try {
    const { id, data } = payload;
    const resp = await providerTaskService.patch(id, data);
    return resp;
  } catch (error) {
    return error;
  }
};

export const getProviderServiceTasksApi = async (data) => {
  try {
    return await providerTaskService.find({
      query: {
        serviceId: data.serviceId,
        providerId: data.providerId,
      },
    });
  } catch (error) {
    return error;
  }
};

export const removeProviderTasksApi = async (data) => {
  try {
    const resp = await providerTaskService.find({
      query: { serviceId: data.serviceId },
    });
    const providerTaskIds = resp.map((r) => r.id);

    const delResp = await providerTaskService.remove(providerTaskIds);

    return delResp;
  } catch (error) {
    return error;
  }
};

export const getAdminstratorPersonnelWithServiceId = async (payload) => {
  try {
    let data = await userService.find({
      query: {
        createdBy: payload.uid,
        userRoleId: 4,
        paginate: false,
        $select: ['email', 'id'],
      },
    });
    let usersData = [];
    for (let i = 0; i < data.length; i++) {
      let serviceIds =
        data[i].meta && data[i].meta.services
          ? data[i].meta.services.split(';')
          : [];
      if (serviceIds.includes(payload.sid)) {
        usersData.push({
          email: data[i].email,
          location:
            data[i].meta && data[i].meta.state ? data[i].meta.state : 'NA',
        });
      }
    }
    return usersData;
  } catch (error) {
    return error;
  }
};

export const getUserListWithServiceId = async (payload) => {
  try {
    let type = payload.type;
    if (type == 'providers') {
      let data = await providerTaskService.find({
        query: {
          serviceId: payload.serviceId,
          $select: ['providerId', 'price', 'id'],
        },
      });
      let providerIds = data.map((ele) => ele.providerId);
      let providerProfiles = await userService.find({
        query: { paginate: false, id: { $in: providerIds } },
      });
      let mapedData = data.map((ele) => {
        let proProfile = providerProfiles.find(
          (pro) => pro.id == ele.providerId
        );
        return {
          providerId: ele.providerId,
          price: ele.price,
          email: proProfile.email,
          name: proProfile.meta
            ? proProfile.meta.companyName
              ? proProfile.meta.companyName
              : proProfile.meta.firstName + ' ' + proProfile.meta.lastName
            : 'NA',
          income: payload.commission
            ? (payload.commission / 100) * ele.price
            : null,
        };
      });
      let n = mapedData.concat(mapedData).concat(mapedData).concat(mapedData);
      return mapedData;
    } else if (type == 'employees') {
      let data = await userMetaService.find({
        query: { key: 'services', $select: ['value', 'userId'] },
      });
      let selectedEmployeeIds = [];
      data.map((ele) => {
        let sids = ele.value ? ele.value.split(';') : [];
        if (sids.includes(payload.serviceId.toString())) {
          selectedEmployeeIds.push(ele.userId);
        }
        return ele;
      });
      if (!selectedEmployeeIds.length) {
        return [];
      }
      let employeeProfile = await userService.find({
        query: { paginate: false, id: { $in: selectedEmployeeIds } },
      });
      let providerIds = employeeProfile.map((ele) => ele.createdBy);
      let providerProfiles = await getUsersDataWithDelay(providerIds);

      let mapedEmployees = employeeProfile.map((ele) => {
        let provider = providerProfiles.find(
          (provi) => provi.id == ele.createdBy
        );
        let providerName = '';
        let providerEmail = '';
        if (provider) {
          providerName = provider.meta
            ? provider.meta.companyName
              ? provider.meta.companyName
              : provider.meta.firstName + ' ' + provider.meta.lastName
            : 'NA';
          providerEmail = provider.email;
        }
        return {
          employeeId: ele.id,
          providerId: ele.createdBy,
          employeeEmail: ele.email,
          employeeName: ele.meta
            ? ele.meta.firstName + ' ' + ele.meta.lastName
            : 'NA',
          providerName,
          providerEmail,
        };
      });
      return mapedEmployees;
    }
    return [];
  } catch (error) {
    return error;
  }
};

const getUsersDataWithDelay = async (providerIds) => {
  return new Promise((resolve) => {
    setTimeout(async () => {
      let providerProfiles = await userService.find({
        query: { paginate: false, id: { $in: providerIds } },
      });
      resolve(providerProfiles);
    }, 1000);
  });
};

const checkDataBase = async () => {
  // this was created to sync data between employees services and their providers
  let providersList = await userService.find({ query: { paginate: false } });
  let providerTasks = await providerTaskService.find();
  let employee = await userMetaService.find({ query: { key: 'services' } });
  let mapedEmployee = employee.map((ele) => {
    let employeeId = ele.userId;
    let employeeProfile = providersList.find((ele) => {
      return ele.id == employeeId;
    });
    let providerSelectedServices = [];
    if (employeeProfile) {
      providerSelectedServices = providerTasks
        .filter((ele) => employeeProfile.createdBy == ele.providerId)
        .map((ele) => ele.serviceId);
    } else {
    }
    let services = ele.value ? ele.value.split(';') : [];
    let pleaseAddTheseServicestoProvider = [];
    for (let i = 0; i < services.length; i++) {
      if (providerSelectedServices.includes(Number(services[i]))) {
      } else {
        pleaseAddTheseServicestoProvider.push(services[i]);
      }
    }
    return {
      employeeId: ele.userId,
      providerId: employeeProfile ? employeeProfile.createdBy : '',
      services,
      employeeProfile,
      providerSelectedServices,
      pleaseAddTheseServicestoProvider,
    };
  });
  let providerIds = mapedEmployee.map((ele) => ele.providerId);
  let provideruniqueArray = providerIds.filter(function (item, pos) {
    return providerIds.indexOf(item) == pos;
  });
  let dataToAdded = provideruniqueArray.map((id) => {
    let tobeAddedServiceIds = [];
    let hisData = mapedEmployee.filter(
      (ele) => ele.providerId.toString() == id.toString()
    );
    hisData.map((ele) => {
      ele.pleaseAddTheseServicestoProvider.map((e) => {
        tobeAddedServiceIds.push(e);
      });
      return ele;
    });
    return {
      providerId: id,
      lastIds: tobeAddedServiceIds.filter(function (item, pos) {
        return tobeAddedServiceIds.indexOf(item) == pos;
      }),
    };
  });
  let serviceIds = [];
  dataToAdded.map((e) => {
    e.lastIds.map((f) => {
      serviceIds.push(f);
    });
  });
  let mapedServiceIdss = serviceIds.filter(function (item, pos) {
    return serviceIds.indexOf(item) == pos;
  });
  let cateGories = await serviceService.find({ query: { paginate: false } });
  dataToAdded.map((ele) => {
    ele.task = ele.lastIds.map((t) => {
      return {
        // createdAt: new Date(),
        // updatedAt: new Date(),
        providerId: ele.providerId,
        categoryId: cateGories.find((ty) => Number(ty.id) == Number(t))
          .categoryId,
        serviceId: Number(t),
        price: 20,
        // status:1,
        income: 0,
        ipc: '',
      };
    });
    return ele;
  });
  let dataToUpdate = [];
  dataToAdded.map((e) => {
    if (e.id != '') {
      if (e.task.length) {
        e.task.map((ty) => {
          dataToUpdate.push(ty);
        });
      }
    }
    return e;
  });

  for (let i = 0; i < dataToUpdate.length; i++) {
    if (dataToUpdate[i].providerId != '') {
      await test(dataToUpdate[i]);
    }
  }
};

export const test = async (data) => {
  return new Promise((resolve) => {
    setTimeout(async () => {
      const resp = await providerTaskService.create([data]);
      resolve(resp);
    }, 3000);
  });
};
