import {
  EDIT_APP_CONFIGURATION,
  EDIT_APP_CONFIGURATION_SUCCESS,
  EDIT_APP_CONFIGURATION_FAILURE,
  GET_APP_CONFIGURATIONS,
  GET_APP_CONFIGURATIONS_SUCCESS,
  GET_APP_CONFIGURATIONS_FAILURE,
} from '../actions';

export const getAppConfigurations = () => {
  return {
    type: GET_APP_CONFIGURATIONS,
  };
};

export const appConfigurationsSuccess = (data) => {
  return {
    type: GET_APP_CONFIGURATIONS_SUCCESS,
    payload: data,
  };
};

export const appConfigurationsFailure = (error) => {
  return {
    type: GET_APP_CONFIGURATIONS_FAILURE,
    payload: error,
  };
};

export const editAppConfiguration = (data) => {
  return {
    type: EDIT_APP_CONFIGURATION,
    payload: data,
  };
};

export const editAppConfigurationSuccess = (data) => {
  return {
    type: EDIT_APP_CONFIGURATION_SUCCESS,
    payload: data,
  };
};

export const editAppConfigurationFailure = (error) => {
  return {
    type: EDIT_APP_CONFIGURATION_FAILURE,
    payload: error,
  };
};
